import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const FemoropatellararthroseItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Femoropatellararthrose wird auch als Kniescheibenarthrose bezeichnet. Sie entsteht durch den fortschreitenden Abbau des Knorpels
            zwischen der Kniescheibe (Patella) und dem Oberschenkelknochen (Femur). Dieser Knorpelabbau führt zu Schmerzen, Entzündungen und einer
            Beeinträchtigung der Funktion des Kniegelenks.
        </Text>
        <Text>
            Die genauen Ursachen für eine Femoropatellararthrose können vielfältig sein. Zu den möglichen Faktoren gehören wiederholte Belastungen des
            Knies, eine schlechte Ausrichtung und Führung der Kniescheibe, Verletzungen, eine oder mehrere erlittene Kniescheibenluxation (-en),
            angeborene Fehlbildungen, altersbedingter Knorpelverschleiß oder entzündliche Erkrankungen wie rheumatoide Arthritis.
        </Text>
        <Text>
            Das Hauptsymptom der Femoropatellararthrose ist ein Knieschmerz, der sich in der Regel im vorderen Teil des Gelenks oder auch hinter der
            Kniescheibe befinden kann. Die Schmerzen können bei Aktivitäten wie Treppensteigen, Laufen, Springen oder längerem Sitzen oder Stehen
            verstärkt auftreten. Auch kann es zu einer (morgendlichen) GelenkSteifheit oder zu einer messbaren Bewegungseinschränkung kommen. Bei
            fortgeschrittener Femoropatellararthrose kann es zu Schwellungen, Knirsch- oder Knackgeräuschen im Kniegelenk kommen.
        </Text>
        <Text>
            Die Diagnose einer Femoropatellararthrose erfolgt in der Regel durch eine Kombination aus körperlicher Untersuchung, Bewertung der
            Symptome des Patienten und bildgebenden Verfahren wie Röntgenaufnahmen oder einer Magnetresonanztomographie (MRT). Diese Untersuchungen
            können den Zustand des Knorpels im Kniegelenk darstellen und andere mögliche Ursachen der Symptome ausschließen.
        </Text>
        <Text>
            Die Behandlung der Femoropatellararthrose soll Schmerzen lindern, die Funktion des Kniegelenks wieder verbessern und die Lebensqualität
            des Patienten erhalten. Konservative Behandlungsmethoden bestehen z. B. aus:
        </Text>
        <OrderedList>
            <ListItem>Schmerzmedikation oder entzündungshemmenden Medikamenten.</ListItem>
            <ListItem>Hyaluronsäure-Infiltrationen als Schmiermittel und Stoßdämpfer oder </ListItem>
            <ListItem>Eigenbluttherapie, zur Schmerz- und Entzündungshemmung.</ListItem>
            <ListItem>
                Physiotherapie zur Stärkung der umgebenden Muskulatur, Verbesserung der Beweglichkeit und Optimierung der Körperhaltung und
                Biomechanik des Knies.
            </ListItem>
            <ListItem>Akupunktur zur Schmerz- und Entzündungshemmung.</ListItem>
            <ListItem>
                Stoßwellentherapie zur Verbesserung der Durchblutung, Stimulation von Stoffwechselprozessen und Förderung der Selbstheilung.
            </ListItem>
            <ListItem>Magnetfeldtherapie zur Schmerzreduktion und Unterstützung der Selbstheilungsprozesse.</ListItem>
            <ListItem>Gewichtsmanagement, um die Belastung des Kniegelenks zu reduzieren.</ListItem>
            <ListItem>
                Verwendung von orthopädischen Hilfsmitteln wie Kniebandagen oder speziellen Einlagen zur Unterstützung und Stabilisierung des Knies.
            </ListItem>
            <ListItem>
                Anpassung der Aktivitäten und Vermeidung von belastenden Bewegungen oder Sportarten, die den Kniegelenkverschleiß weiter fördern
                könnten.
            </ListItem>
        </OrderedList>
        <Text>
            In den meisten Fällen führt eine umfassende konservative Therapie zur Schmerzfreiheit oder ausreichenden Schmerzreduktion. Falls
            konservative Maßnahmen nicht ausreichen sollten, und die Arthrose bereits fortgeschritten ist, kann eine Operation erwogen werden.
        </Text>
        <Text>
            Falls Symptome einer Femoropatellararthrose auftreten, ist eine Vorstellung bei dem Facharzt für Orthopädie Ihres Vertrauens
            empfehlenswert, um eine genaue Diagnose zu erhalten und die besten Behandlungsoptionen eingehend zu besprechen. Eine frühzeitige Diagnose
            und Behandlung kann dazu beitragen, Schmerzen zu lindern und das Fortschreiten der Arthrose maßgeblich zu verlangsamen.
        </Text>
    </Stack>
);
