import * as React from 'react';
import { Stack, Text } from '@chakra-ui/react';

export const TriggerpunktinfiltrationItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Neuraltherapie ist eine medizinische Behandlungsmethode, die sich mit der Diagnose und Behandlung von Erkrankungen und Schmerzen im
            Zusammenhang mit gestörten Nervenfunktionen befasst. Sie wurde in den 1920er Jahren von den Brüdern Ferdinand und Walter Huneke
            entwickelt.
        </Text>
        <Text>
            Die Grundidee der Neuraltherapie basiert auf der Annahme, dass Störungen im Körper, insbesondere im Bereich der Nerven, zu Schmerzen und
            anderen Symptomen führen können. Durch das gezielte Setzen von Injektionen mit Lokalanästhetika oder anderen Medikamenten an bestimmten
            Stellen im Körper sollen diese gestörten Nervenfunktionen normalisiert werden.
        </Text>
        <Text>
            Die Neuraltherapie verwendet in der Regel Procain oder Lidocain als Lokalanästhetikum. Diese Substanzen werden an sogenannten
            "Störfeldern" oder "Störfeldpunkten" injiziert, die sich auch fernab des eigentlichen Schmerzortes befinden können. Das Ziel ist es, die
            gestörte Nervenfunktion zu regulieren, Entzündungen zu reduzieren und Schmerzen zu lindern.
        </Text>
        <Text>
            Die Neuraltherapie kann bei einer Vielzahl von Erkrankungen und Beschwerden angewendet werden, darunter chronische Schmerzen, Migräne,
            Gelenkbeschwerden und Muskelverspannungen. Sie wird auch häufig als begleitende Therapie bei der Behandlung von akuten Verletzungen oder
            postoperativen Schmerzen eingesetzt.
        </Text>
    </Stack>
);
