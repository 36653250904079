import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const LumboischialgieItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Lumboischialgie, umgangssprachlich auch bekannt als „Ischias“, beschreibt Schmerzen, die im unten Rückenbereich beginnend entlang des
            Ischiasnervs verlaufen. Der Ischiasnerv ist der längste Nerv des Körpers und erstreckt sich vom unteren Rückenbereich bis hinunter zu den
            Beinen. Eine Lumboischialgie tritt auf, wenn der Ischiasnerv gereizt oder eingeklemmt wird oder entzündet ist.
        </Text>
        <Text>
            Die häufigste Ursache für eine Lumboischialgie ist ein Bandscheibenvorfall in der Lendenwirbelsäule. Ein Bandscheibenvorfall tritt auf,
            wenn der weiche Kern der Bandscheibe den äußeren Ring durchbricht und austritt. Das ausgetretene Bandscheibengewebe drückt in diesem Fall
            auf Fasern des Ischiasnervs. Dies kann zu Schmerzen, Taubheits-, Kribbelgefühlen oder einer Muskelschwäche im Versorgungsgebiet des
            Ischiasnervs führen.
        </Text>
        <Text>Weitere mögliche Ursachen für eine Lumboischialgie sind:</Text>
        <OrderedList>
            <ListItem>
                Spinalkanalstenose: Eine Verengung des Wirbelkanals, durch den das Rückenmark verläuft, kann auf die Fasern des Ischiasnervs drücken
                und Schmerzen verursachen.
            </ListItem>
            <ListItem>
                Wirbelsäulenverletzungen oder -deformitäten: Verletzungen oder Deformitäten der Wirbelsäule wie Wirbelbrüche, eine Skoliose oder ein
                Wirbelgleiten (Spondylolisthesis) können den Ischiasnerv beeinträchtigen.
            </ListItem>
            <ListItem>
                Muskuläre Verspannungen oder Verletzungen: Muskelverspannungen, Muskelzerrungen oder -risse im unteren Rückenbereich können zu
                Ischiasschmerzen führen.
            </ListItem>
            <ListItem>
                Entzündung oder Infektion: Entzündliche Erkrankungen wie Bandscheibenentzündungen oder Infektionen können den Ischiasnerv reizen.
            </ListItem>
        </OrderedList>
        <Text>Die Symptome einer Lumboischialgie umfassen:</Text>
        <OrderedList>
            <ListItem>
                Schmerzen im unteren Rückenbereich, die sich entlang des Versorgungsgebiets des Ischiasnervs bis in das Gesäß, die Oberschenkel- und
                Unterschenkelrückseite und den Fuß erstrecken können.
            </ListItem>
            <ListItem>Taubheits-, Kribbelgefühle oder ein "Ameisenlaufen" im Versorgungsgebiet des Nervens.</ListItem>
            <ListItem>Muskelschwäche oder -ermüdung im betroffenen Bein oder Fuß.</ListItem>
            <ListItem>Schwierigkeiten beim Stehen, Gehen oder Sitzen für längere Zeit.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung einer Lumboischialgie hat zum Ziel die Schmerzen zu lindern, die Entzündung zu reduzieren und die Funktionsfähigkeit
            wiederherzustellen. Die Behandlungsmöglichkeiten sind vielfältig:
        </Text>
        <OrderedList>
            <ListItem>
                Ruhe und Anpassung der Aktivitäten: Eine vorübergehende Reduzierung belastender Aktivitäten und das Vermeiden von längeren Sitzzeiten
                können helfen die Schmerzen zu reduzieren.
            </ListItem>
            <ListItem>
                Schmerzmittel und entzündungshemmende Medikamente: Sie können zur Linderung von Schmerz und Entzündung eingesetzt werden.
            </ListItem>
            <ListItem>
                Physiotherapie: Gezielte Übungen zur Stärkung der Rücken- und Bauchmuskulatur, Verbesserung der Flexibilität und Verbesserung der
                Körperhaltung können helfen, die Symptome zu lindern und die Genesung zu unterstützen.
            </ListItem>
            <ListItem>
                Injektionen: Injektionen von entzündungshemmenden Medikamenten und / oder Eigenblutsubstraten (z. B. ACP) in den betroffenen Bereich
                können zur Linderung verabreicht werden.
            </ListItem>
            <ListItem>
                Operation: In schweren Fällen oder falls konservative Maßnahmen nicht ausreichend sind, kann eine Operation in Betracht gezogen
                werden, um den Druck auf den Ischiasnerv zu entlasten.
            </ListItem>
        </OrderedList>
        <Text>
            Im Falle einer Lumboischialgie sollten Sie zeitnah den Facharzt für Orthopädie und Unfallchirurgie Ihres Vertrauens aufsuchen, um eine
            genaue Diagnose zu erhalten und die für Sie optimale Behandlungsstrategie zu besprechen.
        </Text>
    </Stack>
);
