export interface ICustomNavLinkProps {
    label: string;
    target: string;
}

export const navLinks: ICustomNavLinkProps[] = [
    { label: 'Start', target: '/' },
    { label: 'Leistungen', target: 'leistungen' },
    { label: 'Dr. Festring', target: 'drfestring' },
    { label: 'Praxis', target: 'praxis' },
    { label: 'Diagnosen', target: 'diagnosen' },
    { label: 'Jobs', target: 'jobs' },
    { label: 'Kontakt', target: 'kontakt' },
];
