import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const RunnersKneeItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Das Läuferknie, auch als Runner's Knee oder Patellofemorales Schmerzsyndrom bekannt, ist eine häufige Kniegelenkerkrankung, die vor allem
            bei Läufern auftritt. Es ist durch Schmerzen und Beschwerden im vorderen Teil des Kniegelenks gekennzeichnet, insbesondere unterhalb der
            Kniescheibe (Patella).
        </Text>
        <Text>
            Die Behandlung des Läuferknies erfordert oft eine konservative Herangehensweise, die auf Schmerzlinderung, Entzündungshemmung und Stärkung
            der umgebenden Muskulatur abzielt:
        </Text>
        <OrderedList>
            <ListItem>
                Ruhe und Schonung: Eine vorübergehende Reduzierung der Lauf- oder Sportaktivitäten kann helfen den Schmerz zu lindern und die
                Entzündung ausheilen zu lassen.
            </ListItem>
            <ListItem>
                Biofeedback: Die Biofeedback-Therapie mittels EMG ist bei muskulären Dysbalancen eine gleichermaßen schonende und nachhaltige
                Therapiemethode.
            </ListItem>
            <ListItem>
                Physiotherapie: Übungen und Techniken zur Stärkung der Oberschenkelmuskulatur, insbesondere der Quadrizepsmuskulatur, können die
                Stabilität des Kniegelenks verbessern und den Druck auf die Kniescheibe verringern.
            </ListItem>
            <ListItem>
                Orthopädische Hilfsmittel: Ein Orthopäde kann Ihnen spezielle Einlagen, Schuhzurichtungen oder Kniebandagen verschreiben, um das
                Kniegelenk zu entlasten und eine bessere Ausrichtung der Kniescheibe zu fördern.
            </ListItem>
            <ListItem>
                Stoßwellentherapie: Eine Stoßwellentherapie kann zur Verbesserung der Durchblutungssituation und damit zur Schmerzlinderung sinnvoll
                sein.
            </ListItem>
            <ListItem>
                Magnetfeldtherapie: Zur Förderung der muskulären Stoffwechselsituation kann ebenfalls der Einsatz einer Magnetfeldtherapie von Vorteil
                für die Heilung und die Schmerzen sein.
            </ListItem>
            <ListItem>
                Medikamente: Nichtsteroidale entzündungshemmende Medikamente wie Ibuprofen können zur Schmerzlinderung und Entzündungshemmung
                eingesetzt werden. Die Einnahme sollte jedoch durch einen Facharzt empfohlen worden sein.
            </ListItem>
            <ListItem>
                Eigenbluttherapie: Eine Infiltrationstherapie mit ACP (Eigenbluttherapie) kann ggf. zur Förderung der Heilung und zur
                Entzündungshemmung eingesetzt werden.
            </ListItem>
            <ListItem>
                Injektion: In Einzelfällen kann ein Orthopäde eine Gelenkinjektion verabreichen, um die Entzündung zu reduzieren und Schmerzen zu
                lindern.
            </ListItem>
            <ListItem>
                Operation: In schweren Fällen oder bei chronischen Verläufen kann eine Operation in Betracht gezogen werden. Diese sollte jedoch
                sorgsam abgewogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Es ist wichtig bei anhaltenden Knieschmerzen den Facharzt für Orthopädie Ihres Vertrauens aufzusuchen, um die Beschwerden fachgerecht
            abklären zu lassen und die für Sie optimale Behandlungsstrategie - abgestimmt auf Ihre individuellen Bedürfnisse - umzusetzen.
        </Text>
    </Stack>
);
