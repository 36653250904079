import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const SpreizfussItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Der Spreizfuß bezeichnet eine Fußdeformität, bei der der Vorfuß im Vergleich zum Normalbefund verbreitet. Er tritt auf, wenn sich die
            Mittelfußknochen spreizen und das Fußquergewölbe abflacht. Dies kann zu einer unzureichenden Stabilität und zu einer ungünstigen
            Druckverteilung im Vorfußbereich führen. Ein Spreizfuß kann angeboren sein oder sich im Laufe des Lebens entwickeln.
        </Text>
        <Text>
            Die Ursachen für einen Spreizfuß können vielfältig sein. Dazu gehören die genetische Veranlagung, schwaches Bindegewebe, eine übermäßige
            Belastung der Füße, Fußarthrose oder vorausgegangene Fußverletzungen. Ein Spreizfuß kann verschiedene Symptome hervorrufen:
        </Text>
        <OrderedList>
            <ListItem>
                Schmerzen im Vorfußbereich: Dies ist eines der häufigsten Symptome eines Spreizfußes. Die Schmerzen können im Bereich der
                Metatarsalköpfchen (Vorfußballen) auftreten und sich bei Belastung verschlimmern.
            </ListItem>
            <ListItem>
                Metatarsalgie: Die Metatarsalgie bezeichnet Schmerzen im Mittelfuß. Diese können einen brennenden oder stechenden Charakter haben.
                Meistens besteht auch eine Druckempfindlichkeit.
            </ListItem>
            <ListItem>
                Ermüdung und Muskelschwäche: Der Spreizfuß kann eine übermäßige Beanspruchung der Vorfußmuskulatur zur Folge haben, was oft zu
                Ermüdung und Muskelschwäche führt.
            </ListItem>
            <ListItem>
                Instabilität: Die Verbreiterung des Vorfußes kann durch eine verringerte Stabilität das Gleichgewicht und die Standfestigkeit
                beeinträchtigen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung des Spreizfußes soll die Schmerzen lindern, die Stabilität verbessern und die normale Fußfunktion wiederherstellen. Die
            Behandlungsmöglichkeiten können je nach Schweregrad des Spreizfußes variieren und umfassen:
        </Text>
        <OrderedList>
            <ListItem>
                Orthopädische Hilfsmittel: Orthopädische Schuheinlagen oder orthopädisches Schuhwerk mit ausreichender Polsterung und Unterstützung
                kann die Druckverteilung im Vorfußbereich verbessern, die Stabilität erhöhen und die Scherzen lindern.
            </ListItem>
            <ListItem>Physiotherapie: Übungen zur Stärkung der Fußmuskulatur und Verbesserung der Beweglichkeit können hilfreich sein.</ListItem>
            <ListItem>
                Entzündungshemmende Medikamente: Entzündungshemmende Medikamente können kurzfristig zur Linderung von Schmerzen und Entzündungen
                eingesetzt werden.
            </ListItem>
        </OrderedList>
        <Text>
            In Ausnahmefällen kann eine Operation in Erwägung gezogen werden, falls die konservativen Maßnahmen keine ausreichende Linderung erreicht
            haben.
        </Text>
        <Text>
            Es ist ratsam bei einem Spreizfuß den Facharzt für Orthopädie Ihres Vertrauens aufzusuchen, um die exakte Diagnose zu erhalten und das
            optimale Behandlungskonzept zu besprechen. Oftmals können so Folgeprobleme, die durch das veränderte Gangbild entstehen können, vermieden
            werden.
        </Text>
    </Stack>
);
