import * as React from 'react';
import { Box, Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
// import EigenblutImage from 'images/leistungen/Eigenbluttherapie.jpg';
import EigenblutImage1 from 'images/leistungen/Eigenblut1.jpg';
import EigenblutImage2 from 'images/leistungen/Eigenblut2.jpg';

export const EigenblutItem: React.FC = (): JSX.Element => {
    return (
        <Stack textAlign={'justify'}>
            <Box>
                <Image float={'right'} w={300} ml={4} src={EigenblutImage1} alt='EigenblutImage1' display={{ base: 'none', md: 'block' }} />
                <Text>
                    Die ACP-Therapie (autologes, konditioniertes Plasma) ist eine Form der Eigenblutbehandlung, die in der Sportmedizin und Orthopädie
                    zur regenerativen Behandlung von Verletzungen, Entzündungen und degenerativen Erkrankungen eingesetzt wird. Bei der ACP-Behandlung
                    wird Blut aus der eigenen Vene des Patienten entnommen und anschließend zentrifugiert, um das Blutplasma von den Blutzellen zu
                    trennen. Das resultierende konzentrierte Plasma, das reich an Blutplättchen und Wachstumsfaktoren ist, wird dann in das betroffene
                    Gewebe injiziert, um die Heilung und Regeneration zu fördern.
                </Text>
            </Box>
            <Image w={'full'} src={EigenblutImage1} alt='EigenblutImage1' display={{ base: 'block', md: 'none' }} />
            <Text>Die ACP-Behandlung wird häufig bei folgenden Erkrankungen eingesetzt:</Text>
            <OrderedList>
                <ListItem>
                    Sehnenentzündungen: ACP kann zur Behandlung von Sehnenentzündungen wie der Achillessehnenentzündung, Patellarsehnenentzündung oder
                    Tennisellenbogen eingesetzt werden. Die injizierten Wachstumsfaktoren können die Entzündung reduzieren und die Heilung der
                    betroffenen Sehnen fördern.
                </ListItem>
                <ListItem>
                    Arthrose: ACP kann auch zur Behandlung von Arthrose eingesetzt werden. Die injizierten Wachstumsfaktoren können die Regeneration
                    des geschädigten Gelenkknorpels stimulieren und Entzündungen reduzieren, was zu einer Linderung der Schmerzen und Verbesserung der
                    Gelenkfunktion führen kann.
                </ListItem>
                <ListItem>
                    Muskelverletzungen: Bei Muskelverletzungen wie Zerrungen oder Teilrissen kann die ACP-Behandlung eingesetzt werden, um die Heilung
                    und Regeneration des verletzten Muskels zu beschleunigen.
                </ListItem>
            </OrderedList>
            <Box>
                <Image float='left' w={{ base: '100%', md: 180 }} mr={4} src={EigenblutImage2} alt='EigenblutImage2' mb={{ base: 2, md: 0 }} />
                <Text>
                    Die ACP-Behandlung erfolgt in der Regel in mehreren Sitzungen im Abstand von Wochen. Die genaue Anzahl der Injektionen und der
                    Behandlungsverlauf hängen von der Art und Schwere der Erkrankung oder Verletzung ab.
                </Text>
                <Text>
                    Die ACP-Behandlung ist im Allgemeinen gut verträglich, da das injizierte Plasma aus dem eigenen Blut des Patienten gewonnen wird.
                    Da keine Fremdsubstanzen verwendet werden, ist das Risiko allergischer Reaktionen gering. In einigen Fällen können vorübergehende
                    Schmerzen oder Schwellungen an der Injektionsstelle auftreten.
                </Text>
                <Text>
                    Es ist wichtig zu beachten, dass die ACP-Behandlung nicht bei jedem Patienten die gleichen Ergebnisse erzielt. Die Eignung der
                    ACP-Behandlung sollte von einem qualifizierten Facharzt bewertet werden, der Ihre individuelle Situation berücksichtigt und Sie
                    über die möglichen Behandlungsoptionen informiert.
                </Text>
            </Box>
        </Stack>
    );
};
