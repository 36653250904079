import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const VordererKnieschmerzItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Der vordere Knieschmerz, auch Patellofemorales Schmerzsyndrom oder Chondropathia patellae genannt, verursacht Schmerzen im Bereich der
            Vorderseite des Kniegelenks. Es handelt sich um ein häufiges Beschwerdebild, das verschiedene Ursachen haben kann:
        </Text>
        <OrderedList>
            <ListItem>
                Überlastung: Häufig tritt der vordere Knieschmerz in Folge einer Überlastung der Strukturen im Kniegelenk auf. Diese kann z. B. durch
                übermäßige körperliche Aktivität, intensives Training, wiederholte Bewegungen oder eine plötzliche Zunahme der Trainingsintensität
                verursacht werden.
            </ListItem>
            <ListItem>
                Kniescheibenfehlstellung / Patellafehlstellung (Malalignment): Eine Fehlstellung der Kniescheibe kann zu einem vorderen Knieschmerz
                führen. Eine zu hohe, zu tiefe oder seitlich verschobene Kniescheibe kann die Bewegung der Kniescheibe beeinträchtigen und Schmerzen
                verursachen.
            </ListItem>
            <ListItem>
                Muskuläre Dysbalance: Muskuläre Dysbalancen in der Muskulatur um das Knie herum können zu einer unphysiologischen Bewegung der
                Kniescheibe führen und Knieschmerzen verursachen. Schwache Oberschenkelmuskeln, insbesondere ein schwacher Quadrizepsmuskel, und eine
                unzureichend ausgeprägte Hüftmuskulatur können die Belastung auf die Kniescheibe erhöhen.
            </ListItem>
            <ListItem>
                Überlastung der Sehnen: Eine Überlastung der Patellasehne, die die Kniescheibe mit dem Schienbein verbindet, kann ebenfalls zu einem
                vorderen Knieschmerz führen. Dieser Fall tritt häufig bei Sportarten auf, die Sprünge und plötzliche Richtungswechsel erfordern, wie
                beispielsweise Basketball oder Volleyball.
            </ListItem>
            <ListItem>
                Trauma oder Verletzung: Eine Kniegelenkverletzung wie eine Prellung durch einen direkten Schlag, eine Distorsion (Verrenkung) oder ein
                Bänderriss, z. B. durch einen Sturz, kann zu einem vorderen Knieschmerz führen.
            </ListItem>
        </OrderedList>
        <Text>Die Behandlung des vorderen Knieschmerz hängt von der Ursache ab. Im Allgemeinen können folgende Maßnahmen helfen:</Text>
        <OrderedList>
            <ListItem>
                Ruhigstellung und Entlastung des Kniegelenks: Reduzieren Sie vorübergehend Aktivitäten, die den Knieschmerz verschlimmern und
                vermeiden Sie übermäßige Belastungen.
            </ListItem>
            <ListItem>Kälte: Kühlung des betroffenen Bereichs kann helfen, um Schmerzen und die Entzündung zu lindern.</ListItem>
            <ListItem>
                Kniebandagen oder orthopädische Schuheinlagen: Kniebandagen und / oder speziell angepasste Schuheinlagen können die Kniescheibe
                unterstützen und die Belastung verringern.
            </ListItem>
            <ListItem>
                Physiotherapie: Ein Physiotherapeut kann Übungen zur Stärkung der Muskulatur um das Knie herum empfehlen, um eine bessere
                Unterstützung und Stabilität zu bieten. Dehnungsübungen und Beweglichkeitstraining können ebenfalls hilfreich sein.
            </ListItem>
            <ListItem>Eigenbluttherapie: Eine Eigenbluttherapie z. B. mit ACP kann eine Schmerzlinderung und Entzündungshemmung bewirken.</ListItem>
            <ListItem>
                Injektionstherapie: In einigen Fällen kann eine Injektion von Kortikosteroiden erwogen werden, um Entzündungen zu reduzieren.
            </ListItem>
            <ListItem>Akupunktur: Sie wird bereits seit Jahrtausenden erfolgreich zur Schmerz- und Entzündungshemmung eingesetzt.</ListItem>
            <ListItem>
                Stoßwellentherapie: Die Stoßwellentherapie kann zur Verbesserung der Durchblutung, Stimulation von Stoffwechselprozessen und Förderung
                der Selbstheilung beitragen.
            </ListItem>
            <ListItem>
                Magnetfeldtherapie: Die Durchführung einer Magnetfeldtherapie dient der Schmerzreduktion und begünstigt die Selbstheilungsprozesse.
            </ListItem>
            <ListItem>
                Schmerzmanagement: Die Einnahme von entzündungshemmenden Medikamenten wie Ibuprofen oder die Anwendung von Salben oder Gels kann zur
                Linderung der Beschwerden beitragen.
            </ListItem>
            <ListItem>
                Anpassung der Aktivitäten und Techniken im Sport: Bei bestimmten Aktivitäten können Anpassungen der Bewegungsabläufe oder der Technik
                helfen, den Knieschmerz zu reduzieren.
            </ListItem>
        </OrderedList>
        <Text>
            In Ausnahmefällen kann eine Operation in Betracht kommen, falls die Ursache des vorderen Knieschmerzes nur operativ zu korrigieren ist.
        </Text>
        <Text>
            Falls Sie Symptome des vorderen Knieschmerzes erkennen, sollte Sie den Facharzt für Orthopädie Ihres Vertrauens aufsuchen, damit Sie eine
            genaue Diagnose erhalten. Im nächsten Schritt wird ein auf Ihre Bedürfnisse abgestimmter Behandlungsplan erstellt, sodass die optimalen
            Voraussetzungen für eine zeitnahe Genesung geschaffen sind.
        </Text>
    </Stack>
);
