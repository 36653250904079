import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const OsteoporoseItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Osteoporose ist eine Erkrankung, bei der die Knochenmasse und Knochendichte abnehmen und die Knochen porös und anfällig für Brüche werden.
            Es handelt sich um eine chronische und fortschreitende Erkrankung, die häufig unbemerkt bleibt, bis es zu einem Knochenbruch kommt.
            Osteoporose betrifft vor allem ältere Menschen und insbesondere postmenopausale Frauen.
        </Text>
        <Text>
            Die Hauptursache für Osteoporose ist ein Ungleichgewicht im Knochenumbau, bei dem der Körper Knochen abbaut und weniger Knochen wieder
            aufbaut. Dies führt zu einer allmählichen Abnahme der Knochenmasse und -dichte. Einige der Risikofaktoren für die Entwicklung einer
            Osteoporose sind:
        </Text>
        <OrderedList>
            <ListItem>
                Geschlecht und Alter: Frauen haben nach der Menopause ein erhöhtes Risiko auf Grund des Abfalls des Östrogenspiegels. Das Risiko
                steigt auch mit zunehmendem Alter.
            </ListItem>
            <ListItem>Familiäre Vorgeschichte: Eine positive Familienanamnese von Osteoporose erhöht das Risiko einer eigenen Erkrankung.</ListItem>
            <ListItem>
                Hormonelle Veränderungen: Hormonelle Störungen wie Schilddrüsenprobleme oder eine frühe Menopause können das Risiko erhöhen.
            </ListItem>
            <ListItem>
                Mangelnde körperliche Aktivität: Ein inaktiver Lebensstil führt zu einer geringeren Knochenbelastung und kann das Risiko erhöhen.
            </ListItem>
            <ListItem>Ernährungsfaktoren: Ein Mangel an Kalzium und Vitamin D in der Ernährung kann das Risiko erhöhen.</ListItem>
        </OrderedList>
        <Text>
            Das erste Symptome einer Osteoporose ist nicht selten ein Knochenbruch. Die häufigsten Frakturen treten in den Wirbelkörpern, Hand- und
            Hüftgelenken auf. Ein Verlust an Körpergröße im Laufe der Zeit oder Rückenschmerzen können Anzeichen für Wirbelkörperfrakturen sein.
        </Text>
        <Text>
            Die Diagnose einer Osteoporose erfolgt in der Regel durch eine Knochendichtemessung, mit der der Mineralgehalt im Knochen bestimmt wird.
            Diese Untersuchung kann helfen, das Risiko von Frakturen einzuschätzen und die Diagnose zu bestätigen.
        </Text>
        <Text>
            Die Behandlung einer Osteoporose soll das Fortschreiten der Erkrankung verlangsamen, Knochenbrüche verhindern und die Knochengesundheit
            verbessern. Folgende Maßnahmen können sinnvoll sein:
        </Text>
        <OrderedList>
            <ListItem>
                Lebensstiländerungen: Eine ausgewogene Ernährung, die reich an Kalzium und Vitamin D ist, körperliche Aktivität und das Vermeiden von
                Risikofaktoren wie Rauchen oder übermäßigem Alkoholkonsum sind wichtig.
            </ListItem>
            <ListItem>
                Medikamentöse Therapie: Hier kommen verschiedene Medikamente wie z. B. Bisphosphonate, selektive Östrogenrezeptormodulatoren oder das
                Parathormon-Analogon Teriparatid zur Behandlung in Frage.
            </ListItem>
            <ListItem>
                Sturzprävention: Um das Risiko von Frakturen zu reduzieren, können Sturzpräventionsmaßnahmen wie regelmäßige Seh- und
                Gleichgewichtsübungen, eine sichere Umgebung zu Hause und die Verwendung von Gehhilfen empfohlen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Es ist wichtig mit dem Facharzt für Orthopädie Ihres Vertrauens über Ihre Risikofaktoren für Osteoporose zu sprechen und regelmäßige
            Knochenuntersuchungen durchführen zu lassen, insbesondere wenn Sie zu den Risikogruppen gehören. Ein frühzeitiges Erkennen und eine
            effektive Behandlung können helfen das Fortschreiten der Erkrankung zu verlangsamen, das Risiko für Frakturen deutlich zu verringern und
            somit Komplikationen und dauerhafte Einschränkungen zu vermeiden.
        </Text>
    </Stack>
);
