import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const QuadrizepssehnenreizungItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Eine Quadrizepssehnenreizung ist eine Reizung oder Entzündung der Sehne des Quadrizepsmuskels. Der Quadrizepsmuskel ist der große Muskel
            auf der Vorderseite des Oberschenkels, der die Streckung des Kniegelenks durchführt. Die Quadrizepssehne verbindet den Muskel mit dem
            oberen Ende der Kniescheibe (Patella) und ist wichtig für die Kraftübertragung vom Quadricepsmuskel zum Schienbein.
        </Text>
        <Text>Eine Quadrizepssehnenreizung kann verschiedene Ursachen haben:</Text>
        <OrderedList>
            <ListItem>
                Überlastung oder Überbeanspruchung: Häufig tritt eine Quadrizepssehnenreizung auf, wenn der Muskel übermäßig belastet wird,
                beispielsweise durch wiederholte Bewegungen oder intensive körperliche Aktivitäten.
            </ListItem>
            <ListItem>Verletzung: Ein direkter Schlag oder Stoß auf den Quadrizepsmuskel kann zu einer Sehnenreizung führen.</ListItem>
            <ListItem>
                Muskuläre Dysbalancen: Eine Dysbalance zwischen dem Quadrizepsmuskel und den benachbarten Muskeln, insbesondere den ischiokruralen
                Muskeln auf der Rückseite des Oberschenkels, kann zu einer erhöhten Belastung der Quadrizepssehne führen.
            </ListItem>
        </OrderedList>
        <Text>Die Symptomatik einer Quadrizepssehnenreizung kann Folgendes beinhalten:</Text>
        <OrderedList>
            <ListItem>Schmerzen oder Druckempfindlichkeit entlang der Quadrizepssehne, insbesondere an der oberen Kante der Kniescheibe.</ListItem>
            <ListItem>Schwellung oder leichte Rötung im betroffenen Bereich.</ListItem>
            <ListItem>Einschränkung der Beweglichkeit des Kniegelenks oder Schwierigkeiten beim Strecken des Knies.</ListItem>
            <ListItem>Ggf. Knirsch- oder Knackgeräusche im Bereich des vorderen Kniegelenks bei bestimmten Bewegungen.</ListItem>
        </OrderedList>
        <Text>Die Behandlung einer Quadrizepssehnenreizung erfordert in der Regel:</Text>
        <OrderedList>
            <ListItem>
                Ruhe: Eine vorübergehende Reduzierung oder Vermeidung von Aktivitäten, die die Sehne belasten, um ihr Zeit zur Heilung zu geben.
            </ListItem>
            <ListItem>
                Kühlung: Die Anwendung von Eis oder einer Kältepackung auf den betroffenen Bereich kann Schmerzen und Entzündungen reduzieren.
            </ListItem>
            <ListItem>
                Schmerzlinderung: Die Einnahme von entzündungshemmenden Medikamenten kann zur Linderung von Schmerzen und Entzündungen beitragen.
            </ListItem>
            <ListItem>
                Stoßwellentherapie: Eine Stoßwellentherapie kann zur Verbesserung der Durchblutungssituation und damit zur Schmerzlinderung sinnvoll
                sein.
            </ListItem>
            <ListItem>
                Eigenbluttherapie: Eine Infiltrationstherapie mit ACP (Eigenbluttherapie) kann zur Förderung der Heilung und zur Entzündungshemmung
                eingesetzt werden.
            </ListItem>
            <ListItem>
                Magnetfeldtherapie: Zur Förderung der muskulären Stoffwechselsituation kann ebenfalls der Einsatz einer Magnetfeldtherapie von Vorteil
                für die Heilung und die Schmerzen sein.
            </ListItem>
            <ListItem>
                Biofeedback: Die Biofeedback-Therapie mittels EMG ist bei muskulären Dysbalancen eine gleichermaßen schonende und nachhaltige
                Therapiemethode.
            </ListItem>
            <ListItem>
                Physiotherapie: Spezifische Übungen und Dehnungen, um die Flexibilität und Stärke des Quadrizepsmuskels und der umliegenden Muskulatur
                zu verbessern.
            </ListItem>
            <ListItem>
                Unterstützende Maßnahmen: Die Verwendung von Bandagen oder Schienen kann den betroffenen Bereich unterstützen und die Heilung fördern.
            </ListItem>
        </OrderedList>
        <Text>
            Falls Sie eine Quadricepssehnenreizung vermuten, ist eine Vorstellung bei dem Facharzt für Orthopädie Ihres Vertrauens sinnvoll, um die
            Diagnose zu sichern, andere, ggf. schwerwiegendere Diagnosen auszuschließen und die für Sie optimale Behandlung zu planen.
        </Text>
    </Stack>
);
