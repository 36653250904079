import * as React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';

export const SchroepfenItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Das Schröpfen ist eine alternative medizinische Technik, die seit vielen Jahrhunderten in verschiedenen Kulturen angewendet wird. Es
            handelt sich um eine Methode, bei der auf der Haut ein Unterdruck erzeugt wird, um bestimmte Effekte zu erzielen.
        </Text>
        <Box>
            <Text mb={2}>
                Beim Schröpfen werden spezielle Schröpfgläser oder Schröpfköpfe auf die Haut aufgesetzt, die zuvor mit Unterdruck versehen wurden. Der
                Unterdruck kann auf verschiedene Arten erzeugt werden. Traditionell wird dazu Luft aus dem Schröpfglas oder Schröpfkopf abgesaugt,
                indem eine brennende Substanz wie Alkohol kurzzeitig entzündet und dann wieder erstickt wird. Dadurch entsteht ein Vakuum, das das
                Glas oder den Kopf auf der Haut festsaugt.
            </Text>
            <Text mb={2}>
                Es gibt auch modernere Schröpfverfahren, bei denen Unterdruck mit Hilfe von Pumpen erzeugt wird. Diese Techniken sind in der Regel
                kontrollierter und sicherer als das traditionelle Schröpfen.
            </Text>
            <Text mb={2}>
                Die Wirkung des Schröpfens beruht auf verschiedenen Mechanismen. Durch den Unterdruck werden Blutgefäße geweitet, was zu einer
                besseren Durchblutung in der betroffenen Region führen kann. Dies kann helfen, Muskelverspannungen zu lösen und Schmerzen zu lindern.
                Darüber hinaus wird angenommen, dass das Schröpfen auch den Lymphfluss anregt und den Stoffwechsel verbessert.
            </Text>
            <Text mb={2}>
                Das Schröpfen wird häufig zur Behandlung von Muskelverspannungen, Rückenschmerzen, Nackenschmerzen und anderen muskuloskelettalen
                Beschwerden eingesetzt. Es wird auch bei Erkrankungen wie Erkältungen, Bronchitis oder Verdauungsstörungen angewendet.
            </Text>
            <Text>
                Es ist wichtig zu beachten, dass das Schröpfen potenzielle Risiken und Nebenwirkungen mit sich bringen kann. Dazu gehören
                vorübergehende v. a. Hautreizungen und Blutergüsse an den Schröpfstellen. Aus diesem Grund sollte das Schröpfen von qualifiziertem
                medizinischem Fachpersonal durchgeführt werden.
            </Text>
        </Box>
    </Stack>
);
