import { Container, useDisclosure } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { Nav } from 'components/nav';
import React, { useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Footer } from './Footer';
import { ImageHeader } from './ImageHeader';
import { SideMenu } from './SideMenu';

interface ILayoutProps {
    children: React.ReactElement;
}

export const Layout: React.FC<ILayoutProps> = ({ children }) => {
    const { pathname } = useLocation();
    const isHomePath = matchPath('/', pathname);
    const isFestringPath = matchPath('/drfestring', pathname);

    const navRef = useRef<HTMLDivElement | null>(null);
    const buttonsRef = useRef<HTMLDivElement | null>(null);
    const sideMenuRef = useRef<HTMLDivElement | null>(null);
    const footerRef = useRef<HTMLDivElement | null>(null);

    const navDimensions = useSize(navRef);
    const footerDimensions = useSize(footerRef);

    const { isOpen, onToggle } = useDisclosure();

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                buttonsRef.current &&
                !buttonsRef.current.contains(event.target) &&
                sideMenuRef.current &&
                !sideMenuRef.current.contains(event.target) &&
                isOpen
            ) {
                onToggle();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onToggle]);

    const containerMinHeight = `calc(100vh - ${navDimensions?.height ?? 0}px - ${footerDimensions?.height ?? 0}px)`;

    return (
        <>
            <Nav ref={navRef} buttonsRef={buttonsRef} isAppointmentOpen={isOpen} onAppointmentToggle={onToggle} />
            <SideMenu ref={sideMenuRef} isOpen={isOpen} onToggle={onToggle} />
            {(isHomePath || isFestringPath) && <ImageHeader />}
            <Container maxW={'container.xl'} p={6} minH={containerMinHeight}>
                {children}
            </Container>
            <Footer ref={footerRef} />
        </>
    );
};
