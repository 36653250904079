import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const RueckenschmerzenItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Rückenschmerzen sind ein häufiges Symptom, das verschiedene Ursachen haben kann. Sie können im oberen, mittleren oder unteren Rücken
            auftreten und von einem leichten Unbehagen bis hin zu starken Schmerzen reichen. Hier sind einige der häufigsten Ursachen für
            Rückenschmerzen:
        </Text>
        <OrderedList>
            <ListItem>
                Muskelverspannungen und -verletzungen: Überlastung der Muskeln durch falsche Haltung, muskuläre Dysbalancen, schweres Heben,
                wiederholte Bewegungen oder plötzliche Verletzungen können zu Rückenschmerzen führen. Muskelverspannungen können sich in verschiedenen
                Teilen des Rückens manifestieren und zu Schmerzen und Bewegungseinschränkungen führen.
            </ListItem>
            <ListItem>
                Bandscheibenerkrankungen: Die Bandscheiben befinden sich zwischen den Wirbelkörpern und dienen als Stoßdämpfer. Ein
                Bandscheibenvorfall, eine Bandscheibenvorwölbung oder eine Bandscheibendegeneration mit Verminderung der Bandscheibenelastizität kann
                zu starken Rückenschmerzen führen, insbesondere im Bereich der Lendenwirbelsäule.
            </ListItem>
            <ListItem>
                Wirbelsäulenerkrankungen: Erkrankungen wie Spondylarthrose (Degeneration der Wirbelgelenke), Spondylolisthesis (Wirbelgleiten) oder
                Osteoporose (Verminderung der Knochendichte) können zu Rückenschmerzen führen.
            </ListItem>
            <ListItem>
                Fehlhaltung und schlechte ergonomische Bedingungen: Eine schlechte Körperhaltung beim Sitzen, Stehen oder Liegen kann zu
                Rückenschmerzen führen. Ebenso können ungünstige Arbeitsbedingungen wie das Heben schwerer Gegenstände, lange Sitzzeiten am
                Schreibtisch oder das Tragen schwerer Lasten zu Rückenproblemen beitragen.
            </ListItem>
            <ListItem>
                Überlastung durch körperliche Aktivität: Intensive körperliche Aktivität, insbesondere wenn sie ungewohnt oder übermäßig ist, kann zu
                Überlastung und Rückenschmerzen führen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung von Rückenschmerzen hängt von der spezifischen Ursache und Schwere der Symptome ab. In den meisten Fällen können
            Rückenschmerzen mit konservativen Maßnahmen behandelt werden:
        </Text>
        <OrderedList>
            <ListItem>Ruhe und Schonung der betroffenen Region</ListItem>
            <ListItem>Anwendung von Wärme- oder Kältepackungen</ListItem>
            <ListItem>Schmerzlinderung mit nichtsteroidalen entzündungshemmenden Medikamenten</ListItem>
            <ListItem>
                Stoßwellentherapie zur Verbesserung der Durchblutungssituation, zum Lösen von Verspannungen, zur Schmerzlinderung und Beschleunigung
                der Heilung
            </ListItem>
            <ListItem>
                Infiltrationstherapie mit ACP (Eigenbluttherapie), Lokalanästhetikum oder pflanzlichen Substanzen zur Entzündungshemmung und
                Schmerzlinderung
            </ListItem>
            <ListItem>Magnetfeldtherapie zur Förderung der muskulären Stoffwechselsituation</ListItem>
            <ListItem>Biofeedback-Therapie bei muskulären Dysbalancen</ListItem>
            <ListItem>Physiotherapie zur Stärkung der Muskulatur, Verbesserung der Flexibilität und Schmerzlinderung</ListItem>
            <ListItem>Ergonomische Anpassungen am Arbeitsplatz und im Alltag</ListItem>
            <ListItem>Entspannungstechniken wie Dehnübungen, Yoga oder Meditation</ListItem>
        </OrderedList>
        <Text>
            Insbesondere wenn Rückenschmerzen länger anhalten, sich verschlimmern oder von anderen Symptomen begleitet werden, sollten Sie den
            Facharzt für Orthopädie Ihres Vertrauens aufsuchen. Eine exakte Diagnose und ein individuell abgestimmter Behandlungsplan sind die besten
            Voraussetzungen für eine zeitnahe Schmerzlinderung, die Abwendung möglicher Komplikationen und eine vollständige Genesung.
        </Text>
    </Stack>
);
