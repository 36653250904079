import * as React from 'react';
import { Image, Stack, Text } from '@chakra-ui/react';
import MuskelImage from 'images/leistungen/Muskeldiag.jpg';

export const MuskelkrafttestungItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die manuelle Muskeltestung (MMT) ist eine Methode zur Bewertung der Muskelkraft und der Funktionsfähigkeit bestimmter Muskeln oder
            Muskelgruppen. Sie wird häufig in der Orthopädie (z. B. vor und/oder nach Operationen oder Verletzungen) oder zur sportmedizinischen
            Diagnostik (z. B. zur Dokumentation der Trainingsleistung vor Wettkämpfen) eingesetzt. So können Abweichungen der Muskelkraft nach
            Sportverletzungen oder Operationen exakt beziffert werden. Die manuelle Muskelkrafttestung dient ebenfalls zur Verlaufsbeurteilung und
            Überwachung eines Muskelaufbautrainings nach Operationen oder Sportverletzungen (Rehabilitation). Auch ein Muskelaufbau aus rein
            sportlichen Gründen kann exakt nachgehalten werden.
        </Text>
        <Image src={MuskelImage} boxShadow={'md'} alt='Muskeldiagnostik' />
        <Text>
            Bei der manuellen Muskeltestung wird die Muskelkraft anhand des Widerstands, den der Patient gegen einen Drucksensor aufbringen kann,
            gemessen. Der Test besteht zumeist aus verschiedenen Bewegungsrichtungen, die auf den zu testenden Muskel oder die Muskelgruppe abgestimmt
            sind.
        </Text>
        <Text>
            Der Untersucher fordert den Patienten auf, eine bestimmte Muskelaktion gegen den Widerstand des Drucksensors auszuführen. Dabei wird der
            Muskel auf seine maximale Kontraktionsfähigkeit getestet. Die Muskelkraft wird im Vergleich zur Gegenseite beurteilt. Ebenso können
            Normalwerte der Muskelkraft zur Mitbeurteilung herangezogen werden.
        </Text>
        <Text>
            Die manuelle Muskeltestung kann verwendet werden, um Muskelstärken, -schwächen und Dysbalancen zu identifizieren, die Diagnosestellung bei
            muskuloskelettalen oder neurologischen Erkrankungen zu unterstützen, Behandlungspläne zu entwickeln und den Fortschritt während der
            Rehabilitation zu überwachen.
        </Text>
        <Text>
            Es ist zu beachten, dass die manuelle Muskeltestung auch von verschiedenen Faktoren wie Schmerzen, Ermüdung oder Bewegungseinschränkungen
            beeinflusst werden kann. Daher wird sie oft in Kombination mit anderen diagnostischen Verfahren und medizinischen Tests eingesetzt, um ein
            umfassenderes Bild der muskulären Funktion zu erhalten.
        </Text>
    </Stack>
);
