import { Text, TextProps } from '@chakra-ui/react';

interface IHyphenProps extends TextProps {
    children: string;
    hyphens?: 'none' | 'manual' | 'auto';
}

export const HyphenText: React.FC<IHyphenProps> = (props) => {
    const { children, hyphens = 'auto', ...rest } = props;
    return (
        <Text style={{ WebkitHyphens: hyphens, msHyphens: hyphens, hyphens: hyphens }} {...rest}>
            {children}
        </Text>
    );
};
