import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const MeniskusrissItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Meniskusriss ist eine Verletzung des Meniskus im Kniegelenk. Der Meniskus ist eine scheibenförmige Knorpelstruktur, die sich zwischen
            dem Oberschenkelknochen (Femur) und dem Schienbeinknochen (Tibia) im Kniegelenk befindet. Er dient dazu, die Belastung gleichmäßig auf das
            Kniegelenk zu verteilen, Stöße abzufedern und die Stabilität des Gelenks zu verbessern.
        </Text>
        <Text>
            Ein Meniskusriss tritt in der Regel durch plötzliche Drehbewegungen des Kniegelenks bei gleichzeitigem Druck oder Belastung auf. Dies kann
            bei Aktivitäten wie Sportarten mit plötzlichen Richtungswechseln, abrupten Stopps oder Knieverdrehungen auftreten.
        </Text>
        <Text>
            Die Symptome eines Meniskusrisses können variieren, abhängig von der Schwere und Art der Verletzung. Zu den häufigsten Symptomen gehören:
        </Text>
        <OrderedList>
            <ListItem>Schmerzen im Knie, insbesondere an der Innenseite oder Außenseite des Gelenks.</ListItem>
            <ListItem>Schwellung des Kniegelenks / Gelenkerguss.</ListItem>
            <ListItem>Einschränkung der Gelenkbeweglichkeit, insbesondere bei Beugung und / oder Streckung.</ListItem>
            <ListItem>Gefühl von Blockaden oder Einklemmen im Kniegelenk.</ListItem>
            <ListItem>Instabilität oder das Gefühl, dass das Kniegelenk bei Bewegung / Belastung nachgibt.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung eines Meniskusrisses hängt u. a. von der Größe, Lokalisation und Art des Risses sowie dem Alter und der Aktivität des
            Patienten ab. Die Behandlungsmöglichkeiten umfassen:
        </Text>
        <OrderedList>
            <ListItem>
                Konservative Behandlung: Bei kleinen, stabilen Meniskusrissen kann eine konservative Behandlung empfohlen werden. Eine
                Belastungsreduktion (Sportpause), Kühlung, Schonung sowie die Kompression und Hochlagerung des Kniegelenks können sinnvoll sein.
                Infiltrationen mit entzündungshemmenden Medikamenten, Eigenblutsubstraten (z. B. ACP) oder Hyaluronsäure können ebenfalls hilfreich
                sein. Im Verlauf sollte eine Physiotherapie erfolgen, um die Muskulatur um das Knie zu stärken und die Stabilität zu verbessern.
            </ListItem>
            <ListItem>
                Arthroskopische Operation: Für größere, symptomatische oder instabile Meniskusrisse kann eine Arthroskopie in Betracht gezogen werden.
                In der Regel wird hier der Meniskusriss entweder genäht oder der defekte Bereich des Meniskus wird entfernt.
            </ListItem>
        </OrderedList>
        <Text>
            Die postoperative Nachbehandlung spielt eine wichtige Rolle bei der Genesung nach einer Meniskusoperation. Physiotherapie wird oft
            empfohlen, um die Beweglichkeit, Kraft und Stabilität des Knies wiederherzustellen.
        </Text>
        <Text>
            Falls Sie einen Meniskusriss vermuten, sollten Sie den Facharzt für Orthopädie und Unfallchirurgie Ihres Vertrauens aufsuchen, um eine
            genaue Diagnose und ein fachgerechtes, individuelles Behandlungskonzept zu erhalten, damit mögliche Folgeschäden wie eine
            Kniegelenkinstabilität oder eine frühzeitige Arthrose zu vermeiden.
        </Text>
    </Stack>
);
