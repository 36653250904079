import { Box, Button, chakra, Collapse, Flex, Stack, Text, useColorModeValue, useDisclosure, IconButton } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import React, { forwardRef, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MenuToggle } from './MenuToggle';
import { navLinks } from './navLinks';
import { CalendarIcon } from '@chakra-ui/icons';

interface INavProps {
    isAppointmentOpen: boolean;
    onAppointmentToggle: () => void;
    buttonsRef: React.Ref<HTMLDivElement>;
}

export const Nav = forwardRef<HTMLDivElement, INavProps>((props, ref) => {
    const { buttonsRef, isAppointmentOpen, onAppointmentToggle } = props;
    const { isOpen, onToggle } = useDisclosure();

    const mobileNavRef = useRef<HTMLDivElement | null>(null);
    const menuToggleRef = useRef<HTMLButtonElement | null>(null);
    const appointmentButtonRef = useRef<HTMLButtonElement | null>(null);

    const dimensions = useSize(appointmentButtonRef);
    const width = dimensions?.width ?? 0;

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                mobileNavRef.current &&
                !mobileNavRef.current.contains(event.target) &&
                menuToggleRef.current &&
                !menuToggleRef.current.contains(event.target) &&
                isOpen
            ) {
                onToggle();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onToggle]);

    const handleNavClick = () => {
        if (isOpen) {
            onToggle();
        }
    };

    return (
        <NavContainer ref={ref}>
            <Flex
                h={{ base: 12, md: 16 }}
                pl={4}
                py={2}
                borderBottom={'1px solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}
            >
                <Flex ml={-2} display={{ base: '', md: 'none' }}>
                    <MenuToggle ref={menuToggleRef} isOpen={isOpen} onToggle={onToggle} />
                </Flex>
                <Flex flex={1} justify={{ base: 'left', md: 'start' }} ml={4}>
                    <Logo handleNavClick={handleNavClick} />

                    <Flex display={{ base: 'none', md: 'flex' }} ml={5}>
                        <DesktopNav />
                    </Flex>
                </Flex>
                <Box ref={buttonsRef}>
                    <IconButton
                        aria-label='Termin buchen'
                        colorScheme='primary'
                        size={{ base: 'sm', md: 'md' }}
                        icon={<CalendarIcon />}
                        borderRightRadius={0}
                        onClick={onAppointmentToggle}
                        transform={`translateX(${isAppointmentOpen ? `${width}px` : 0})`}
                        transition={'transform 0.5s ease-in-out'}
                        _hover={{}}
                        _active={{}}
                    />
                    <Button
                        ref={appointmentButtonRef}
                        className={`appointment-button ${isAppointmentOpen ? 'open' : 'closed'}`}
                        size={{ base: 'sm', md: 'md' }}
                        colorScheme='primary'
                        ml={0}
                        pl={'0!important'}
                        borderRadius={0}
                        onClick={onAppointmentToggle}
                        transform={`translateX(${isAppointmentOpen ? `${width}px` : 0})`}
                        transition={'transform 0.5s ease-in-out'}
                        _hover={{}}
                        _active={{}}
                    >
                        Termin buchen
                    </Button>
                </Box>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav ref={mobileNavRef} handleNavClick={handleNavClick} />
            </Collapse>
        </NavContainer>
    );
});

interface INavContainerProps {
    children: React.ReactElement[];
}

const NavContainer = forwardRef<HTMLDivElement, INavContainerProps>((props, ref) => (
    <Box id='navContainer' ref={ref}>
        <chakra.nav position={'fixed'} w={'100%'} zIndex={3}>
            {props.children}
        </chakra.nav>
        <Box h={{ base: 12, md: 16 }} />
        {/* <Text color={'red.500'}>Datenschutz, Doctolib-Links</Text> */}
    </Box>
));

const DesktopNav: React.FC = () => (
    <Stack direction={'row'} spacing={6}>
        {navLinks.map((navLink, index) => (
            <NavLink
                key={index}
                to={navLink.target}
                style={({ isActive }) => {
                    return {
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '18px',
                        fontWeight: isActive ? 700 : 300,
                    };
                }}
            >
                {navLink.label}
            </NavLink>
        ))}
    </Stack>
);

interface INavClickProps {
    handleNavClick: () => void;
}

const MobileNav = React.forwardRef<HTMLDivElement, INavClickProps>(({ handleNavClick }, ref) => (
    <Stack
        ref={ref}
        spacing={4}
        p={4}
        borderBottom='1px solid'
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        display={{ base: 'flex', md: 'none' }}
    >
        {navLinks.map((navLink, index) => (
            <Link key={index} to={navLink.target} style={{ fontWeight: 300 }} onClick={handleNavClick}>
                {navLink.label}
            </Link>
        ))}
    </Stack>
));

const Logo: React.FC<INavClickProps> = ({ handleNavClick }) => (
    <Link to='/' onClick={handleNavClick}>
        <Flex
            pr={{ base: 0, md: 5 }}
            borderRight={{ base: 'none', md: '3px solid' }}
            borderRightColor={useColorModeValue('primary.500!important', 'primary.200!important')}
        >
            <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={700} color={useColorModeValue('primary.500', 'primary.200')}>
                dr
            </Text>
            <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={300}>
                festring
            </Text>
        </Flex>
    </Link>
);
