import * as React from 'react';
import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';

export const ZweitmeinungItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Wenn Sie sich mit einer medizinischen Empfehlung, die Sie erhalten haben, nicht wohl oder nicht ausreichend gut und/oder ausreichend
            umfänglich beraten fühlen, kontaktieren Sie uns gerne für eine weitere Einschätzung (zweite Meinung).
        </Text>
        <Text>Es gibt verschiedene medizinische Situationen, in denen eine Zweitmeinung hilfreich sein kann:</Text>
        <OrderedList>
            <ListItem>
                Bei Unsicherheit über die beste Behandlungsoption: Manchmal gibt es mehrere Behandlungsoptionen für eine bestimmte Erkrankung, und
                eine Zweitmeinung kann helfen, die Vor- und Nachteile der verschiedenen Optionen abzuwägen und eine fundierte Entscheidung zu treffen.
            </ListItem>
            <ListItem>
                Bei komplexen oder invasiven Behandlungen: Vor einer größeren Operation oder einer invasiven Behandlung kann eine Zweitmeinung helfen,
                verschiedene Ansätze, Risiken und mögliche Alternativen zu verstehen und eine fundierte Entscheidung zu treffen.
            </ListItem>
            <ListItem>
                Bei anhaltenden oder unklaren Symptomen: Wenn Symptome anhalten oder keine eindeutige Diagnose gestellt wurde, kann eine Zweitmeinung
                helfen, weitere Untersuchungen oder alternative Ansätze in Betracht zu ziehen.
            </ListItem>
            <ListItem>
                Bei einer schwerwiegenden Diagnose: Wenn eine schwerwiegende Diagnose gestellt wurde, kann es sinnvoll sein, eine Zweitmeinung
                einzuholen, um sicherzustellen, dass die Diagnose korrekt ist und um verschiedene Behandlungsmöglichkeiten zu besprechen.
            </ListItem>
        </OrderedList>
        <Text>
            Der Zweck einer Zweitmeinung besteht darin zusätzliche Informationen, Perspektiven und Fachkenntnisse zu erhalten, um auf Basis der
            bestmöglichen Informationslage eine Entscheidung über die optimale Vorgehensweise treffen zu können.
        </Text>
    </Stack>
);
