import * as React from 'react';
import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';

export const SportmedizinItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Sportmedizin ist ein medizinisches Fachgebiet, das sich mit der Prävention, Diagnose, Behandlung und Rehabilitation von Verletzungen
            und Erkrankungen im Zusammenhang mit sportlicher Aktivität und körperlicher Fitness befasst. Ihr Ziel ist es, Sportlerinnen und Sportlern
            dabei zu helfen, ihre Leistung zu verbessern, Verletzungen zu vermeiden und ihre Gesundheit zu erhalten.
        </Text>
        <Text>Die Sportmedizin umfasst verschiedene Bereiche, darunter:</Text>
        <OrderedList>
            <ListItem>
                Präventive Sportmedizin: Hierbei geht es um die Identifizierung von Risikofaktoren für Verletzungen und Krankheiten im Sport sowie um
                die Entwicklung von Maßnahmen zur Vermeidung von Verletzungen. Dazu gehören Aufwärmübungen, Kraft- und Konditionstraining, Stretching,
                Ernährungsberatung, Hydratation und Maßnahmen zur Verletzungsprävention wie das Tragen von Schutzkleidung.
            </ListItem>
            <ListItem>
                Sportverletzungen: Die Sportmedizin befasst sich mit der Diagnose und Behandlung von Verletzungen, die durch sportliche Aktivitäten
                verursacht werden. Dazu gehören Muskelzerrungen, Bänder- und Sehnenverletzungen, Knochenbrüche, Gelenkverrenkungen,
                Überlastungssyndrome und andere akute oder chronische Verletzungen.
            </ListItem>
            <ListItem>
                Leistungsdiagnostik: In der Sportmedizin werden verschiedene Tests und Untersuchungen durchgeführt, um die Leistungsfähigkeit von
                Sportlern zu bewerten. Dazu gehören Herz-Kreislauf-Tests, Lungenfunktionstests, Blutuntersuchungen, Spiroergometrie (Atemgasanalyse
                während des Trainings), Körperzusammensetzungsanalysen und Tests zur Bestimmung der aeroben und anaeroben Leistungsfähigkeit.
            </ListItem>
            <ListItem>
                Sportmedizinische Betreuung: Sportärzte und Sportmediziner betreuen Sportlerinnen und Sportler, um ihre Gesundheit zu erhalten und zu
                verbessern. Sie entwickeln individuelle Trainings- und Ernährungspläne, bieten Beratung zur Leistungssteigerung und zur
                Verletzungsprävention, geben Empfehlungen für die Regeneration und Rehabilitation nach Verletzungen und unterstützen bei der
                Bewältigung von gesundheitlichen Herausforderungen im Zusammenhang mit dem Sport.
            </ListItem>
            <ListItem>
                Sporttauglichkeitsuntersuchungen: Vor der Teilnahme an bestimmten Sportarten oder Wettkämpfen können sportmedizinische Untersuchungen
                durchgeführt werden, um die körperliche Fitness und die Eignung für die geplante sportliche Aktivität zu bewerten. Diese
                Untersuchungen umfassen in der Regel eine allgemeine Anamnese, körperliche Untersuchungen, Laboruntersuchungen und ggf. spezifische
                Tests je nach Sportart.
            </ListItem>
        </OrderedList>
        <Text>
            Die Sportmedizin arbeitet eng mit anderen medizinischen Fachgebieten wie Orthopädie, Physiotherapie, Ernährungswissenschaft und
            Psychologie zusammen, um eine umfassende Betreuung von Sportlern zu gewährleisten. Sie spielt eine wichtige Rolle sowohl im Breitensport
            als auch im Hochleistungssport, um die Gesundheit und Leistungsfähigkeit von Sportlern zu fördern und zu erhalten.
        </Text>
    </Stack>
);
