import { EmailIcon } from '@chakra-ui/icons';
import { Button, Link, LinkProps } from '@chakra-ui/react';

const href = 'mailto:praxis@dr-festring.de';
const content = 'praxis@dr-festring.de';

interface IButtonProps extends LinkProps {
    colorScheme?: 'primary' | 'secondary';
    variant?: 'outline' | 'solid';
}

export const EmailButton: React.FC<IButtonProps> = ({ colorScheme = 'primary', variant = 'outline', ...rest }) => (
    <Link href={href} {...rest}>
        <Button size={{ base: 'sm', md: 'md' }} leftIcon={<EmailIcon />} w={'100%'} variant={variant} colorScheme={colorScheme}>
            {content}
        </Button>
    </Link>
);

export const EmailLink: React.FC<LinkProps> = ({ color = 'primary.500', ...rest }) => (
    <Link href={href} color={color} {...rest}>
        {content}
    </Link>
);
