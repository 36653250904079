import { Image, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import Img2 from 'images/IMG_2030.png';

export const ImageHeader: React.FC = () => {
    const percentages = useBreakpointValue<number[]>({ base: [0, 70], md: [25, 75] })!;

    return (
        <Stack
            direction={'row'}
            bgGradient={`linear(to-r, #a0aec0 0%, #b7c6c1 ${percentages[0]}%, #b7c6c1 ${percentages[1]}%, #edf2f7 100%)`}
            justify={{ base: 'space-evenly', md: 'center' }}
            spacing={{ base: 0, md: 12 }}
        >
            <Image src={Img2} h={{ base: 250, md: 350 }} alt='Dr. Jan Festring' pt={{ base: 4, md: 8 }} />
            <Stack justifyContent={'center'} w={{ base: 180, md: 400 }}>
                <Text mb={{ base: 0, md: 2 }} fontSize={{ base: '12px', md: 'lg' }} as='cite'>
                    "Mein Ziel war es immer, meine Patientinnen und Patienten so zu behandeln, wie ich selbst behandelt werden möchte. In meiner
                    eigenen Praxis kann ich meiner Berufung umfassend und auf höchstem medizinischem Niveau nachgehen. Dafür bin ich dankbar."
                </Text>
                <Text>
                    <Text color='primary.500' fontSize={{ base: 'lg', md: '3xl' }}>
                        Dr. med. Jan Festring
                    </Text>
                    <Text fontSize={{ base: '10px', md: 'md' }}>Facharzt für Orthopädie und Unfallchirurgie</Text>
                </Text>
            </Stack>
        </Stack>
    );
};
