import * as React from 'react';
import { Box, Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import KinesioImage from 'images/leistungen/Kinesio-Taping.jpg';

export const KinesioItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Box>
            <Image float={'right'} src={KinesioImage} w={{ base: '100%', md: 200 }} boxShadow={'md'} ml={4} mb={{ base: 2, md: 0 }} />
            <Text mb={2}>
                Kinesio-Taping ist eine Methode, bei der elastische Bänder auf die Haut geklebt werden, um verschiedene Effekte zu erzielen. Es wurde
                in den 1970er Jahren vom japanischen Chiropraktiker Dr. Kenzo Kase entwickelt.
            </Text>
            <Text mb={2}>
                Die Kinesio-Tapes bestehen aus einem elastischen Material, das dem natürlichen Gewebe der Haut ähnelt. Sie sind in verschiedenen
                Farben erhältlich und werden auf spezifische Weise auf die Haut geklebt, je nach den Zielen der Behandlung.
            </Text>
            <Text>Die Hauptziele des Kinesio-Tapings sind:</Text>
        </Box>
        <OrderedList>
            <ListItem>
                Schmerzlinderung: Durch das Tape kann eine milde Kompression auf die Haut und das darunterliegende Gewebe ausgeübt werden, was zu
                einer Entlastung der Schmerzrezeptoren führen kann.
            </ListItem>
            <ListItem>
                Verbesserung der Muskelfunktion: Das Tape kann die Muskelaktivität unterstützen, indem es eine zusätzliche sensorische Information an
                die Muskeln liefert. Dies kann helfen, die Muskelkontraktion und Koordination zu verbessern.
            </ListItem>
            <ListItem>
                Unterstützung der Gelenkfunktion: Das Tape kann verwendet werden, um die Stabilität eines Gelenks zu verbessern und es vor Überlastung
                oder Verletzungen zu schützen. Es kann auch zur Korrektur von Fehlstellungen oder zur Unterstützung von geschwächten Gelenken
                eingesetzt werden.
            </ListItem>
            <ListItem>
                Verbesserung des Lymphflusses: Durch die Anwendung des Tapes in bestimmten Techniken kann der Lymphfluss angeregt werden. Dies kann
                bei der Reduzierung von Schwellungen oder Ödemen helfen.
            </ListItem>
        </OrderedList>
        <Text>
            Das Kinesio-Taping wird häufig in der Sportmedizin eingesetzt, sowohl zur Behandlung von akuten Verletzungen als auch zur Prävention von
            Verletzungen. Es kann aber auch in anderen Bereichen der Orthopädie und Schmerzbehandlung Anwendung finden.
        </Text>
    </Stack>
);
