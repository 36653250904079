import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const MuskelschmerzenItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Muskelschmerzen, auch Myalgie genannt, sind ein häufiges Symptom, das verschiedene Ursachen haben kann. Muskelschmerzen können als dumpf,
            drückend, stechend oder ziehend empfunden werden und können in einem bestimmten Muskel oder in mehreren Muskelgruppen auftreten.
        </Text>
        <Text>Die Ursachen können vielfältig sein. Hier sind einige häufige Auslöser von Muskelschmerzen:</Text>
        <OrderedList>
            <ListItem>
                Überbeanspruchung oder Überlastung der Muskeln: Intensive körperliche Aktivität, ungewohnte Belastungen oder anhaltende
                Muskelkontraktionen können Muskelschmerzen verursachen. Dies tritt häufig bei Sportlern, nach ungewohnter körperlicher Arbeit oder
                nach intensivem Training auf.
            </ListItem>
            <ListItem>
                Muskelverletzungen: Direkte Traumata wie Prellungen oder Muskelzerrungen können Muskelschmerzen verursachen. Diese können durch
                Stürze, Unfälle oder übermäßige Kraftanwendung entstehen.
            </ListItem>
            <ListItem>
                Entzündliche Erkrankungen: Entzündliche Erkrankungen wie Muskelschädigungen bei Autoimmunerkrankungen (z. B. Polymyositis) oder
                entzündliche Muskelerkrankungen (z. B. Myositis) können Muskelschmerzen verursachen.
            </ListItem>
            <ListItem>
                Infektionen: Virale oder bakterielle Infektionen können zu Muskelschmerzen führen. Ein Beispiel dafür ist die Grippe (Influenza), bei
                der Muskelschmerzen häufig auftreten.
            </ListItem>
            <ListItem>
                Nebenwirkung von Medikamenten: Bestimmte Medikamente wie Statine (Cholesterinsenker) können Muskelschmerzen als Nebenwirkung haben.
            </ListItem>
            <ListItem>
                Systemische Erkrankungen: Systemische Erkrankungen wie Fibromyalgie, rheumatoide Arthritis oder Lupus können Muskelschmerzen
                verursachen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung einer Myalgie hängt von der zugrunde liegenden Ursache ab. Im Allgemeinen können folgende Maßnahmen zur Linderung von
            Muskelschmerzen beitragen:
        </Text>
        <OrderedList>
            <ListItem>Ruhe und Schonung des betroffenen Muskels.</ListItem>
            <ListItem>
                Kühlung des betroffenen Bereichs mit einem Eisbeutel zur Linderung der Entzüdnung oder auch warme Kompressen zur Entspannung der
                Muskulatur.
            </ListItem>
            <ListItem>
                Schmerzlindernde Medikamente wie nichtsteroidale entzündungshemmende Medikamente können eingenommen werden, um Schmerzen und
                Entzündungen zu reduzieren. Es ist jedoch wichtig, die Dosierungsempfehlungen zu befolgen und bei Fragen oder Bedenken einen Arzt
                aufzusuchen.
            </ListItem>
            <ListItem>Physiotherapie oder Massagen können helfen, die Muskulatur zu entspannen und die Durchblutung zu verbessern.</ListItem>
            <ListItem>
                Stoßwellentherapie: Eine Stoßwellentherapie kann zur Verbesserung der Durchblutungssituation und Stimulation des Heilungsprozesses
                sinnvoll sein.
            </ListItem>
            <ListItem>
                Magnetfeldtherapie: Zur Förderung der muskulären Stoffwechselsituation kann ebenfalls der Einsatz einer Magnetfeldtherapie von Vorteil
                für die Heilung und die Schmerzen sein.
            </ListItem>
        </OrderedList>
        <Text>
            Falls die Muskelschmerzen anhalten, sehr stark sind oder von anderen Symptomen begleitet werden, sollten Sie sich bei dem Facharzt für
            Orthopädie und Unfallchirurgie Ihres Vertrauens vorstellen, um die zu Grunde liegende Ursache zu identifizieren und die oprimale
            Behandlung zu erhalten.
        </Text>
    </Stack>
);
