import { Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const BizepssehnenrissItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Bizepssehnenriss tritt auf, wenn die Sehne, die den Bizepsmuskel im Oberarm mit dem Knochen verbindet, teilweise oder vollständig
            reißt. Dies kann durch eine plötzliche Verletzung oder durch wiederholte Überlastung der Sehne auftreten.
        </Text>
        <Text>
            Es gibt zwei Arten von Bizepssehnenrissen: Distale Risse, bei denen die Sehne zwischen Bizepsmuskel und Unterarm reißt, und proximale
            Risse, bei denen die Sehne nahe der Schulter reißt.
        </Text>
        <Text>
            Distale Bizepssehnenrisse treten häufiger auf und werden oft durch eine plötzliche Belastung des Bizepsmuskels verursacht, wie zum
            Beispiel beim Heben eines schweren Gegenstands. Die Symptome können Schmerzen im Unterarm, eine spürbare Beule oder Vertiefung über dem
            Ellenbogen, eine Schwäche beim Beugen oder Heben des Arms sowie eine eingeschränkte Beweglichkeit des Unterarms sein.
        </Text>
        <Text>
            Proximale Bizepssehnenrisse sind seltener und werden normalerweise durch degenerative Veränderungen im Bereich der Sehne verursacht. Die
            Symptome können Schmerzen im Schulterbereich, Schwäche beim Heben des Armes oder eine spürbare Beule im Oberarm sein.
        </Text>
        <Text>
            Die Diagnose eines Bizepssehnenrisses erfolgt in der Regel durch körperliche Untersuchung, in der der Arzt den betroffenen Bereich
            abtastet und verschiedene Bewegungstests durchführt. Bildgebende Verfahren wie Ultraschall oder Magnetresonanztomographie (MRT) können
            verwendet werden, um den Riss zu bestätigen und den Schweregrad der Verletzung zu bestimmen.
        </Text>
        <Text>
            Die Behandlung eines Bizepssehnenrisses kann konservativ oder operativ sein, abhängig von der Schwere der Verletzung, dem Alter des
            Patienten, seinen Aktivitätsanforderungen und anderen Faktoren. Konservative Maßnahmen können Schmerzmedikation, Physiotherapie und eine
            Ruhistellung in einer Armschlinge (Orthese) umfassen. Eine Operation wird in der Regel bei jüngeren Patienten oder bei Personen, die eine
            aktive Lebensweise und / oder einen sportlichen Anspruch haben, empfohlen.
        </Text>
        <Text>
            Die Nachbehandlung nach einem Bizepssehnenriss umfasst in der Regel die schrittweise Steigerung der Beweglichkeit und Stärkung des
            betroffenen Arms durch Physiotherapie. Die vollständige Genesung kann insgesamt mehrere Monate dauern.
        </Text>
        <Text>
            Sie sollten sich bei dem Facharzt für Orthopädie und Unfallchirurgie Ihres Vertrauens vorstellen, wenn Sie einen Bizepssehnenriss
            vermuten, da eine angemessene Diagnose und Behandlung dazu beitragen können, Komplikationen zu verhindern und eine optimale Genesung zu
            ermöglichen.
        </Text>
    </Stack>
);
