import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const OmarthroseItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Omarthrose ist eine degenerative Gelenkerkrankung des Schultergelenks, bei der der Knorpel, der normalerweise die Gelenkflächen bedeckt,
            abgenutzt oder geschädigt ist.
        </Text>
        <Text>Die Entstehung einer Omarthrose hat oft mehrere Ursachen:</Text>
        <OrderedList>
            <ListItem>
                Alter: Mit zunehmendem Alter steigt das Risiko für degenerative Veränderungen in den Gelenken, einschließlich des Schultergelenks.
            </ListItem>
            <ListItem>
                Überlastung oder repetitive Belastung: Wiederholte Bewegungen oder eine Überbeanspruchung des Schultergelenks, z. B. durch bestimmte
                Berufe oder Sportarten, können zur Entwicklung einer Omarthrose beitragen.
            </ListItem>
            <ListItem>
                Verletzung oder Trauma: Eine frühere Verletzung des Schultergelenks, wie ein Bruch oder eine Schulterluxation, kann das Risiko für die
                Entwicklung einer Omarthrose erhöhen.
            </ListItem>
            <ListItem>
                Genetische Veranlagung: Es gibt Hinweise darauf, dass genetische Faktoren eine Rolle bei der Entstehung einer Omarthrose spielen
                können.
            </ListItem>
        </OrderedList>
        <Text>Die Symptome einer Omarthrose können sich unterschiedlich äußern:</Text>
        <OrderedList>
            <ListItem>
                Schmerzen im Schultergelenk, die bei bestimmten Bewegungen auftreten oder sich verschlimmern, wenn das Gelenk belastet wird.
            </ListItem>
            <ListItem>Steifheit und eine eingeschränkte Beweglichkeit des Schultergelenks.</ListItem>
            <ListItem>Knirsch- oder Knackgeräusche im Gelenk während der Bewegung.</ListItem>
            <ListItem>Muskelschwäche im Schulterbereich.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung der Omarthrose zielt darauf ab, die Schmerzen zu lindern, die Beweglichkeit zu verbessern und die Funktion des
            Schultergelenks zu erhalten. Hier sind einige mögliche Behandlungsoptionen:
        </Text>
        <OrderedList>
            <ListItem>
                Schmerzlinderung: Schmerzmittel, entzündungshemmende Medikamente oder lokale Injektionen mit Hyaluronsäure, ACP (Eigenbluttherapie)
                oder Kortikosteroiden können verwendet werden, um Schmerzen zu lindern und Entzündungen zu reduzieren.
            </ListItem>
            <ListItem>
                Physiotherapie: Spezifische Übungen und physikalische Therapie können helfen, die Muskulatur um das Schultergelenk zu stärken, die
                Beweglichkeit zu verbessern und die Stabilität zu erhöhen.
            </ListItem>
            <ListItem>
                Stoßwellentherapie: Eine Stoßwellentherapie kann zur Verbesserung der Durchblutungssituation und Stimulation des Heilungsprozesses
                eingesetzt werden.
            </ListItem>
            <ListItem>
                Magnetfeldtherapie: Zur Förderung der muskulären Stoffwechselsituation kann ebenfalls der Einsatz einer Magnetfeldtherapie von Vorteil
                für die Heilung und die Schmerzen sein.
            </ListItem>
            <ListItem>
                Wärme- oder Kältetherapie: Die Anwendung von Wärme oder Kälte auf das Schultergelenk kann zur Schmerzlinderung beitragen.
            </ListItem>
            <ListItem>
                Hilfsmittel: In einigen Fällen können Hilfsmittel wie Schulterbandagen kurzfristig verwendet werden, um das Gelenk zu entlasten und
                die Stabilität zu verbessern.
            </ListItem>
            <ListItem>
                Operation: In fortgeschrittenen Fällen, in denen konservative Maßnahmen keine ausreichende Linderung bieten, kann eine Operation
                erwogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Wenn Sie Symptome einer Omarthrose haben, sollten Sie sich bei dem Facharzt für Orthopädie Ihres Vertrauens vorstellen, um eine genaue
            Diagnose zu erhalten und zusammen die bestmögliche Behandlungsstrategie zu besprechen.
        </Text>
    </Stack>
);
