import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const NervenschmerzenItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Nervenschmerzen, auch als neuropathische Schmerzen bezeichnet, entstehen durch eine Reizung oder Schädigung von Nervenfasern. Im Gegensatz
            zu muskulären oder gelenkbedingten Schmerzen sind Nervenschmerzen oft stechend, brennend oder elektrisierend. Sie können innerhalb des
            Versorgungsgebiets des betroffenen Nervs ausstrahlen und Missempfindungen wie Taubheits- oder Kribbelgefühle hervorrufen.
        </Text>
        <Text>Die Ursachen für Nervenschmerzen können vielfältig sein. Hier sind einige häufige Auslöser:</Text>
        <OrderedList>
            <ListItem>
                Nervenkompression oder -schädigung: Eine Neuroforamenstenose (Verengung eines Nervenaustrittskanals), ein Bandscheibenvorfall, eine
                unfallbedingte Verletzung oder eine Nervenentzündung können zu Nervenschmerzen führen.
            </ListItem>
            <ListItem>
                Nervenerkrankungen: Erkrankungen wie die (diabetische) Polyneuropathie, Trigeminusneuralgie oder Multiple Sklerose können
                Nervenschmerzen verursachen.
            </ListItem>
            <ListItem>Chemotherapie: Einige Chemotherapie-Medikamente können Nervenschäden verursachen und zu Nervenschmerzen führen.</ListItem>
            <ListItem>
                Wirbelsäulenerkrankungen: Z. B. Bandscheibenvorfälle oder degenerative Veränderungen der Wirbelsäule können auf Nerven drücken und
                Nervenschmerzen verursachen.
            </ListItem>
            <ListItem>
                Entzündliche Erkrankungen: Autoimmunerkrankungen wie rheumatoide Arthritis oder Lupus können Nervenschmerzen hervorrufen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung von Nervenschmerzen hat zum Ziel Schmerzen zu lindern und die zu Grunde liegende Ursache zu behandeln. Hier sind einige
            mögliche Behandlungsoptionen:
        </Text>
        <OrderedList>
            <ListItem>
                Medikamente: Verschiedene Medikamente können bei der Behandlung von Nervenschmerzen eingesetzt werden, darunter bestimmte
                Schmerzmittel, Antidepressiva und Antikonvulsiva. Diese Medikamente beeinflussen die Signalübertragung und / oder modulieren die
                Schmerzwahrnehmung.
            </ListItem>
            <ListItem>
                Akupunktur: Die Akupunktur wird bereits seit Jahrtausenden zur Schmerzbehandlung eingesetzt. Sie kann im Falle von Nervenschmerzen
                hilfreich sein.
            </ListItem>
            <ListItem>
                Physiotherapie: Eine gezielte physiotherapeutische Behandlung kann helfen, die Muskulatur zu stärken, die Beweglichkeit zu verbessern
                und Schmerzen zu lindern. Dies kann Übungen, manuelle Therapie und Techniken wie TENS (transkutane elektrische Nervenstimulation)
                umfassen.
            </ListItem>
            <ListItem>
                Nervenblockaden: In einigen Fällen können Injektionen von lokalen Betäubungsmitteln oder Kortikosteroiden in die Nähe des betroffenen
                Nervs zur Schmerzlinderung eingesetzt werden.
            </ListItem>
            <ListItem>
                Psychotherapie und Schmerzbewältigungstechniken: Bei chronischen Nervenschmerzen kann eine psychotherapeutische Unterstützung helfen,
                mit Schmerzen umzugehen und die Lebensqualität zu verbessern. Techniken wie Entspannungsübungen, Biofeedback oder kognitive
                Verhaltenstherapie können hilfreich sein.
            </ListItem>
        </OrderedList>
        <Text>
            Es ist empfehlenswert den Facharzt Ihres Vertrauens aufzusuchen, wenn Sie unter Nervenschmerzen leiden, insbesondere wenn sie anhalten,
            stark sind oder Ihre Lebensqualität beeinträchtigen. So kann eine genaue Diagnostik erfolgen und die optimale Therapie eingeleitet werden.
        </Text>
    </Stack>
);
