import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const WadenkraempfeItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Wadenkrämpfe sind schmerzhafte, unwillkürliche Kontraktionen der Wadenmuskulatur. Sie können plötzlich auftreten und gehen häufig mit
            einer sichtbaren und spürbaren Verhärtung der Muskeln einher. Wadenkrämpfe können sehr unterschiedliche Ursachen haben:
        </Text>
        <OrderedList>
            <ListItem>
                Überanstrengung und Muskelermüdung: Übermäßige Anstrengung der Wadenmuskulatur durch längeres Stehen, Gehen oder Sport kann zu
                Krämpfen führen.
            </ListItem>
            <ListItem>
                Dehydratation (Exsikkose) und Elektrolytstörungen: Ein Mangel an Flüssigkeitszufuhr und Elektrolyten wie Kalium, Natrium, Calcium oder
                Magnesium kann zu Wadenkrämpfen führen.
            </ListItem>
            <ListItem>
                Muskelverkürzung: Verkürzte Wadenmuskeln auf Grund von Bewegungsmangel, unzureichendem Aufwärmen oder einer muskulären Dysbalance
                können zu Krämpfen führen.
            </ListItem>
            <ListItem>
                Nervenkompression: Eine Nervenkompression durch Bandscheibenvorfälle oder Engstellen der Nervenaustrittskanäle oder eine Nervenreizung
                in der Wadenregion kann Wadenkrämpfe verursachen.
            </ListItem>
            <ListItem>
                Medikamente: Einige Medikamente wie z. B. Diuretika, Statine oder bestimmte Asthma-Medikamente können als Nebenwirkung Wadenkrämpfe
                auslösen.
            </ListItem>
        </OrderedList>
        <Text>
            Falls Sie von Wadenkrämpfen betroffen sind, können Sie einige Maßnahmen ergreifen, um die Beschwerden zu lindern und zukünftige Krämpfe zu
            verhindern:
        </Text>
        <OrderedList>
            <ListItem>
                Dehnen und Aufwärmen: Regelmäßiges Dehnen der Wadenmuskulatur vor und nach dem Sport sowie vor dem Schlafengehen kann helfen, die
                Muskeln zu entspannen und Krämpfen vorzubeugen.
            </ListItem>
            <ListItem>
                Flüssigkeitszufuhr: Stellen Sie sicher, dass Sie ausreichend Flüssigkeit trinken, um eine Dehydratation (Exsikkose) zu vermeiden. Bei
                intensivem Training oder starkem Schwitzen kann es sinnvoll sein Elektrolytgetränke zu sich zu nehmen.
            </ListItem>
            <ListItem>
                Ausreichende Nährstoffversorgung: Achten Sie auf eine ausgewogene Ernährung, die ausreichend Kalium, Natrium, Calcium und Magnesium
                enthält. Diese Elektrolyte sind wichtig für eine normale Muskelkontraktion.
            </ListItem>
            <ListItem>
                Vermeidung von Überlastung: Vermeiden Sie die übermäßige Belastung der Wadenmuskulatur und geben Sie Ihren Muskeln ausreichend
                Erholungspausen, um sich zu regenerieren.
            </ListItem>
            <ListItem>
                Massage und Entspannungstechniken: Massieren Sie die Wadenmuskeln sanft und verwenden Sie Muskelentspannungsübungen und Wärme, um die
                Muskeln zu lockern und zu entspannen.
            </ListItem>
        </OrderedList>
        <Text>
            Falls die Wadenkrämpfe häufig auftreten, sehr schmerzhaft sind oder mit anderen Symptomen einhergehen, sollten Sie einen Arzt aufsuchen.
            Es könnte eine Grunderkrankung bestehen, die behandelt werden sollte. Der Facharzt für Orthopädie Ihres Vertrauens wird eine gründliche
            Untersuchung und Diagnostik durchführen, die genaue Diagnose bestimmen, mit Ihnen die möglichen Behandlungsoptionen besprechen, sodass die
            für Sie optimale Behandlungsstrategie festgelegt werden kann.
        </Text>
    </Stack>
);
