import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const SpondylarthroseItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Spondylarthrose, auch Facettengelenksarthrose oder Wirbelgelenksarthrose genannt, bezeichnet den Verschleiß des Knorpels in den
            Facettengelenken, den kleinen Gelenken zwischen den Wirbeln, die für die Stabilität und Beweglichkeit der Wirbelsäule verantwortlich sind.
        </Text>
        <Text>
            Die Hauptursache für die Entstehung einer Spondylarthrose ist der normale Alterungsprozess und der damit verbundene Gelenkverschleiß. Mit
            zunehmendem Lebensalter nutzt der Knorpel in den Facettgelenken ab und die Gelenkoberflächen verlieren an Kongruenz und werden unebener.
            Dies führt zu vermehrter Reibung und eine weiteren Schädigung der Gelenkflächen. Es gibt jedoch auch andere Faktoren, die das Risiko für
            eine Spondylarthrose erhöhen können, wie z. B. frühere Verletzungen der Wirbelsäule, übermäßige Belastung der Wirbelgelenke, genetische
            Veranlagung, Fehlstellungen der Wirbelsäule oder entzündliche Erkrankungen wie rheumatoide Arthritis.
        </Text>
        <Text>Zu den häufigsten Symptome einer Spondylarthrose gehören:</Text>
        <OrderedList>
            <ListItem>
                Nacken- oder Rückenschmerzen: Chronische oder intermittierende Schmerzen im betroffenen Wirbelsäulenabschnitt, die sich bei
                wirbelsäulenbelastenden Aktivitäten wie langes Stehen oder Gehen verschlimmern können.
            </ListItem>
            <ListItem>
                Steifheit und Bewegungseinschränkung: Die Beweglichkeit der Wirbelsäule kann bei Vorliegen einer Spondylarthrose eingeschränkt sein,
                was zu Steifheit und Schwierigkeiten beim Beugen, Drehen oder Aufrichten führen kann.
            </ListItem>
            <ListItem>
                Lokaler Druckschmerz: Druckschmerzen können über den betroffenen Facettengelenken auftreten, falls sie gereizt oder entzündet sind.
            </ListItem>
            <ListItem>
                Ausstrahlende Schmerzen: In einigen Fällen können die Schmerzen von der Wirbelsäule in das Gesäß, die Hüftregion, die Oberschenkel
                oder in die Nacken- und Schulterregion ausstrahlen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung der Spondylarthrose soll die Schmerzen lindern, die Beweglichkeit verbessern und die Lebensqualität erhalten. Zu den
            Behandlungsmöglichkeiten gehören:
        </Text>
        <OrderedList>
            <ListItem>
                Konservative Maßnahmen: Dazu gehören eine Schmerzmedikation (z. B. nichtsteroidale entzündungshemmende Medikamente), Akupunktur,
                Stoßwellentherapie, Magnetfeldtherapie, Physiotherapie, Wärme- oder Kälteanwendungen, Gewichtsreduktion bei Übergewicht sowie die
                Anpassungen der körperlichen Aktivitäten, um eine Überlastung der Wirbelsäule zu vermeiden.
            </ListItem>
            <ListItem>
                Injektionen: Lokale Injektionen mit Hyaluronsäure, ACP (Eigenbluttherapie), entzündungshemmenden Medikamenten oder Kortikosteroiden
                können erfolgreich zur Schmerzlinderung, Entzündungshemmung und Verlangsamung der Erkrankung eingesetzt werden.
            </ListItem>
            <ListItem>
                Operation: In schweren Fällen, in denen konservative Maßnahmen keine ausreichende Linderung erzielen können, kann eine Operation in
                Betracht gezogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Es ist sinnvoll den Facharzt für Orthopädie Ihres Vertrauens aufzusuchen, um eine genaue Diagnose zu erhalten und die besten
            Behandlungsoptionen zu besprechen und einzuleiten. So können die Schmerzen in der Regel effektiv gelindert, das Fortschreiten des
            Verschleißes verlangsamt und die Lebensqualität wieder erheblich verbessert werden.
        </Text>
    </Stack>
);
