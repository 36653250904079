import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const SenkfussItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Senkfuß, auch Pes planus genannt, ist eine Fußdeformität, bei der das Längsgewölbe des Fußes abgeflacht oder sogar vollständig
            aufgehoben ist. Ein Senkfuß kann angeboren sein (kongenital) oder sich im Laufe des Lebens entwickeln. Es gibt verschieden starke
            Ausprägungen des Senkfußes.
        </Text>
        <Text>Ein Senkfuß kann zu verschiedenen Symptomen führen:</Text>
        <OrderedList>
            <ListItem>
                Schmerzen und Ermüdung: Durch den verminderten Halt des Fußgewölbes können die Muskeln und Bänder im Fuß überlastet werden, was zu
                Schmerzen und einem Ermüdungsgefühl im Fuß führen kann.
            </ListItem>
            <ListItem>
                Instabilität und Gangunsicherheit: Ein Senkfuß kann zu einer verminderten Stabilität des Fußes führen, was das Gleichgewicht und die
                Koordination beim Gehen beeinträchtigen kann.
            </ListItem>
            <ListItem>
                Beschwerden der Fuß- und Beinposition: Ein Senkfuß kann zu einer veränderten Ausrichtung des Fußes und des Unterschenkels führen, was
                zu einer ungleichen Verteilung des Gewichts auf den Fuß, einer muskulären Dysbalance und zu Fehlstellungen wie Überpronation oder
                Abweichungen im Gangbild führen kann.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung eines Senkfußes hängt von der Schwere der Symptome und der Auswirkungen auf die Lebensqualität ab. Hier sind einige
            mögliche Ansätze:
        </Text>
        <OrderedList>
            <ListItem>
                Konservative Behandlung: Diese kann Maßnahmen wie physiotherapeutische Übungen zur Stärkung der Fuß- und Beinmuskulatur, den Einsatz
                von orthopädischen Einlagen zur Unterstützung des Fußgewölbes, die Verwendung von unterstützenden Schuhen mit ausreichender Dämpfung
                und Stabilität sowie die Therapie der muskulären Dysbalancen umfassen.
            </ListItem>
            <ListItem>
                Medikamente: Kurzfristig können Schmerzmittel oder entzündungshemmende Medikamente zur Linderung von Schmerzen und Entzündungen
                eingesetzt werden.
            </ListItem>
            <ListItem>
                Operation: In besonders schweren Fällen oder wenn konservative Maßnahmen keine ausreichende Linderung bieten, kann eine operative
                Behandlung erwogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Falls Sie einen Senkfuß vermuten, ist die Vorstellung bei dem Facharzt für Orthopädie Ihres Vertrauens ratsam, um eine exakte Diagnose zu
            erhalten und das für Sie beste Behandlungskonzept zu ermitteln.
        </Text>
    </Stack>
);
