import * as React from 'react';
import { Stack, Text } from '@chakra-ui/react';

export const OrthomolekularItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die orthomolekulare Therapie, auch als orthomolekulare Medizin bekannt, ist ein Behandlungsansatz, bei dem die Konzentration von
            Nährstoffen im Körper auf ein optimales Maß gebracht wird, um Gesundheit und Wohlbefinden zu fördern und Krankheiten vorzubeugen oder zu
            behandeln. Der Begriff "orthomolekular" wurde von dem Biochemiker Linus Pauling geprägt und bedeutet "die richtigen Moleküle".
        </Text>
        <Text>
            Die Grundannahme der orthomolekularen Therapie ist, dass der Körper zur Aufrechterhaltung seiner Gesundheit und optimalen Funktion
            bestimmte Nährstoffe in ausreichender Menge benötigt. Durch eine gezielte Supplementierung oder Ernährungsumstellung sollen diese
            Nährstoffe im Körper in einer optimalen Konzentration vorhanden sein. Dies soll den Körper unterstützen, sich selbst zu heilen und
            Krankheiten zu verhindern oder zu behandeln.
        </Text>
        <Text>
            Die orthomolekulare Therapie basiert auf individuellen biochemischen und genetischen Unterschieden jedes Menschen. Es werden spezifische
            Nährstoffe wie Vitamine, Mineralstoffe, Aminosäuren, Fettsäuren und andere Substanzen in therapeutischen Dosen verwendet, um den
            Nährstoffbedarf des Körpers zu decken. Die Dosis überschreitet hier oft die empfohlene Tageshöchstdosis, jedoch innerhalb eines sicheren
            Bereichs.
        </Text>
        <Text>
            Die orthomolekulare Therapie kann bei verschiedenen gesundheitlichen Zuständen eingesetzt werden, wie z. B. bei chronischen Krankheiten,
            Stoffwechselstörungen, neurologischen Erkrankungen, Herz-Kreislauf-Erkrankungen, Immunsystemstörungen, Stressbewältigung oder zur
            allgemeinen Gesundheitsförderung. Sie kann auch als Ergänzung zu konventionellen medizinischen Therapien eingesetzt werden.
        </Text>
        <Text>
            Die Anwendung der orthomolekularen Therapie sollte unter fachärztlicher Aufsicht und auf der Grundlage einer umfassenden medizinischen
            Bewertung erfolgen, die den individuellen Gesundheitszustand, die Krankheitsgeschichte, die aktuelle Medikation und Laborwerte einbezieht.
            So kann eine gleichermaßen individuell und sichere orthomolekulare Therapie gewährleistet werden.
        </Text>
    </Stack>
);
