import { Button, Link, LinkProps } from '@chakra-ui/react';
import { FaMobileScreenButton } from 'react-icons/fa6';

const href = 'tel:+4915227810752';
const content = '0152 - 27 81 07 52';

interface IProps extends LinkProps {
    colorScheme?: 'primary' | 'secondary';
    variant?: 'outline' | 'solid';
}

export const MobileButton: React.FC<IProps> = ({ colorScheme = 'primary', variant = 'outline', ...rest }) => (
    <Link href={href} {...rest}>
        <Button size={{ base: 'sm', md: 'md' }} leftIcon={<FaMobileScreenButton />} w={'100%'} variant={variant} colorScheme={colorScheme}>
            {content}
        </Button>
    </Link>
);

export const MobileLink: React.FC<LinkProps> = (props) => (
    <Link {...props} href={href}>
        {content}
    </Link>
);
