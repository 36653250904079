import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const KopfschmerzenItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Kopfschmerzen sind ein häufiges Symptom, das verschiedene Ursachen haben kann. Die meisten Kopfschmerzen sind harmlos und werden durch
            Spannung, Stress, Müdigkeit, Dehydrierung oder bestimmte Lebensmittel ausgelöst. Es gibt jedoch auch spezielle Arten von Kopfschmerzen,
            die eigene Erkrankungen sind. Hier sind einige der häufigsten Arten von Kopfschmerzen:
        </Text>
        <OrderedList>
            <ListItem>
                Spannungskopfschmerzen: Dies sind die häufigsten Kopfschmerzen und werden oft als drückender oder ziehender Schmerz im Bereich von
                Stirn, Schläfen oder Hinterkopf empfunden. Sie werden oft durch Stress, Muskelverspannungen, schlechte Körperhaltung oder
                Augenbelastung verursacht.
            </ListItem>
            <ListItem>
                Migräne: Migräne ist eine anfallsartige Kopfschmerzerkrankung, die von starken, pulsierenden Schmerzen begleitet sein kann, die auf
                einer Seite des Kopfes auftreten. Migräne kann von weiteren Symptomen wie Übelkeit, Erbrechen, Licht- und Geräuschempfindlichkeit
                begleitet werden.
            </ListItem>
            <ListItem>
                Cluster-Kopfschmerzen: Cluster-Kopfschmerzen sind äußerst schmerzhafte Kopfschmerzattacken, die in Clustern auftreten. Sie werden oft
                als brennender, stechender Schmerz um ein Auge oder eine Schläfe herum beschrieben. Cluster-Kopfschmerzen treten häufig in
                regelmäßigen Abständen auf und können von begleitenden Symptomen wie der Rötung des Auges, einem Tränenfluss oder anderen grippalen
                Symptomen begleitet sein.
            </ListItem>
            <ListItem>
                Sinusitis-Kopfschmerzen: Eine Sinusitis (Nasennebenhöhlenentzündung) kann zu Kopfschmerzen führen, die sich typischerweise im Bereich
                der Stirn, der Wangen oder um die Augen herum befinden. Die Schmerzen werden oft als dumpf oder drückend empfunden und können mit
                grippalen Symptomen wie einem Nasenausfluss oder / und Gesichtsschmerzen einhergehen.
            </ListItem>
            <ListItem>
                Hormonelle Kopfschmerzen: Bei einigen Menschen treten Kopfschmerzen in Zusammenhang mit hormonellen Veränderungen auf, wie z. B. bei
                Frauen während des Menstruationszyklus, während der Schwangerschaft oder in den Wechseljahren.
            </ListItem>
        </OrderedList>
        <Text>Es gibt verschiedene Behandlungsmöglichkeiten für Kopfschmerzen, je nach Art und Ursache. Dazu gehören:</Text>
        <OrderedList>
            <ListItem>Schmerzmittel: Bei leichten bis mäßigen Kopfschmerzen können Schmerzmittel wie Paracetamol oder Ibuprofen helfen.</ListItem>
            <ListItem>
                Die Akupunktur wird bereits seit Jahrtausenden sehr erfolgreich zur Therapie von Kopfschmerzen, insbesondere der Migräne, eingesetzt.
            </ListItem>
            <ListItem>
                Entspannungstechniken: Stressabbau und Entspannungstechniken wie tiefe Atmung, Meditation oder Yoga können bei Spannungskopfschmerzen
                helfen.
            </ListItem>
            <ListItem>
                Vermeidung von Auslösern: Wenn bestimmte Lebensmittel, Getränke oder Umweltfaktoren Kopfschmerzen auslösen, sollten diese vermieden
                werden.
            </ListItem>
            <ListItem>Triptane: Spezielle Medikamente, wie Triptane, werden zur Behandlung von Migräne eingesetzt.</ListItem>
            <ListItem>
                Vorbeugende Maßnahmen: Bei wiederkehrenden oder chronischen Kopfschmerzen können vorbeugende Maßnahmen wie regelmäßige körperliche
                Aktivität, ausreichend Schlaf und Stressmanagement hilfreich sein.
            </ListItem>
        </OrderedList>
        <Text>
            Falls Sie Probleme mit Kopfschmerzen haben, sollten diese fachärztlich abgeklärt werden. Falls die Ursache im Fachbereich Orthopädie
            liegen sollte, gibt es verschiedene, effektive Diagnostik- und Behandlungsmöglichkeiten, mit denen wir helfen können. Wir beraten Sie
            gerne.
        </Text>
    </Stack>
);
