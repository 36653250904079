import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const FersenspornItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Fersensporn ist ein knöcherner Auswuchs, der sich an der Unterseite des Fersenbeins (Calcaneus) bildet. Er tritt häufig als Reaktion
            auf langfristige Überlastung und Zugspannung der Plantarfaszie auf, eine dicke Bandstruktur, die die Fußsohle stabilisiert.
        </Text>
        <Text>
            Die Hauptursache eines Fersensporns ist die Plantarfasziitis, eine Entzündung der Plantarfaszie. Wiederholte Mikroverletzungen oder eine
            chronische Überlastung der Faszie führen zur Bildung von Kalkablagerungen am Fersenbein. Dieser knöcherne Auswuchs wird als Fersensporn
            bezeichnet.
        </Text>
        <Text>
            Ein Fersensporn verursacht in erster Linie Schmerzen an der Fersenunterseite, insbesondere nach längeren Phasen des Stehens oder Gehens
            und nach dem Aufstehen am Morgen. Der Schmerz kann anfangs als stechend oder brennend empfunden werden und im Laufe des Tages zunehmen.
            Mechanischer Druck auf den Fersensporn kann ebenfalls Schmerzen verursachen.
        </Text>
        <Text>
            Die Diagnose eines Fersensporns erfolgt in der Regel an Hand der Symptome des Patienten und einer körperlichen Untersuchung des Fußes.
            Bildgebende Verfahren wie Röntgenaufnahmen können verwendet werden, um den Fersensporn sichtbar zu machen und andere mögliche Ursachen für
            die Schmerzen auszuschließen.
        </Text>
        <Text>
            Die Behandlung eines Fersensporns zielt darauf ab, die Schmerzen zu lindern und die Entzündung in der Plantarfaszie zu reduzieren. Zu den
            konservativen Behandlungsmethoden gehören:
        </Text>
        <OrderedList>
            <ListItem>Ruhe und Vermeidung von belastenden Aktivitäten, die die Schmerzen verschlimmern könnten.</ListItem>
            <ListItem>Eisbehandlung zur Linderung der Schmerzen und der Entzündung.</ListItem>
            <ListItem>Schmerzlindernde Medikamente wie nichtsteroidale entzündungshemmende Medikamente.</ListItem>
            <ListItem>Dehnübungen der Plantarfaszie und der Wadenmuskulatur.</ListItem>
            <ListItem>Verwendung von speziellen Einlagen oder Schuhpolstern, um die Belastung auf den Fuß zu reduzieren.</ListItem>
            <ListItem>Physiotherapie, um die Muskulatur zu stärken und die Beweglichkeit zu verbessern.</ListItem>
            <ListItem>
                Fokussierte Stoßwellentherapie (ESWT), eine schonende, nicht-invasive Behandlungsmethode, bei der Stoßwellen auf den Fersensporn
                gerichtet werden, um die Heilung zu fördern.
            </ListItem>
            <ListItem>
                In einigen Fällen kann eine lokale Injektion von Kortikosteroiden an den Fersensporn erwogen werden, um die Schmerzen und die
                Entzündung zu lindern.
            </ListItem>
        </OrderedList>
        <Text>
            In der überwiegenden Mehrzahl der Fälle verbessert sich die Symptomatik maßgeblich unter den o. g. konservativen Maßnahmen. In seltenen
            Einzelfällen, in denen die konservative Therapie nicht ausreichend wirksam ist, kann eine Operation in Erwägung gezogen werden.
        </Text>
        <Text>
            Falls Sie Symptome eines Fersensporns haben, ist eine kurzfristige Vorstellung bei dem Facharzt für Orthopädie Ihres Vertrauens sinnvoll,
            um die genaue Diagnose und eine angemessene Behandlung für den Fersensporn zu beginnen. Ein frühes Eingreifen und die konsequente
            Anwendung konservativer Maßnahmen führen in der Regel zu einer effektiven Schmerzlinderung und vollständigen Genesung.
        </Text>
    </Stack>
);
