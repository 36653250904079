import { Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const CoxarthroseItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Coxarthrose, auch als Hüftarthrose bezeichnet, ist eine degenerative Gelenkerkrankung, bei der der Knorpel im Hüftgelenk allmählich
            abgebaut wird. Coxarthrose tritt in der Regel im fortgeschrittenen Alter auf, kann aber auch durch Verletzungen, angeborene Fehlbildungen
            oder langfristige Überlastung des Hüftgelenks verursacht werden.
        </Text>
        <Text>
            Die Symptome der Coxarthrose umfassen Schmerzen im Hüftgelenk, die sich bei Bewegung oder Belastung verschlimmern und in Ruhe nachlassen
            können. Die Schmerzen können bis ins Gesäß, in die Leiste oder den Oberschenkel ausstrahlen. Steifheit und eingeschränkte Beweglichkeit
            des Hüftgelenks sind ebenfalls häufige Symptome. Im fortgeschrittenen Stadium kann es zu einer Verringerung der Muskelkraft im betroffenen
            Bein kommen, die ebenso wie die mechanische Bewegungseinschränkung zu einer Veränderung des Gangbildes führen kann.
        </Text>
        <Text>
            Die Diagnose einer Coxarthrose erfolgt in der Regel durch eine Kombination aus körperlicher Untersuchung, Bewertung der Symptome des
            Patienten und bildgebenden Verfahren wie Röntgenaufnahmen oder Magnetresonanztomographie (MRT). Diese Untersuchungen können den Zustand
            des Hüftgelenks und den Grad des Knorpelabbaus aufzeigen.
        </Text>
        <Text>
            Die Behandlung der Coxarthrose zielt darauf ab, Schmerzen zu lindern, die Funktion des Hüftgelenks zu verbessern und die Lebensqualität
            des Patienten zu verbessern oder zu erhalten. Zu den konservativen Behandlungsmethoden gehören eine Schmerzmedikation, Physiotherapie zur
            Stärkung der Muskulatur, Infiltrationen mit Hyaluronsäure, Kortikosteroiden oder Eigenblutsubstraten (ACP), eine Gewichtsreduktion bei
            Übergewicht, die Vermeidung belastender Aktivitäten, der Einsatz von Gehhilfen und eine Anpassungen des Lebensstils, um die Belastung des
            Hüftgelenks zu verringern.
        </Text>
        <Text>
            In fortgeschrittenen Fällen, in denen konservative Maßnahmen keine ausreichende Linderung mehr bieten, kann die Implantation einer
            Hüftgelenksendoprothese erwogen werden. Die Entscheidung bezüglich einer Operation hängt von verschiedenen Faktoren ab wie z. B. dem Alter
            des Patienten, dem Schweregrad der Symptome, dem allgemeinen Gesundheitszustand und den individuellen Bedürfnissen des Patienten.
        </Text>
        <Text>
            Die Nachbehandlung nach einer Hüftgelenksoperation sollte durch einen Facharzt für Orthopädie betreut und überwacht werden, um das
            bestmögliche Endergebnis zu erreichen. Hier ist die Physiotherapie ein wichtiger Bestandteil, um die Beweglichkeit wiederherzustellen, die
            Muskulatur zu stärken und die Funktion des Hüftgelenks zu verbessern.
        </Text>
        <Text>
            Falls Sie Symptome einer Coxarthrose erkennen, ist eine Vorstellung bei dem Facharzt für Orthopädie Ihres Vertrauens ratsam, um eine
            genaue Diagnose zu erhalten und die besten Behandlungsoptionen zu besprechen. Eine frühzeitige Behandlung kann dazu beitragen, Schmerzen
            zu lindern und die Progression der Erkrankung deutlich zu verlangsamen.
        </Text>
    </Stack>
);
