import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, Link, LinkProps } from '@chakra-ui/react';
import React from 'react';

interface IButtonProps extends LinkProps {
    colorScheme?: 'primary' | 'secondary';
}

export const DoctolibButton: React.FC<IButtonProps> = ({ colorScheme = 'primary', ...rest }) => (
    <Link href='https://www.doctolib.de/privatpraxis/meerbusch/privatpraxis-dr-festring' target='_blank' {...rest}>
        <Button size={{ base: 'sm', md: 'md' }} leftIcon={<ExternalLinkIcon />} colorScheme={colorScheme} w={'100%'}>
            Termin buchen
        </Button>
    </Link>
);

interface IProps extends LinkProps {
    children: React.ReactElement;
}

export const Doctolib: React.FC<IProps> = (props) => {
    const { children, ...rest } = props;

    return (
        <Link href='https://www.doctolib.de/privatpraxis/meerbusch/privatpraxis-dr-festring' target='_blank' {...rest}>
            {children}
        </Link>
    );
};
