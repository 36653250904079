import * as React from 'react';
import { Box, Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import HyaImage from 'images/leistungen/Hyaluron.jpg';

export const HyaluronItem: React.FC = (): JSX.Element => {
    return (
        <Stack textAlign={'justify'}>
            <Text>
                Die Hyaluronsäure-Infiltration, auch als Hyaluronsäuretherapie oder viskosupplementäre Therapie bekannt, ist eine Behandlungsmethode,
                die bei Gelenkerkrankungen wie Arthrose eingesetzt wird. Arthrose ist eine degenerative Gelenkerkrankung, bei der der Knorpel in den
                Gelenken allmählich abbaut, was zu Schmerzen, Entzündungen und Einschränkungen der Gelenkbeweglichkeit führen kann.
            </Text>
            <Text>
                Bei der Hyaluronsäure-Infiltration wird eine Lösung mit Hyaluronsäure, einer natürlichen Substanz, die normalerweise im Gelenkknorpel
                vorkommt, in das betroffene Gelenk injiziert. Hyaluronsäure besitzt viskoelastische Eigenschaften, die dabei helfen können, die
                Gelenkfunktion zu verbessern und die Symptome der Arthrose zu lindern:
            </Text>
            <OrderedList>
                <ListItem>
                    Schmierung und Stoßdämpfung: Hyaluronsäure kann als Schmiermittel im Gelenk wirken und die Gelenkbeweglichkeit verbessern. Zudem
                    besitzt sie stoßdämpfende Eigenschaften, die die Belastung auf den Gelenkknorpel verringern können.
                </ListItem>
                <ListItem>
                    Entzündungshemmung: Hyaluronsäure kann entzündungshemmende Effekte haben, indem sie bestimmte entzündungsfördernde Substanzen im
                    Gelenk reduziert.
                </ListItem>
                <ListItem>
                    Stimulierung der Knorpelregeneration: Hyaluronsäure kann das Umfeld im Gelenk verbessern und die körpereigene Knorpelregeneration
                    anregen.
                </ListItem>
            </OrderedList>
            <Box>
                <Image
                    float={'right'}
                    src={HyaImage}
                    w={{ base: '100%', md: 520 }}
                    ml={4}
                    mb={{ base: 2, md: 0 }}
                    boxShadow={'md'}
                    alt='Hyaluronsäure'
                />
                <Text mb={2}>
                    Die Hyaluronsäure-Infiltration wird in der Regel in mehreren Sitzungen im Abstand von Wochen durchgeführt. Die genaue Anzahl der
                    Injektionen hängt von der Schwere der Arthrose und den individuellen Bedürfnissen des Patienten ab. Die Infiltration wird
                    normalerweise unter sterilen Bedingungen durchgeführt und kann mit lokaler Betäubung oder unter Verwendung von bildgebenden
                    Verfahren wie Ultraschall oder Röntgenkontrolle erfolgen.
                </Text>
                <Text mb={2}>
                    Sie kann bei vielen Patienten zu einer Linderung der Schmerzen und einer Verbesserung der Gelenkfunktion führen. Die Wirkung hält
                    in der Regel mehrere Monate an, kann jedoch von Person zu Person variieren. Die Behandlung kann in einigen Fällen wiederholt
                    werden, um die positiven Effekte aufrechtzuerhalten.
                </Text>
                <Text>
                    Wie bei jeder medizinischen Behandlung können auch bei der Hyaluronsäure-Infiltration Nebenwirkungen auftreten, wie vorübergehende
                    Schmerzen, Schwellungen oder Rötungen an der Injektionsstelle. Schwere Nebenwirkungen sind äußerst selten.
                </Text>
            </Box>
            <Text>
                Es ist wichtig, dass die Hyaluronsäure-Infiltration von einem qualifizierten Facharzt durchgeführt wird, der Ihre spezifische
                Situation bewertet und Ihnen die besten Behandlungsmöglichkeiten empfiehlt.
            </Text>
        </Stack>
    );
};
