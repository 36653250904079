import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const ImpingementSyndromItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Mit einem Impingement-Syndrom ist in der Regel das Schulterimpingement-Syndrom gemeint. Es handelt sich um eine schmerzhafte Erkrankung
            der Schulter, bei der die Weichteile in einem Anteil des Schultergelenks eingeklemmt werden. Es tritt häufig aufgrund von Reibung und
            Kompression zwischen dem Oberarmknochen (Humerus) und dem Schulterdach (Akromion) auf. Das Impingement-Syndrom kann sowohl akut als auch
            chronisch auftreten.
        </Text>
        <Text>Die Hauptursachen des Impingement-Syndroms sind in der Regel:</Text>
        <OrderedList>
            <ListItem>
                Degenerative Veränderungen: Mit zunehmendem Alter können degenerative Veränderungen im Schultergelenk auftreten, wie z. B.
                Knochenwucherungen oder Verdickungen des Schulterdachs, die den Raum für die Weichteile verringern und zu einem „Impingement“ führen
                können.
            </ListItem>
            <ListItem>
                Überbeanspruchung oder repetitive Bewegungen: Aktivitäten, die wiederholte Überkopfbewegungen oder eine kontinuierliche Belastung der
                Schulter erfordern, wie z. B. bestimmte Sportarten (z. B. Tennis, Schwimmen) oder berufliche Tätigkeiten (z. B. Maler, Handwerker),
                können das Risiko für das Auftreten eines Impingement-Syndroms erhöhen.
            </ListItem>
            <ListItem>
                Fehlhaltungen oder muskuläre Dysbalancen: Eine schlechte Körperhaltung, insbesondere eine nach vorne gerichtete Schulterhaltung, sowie
                muskuläre Dysbalancen um die Schulter herum können zu einer Fehlfunktion des Schultergelenks führen und das Risiko für das Auftreten
                eines Impingement-Syndroms erhöhen.
            </ListItem>
        </OrderedList>
        <Text>Die Symptome des Impingement-Syndroms können variieren, können jedoch Folgendes umfassen:</Text>
        <OrderedList>
            <ListItem>Schmerzen in der Schulter, die sich insbesondere bei Überkopfbewegungen oder dem Anheben des Arms verschlimmern.</ListItem>
            <ListItem>Bewegungseinschränkungen des Arms, insbesondere beim Anheben des Arms seitlich oder über den Kopf.</ListItem>
            <ListItem>Schwäche der Schultergürtelmuskulatur.</ListItem>
            <ListItem>Knirschende oder knisternde Geräusche im Bereich des Schultergelenks.</ListItem>
            <ListItem>Schwellung oder Entzündung in der Schulterregion.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung des Impingement-Syndroms zielt darauf ab, den Schmerz zu lindern, die Entzündung zu reduzieren und die normale Funktion der
            Schulter wiederherzustellen. Die Behandlungsmöglichkeiten können folgendes umfassen:
        </Text>
        <OrderedList>
            <ListItem>
                Konservative Maßnahmen: Ruhe, Vermeidung von übermäßiger Belastung der Schulter, physikalische Therapie zur Stärkung der
                Schultermuskulatur und Verbesserung der Beweglichkeit, Anwendung von Eis oder Wärme zur Schmerzlinderung und Verwendung von schmerz-
                und / oder entzündungshemmenden Medikamenten. Akupunktur kann zur Schmerz- und Entzündungshemmung angewendet werden. Die
                Stoßwellentherapie ist eine schonende und gleichzeitig effektive Therapiemöglichkeit zur Verbesserung der Durchblutung, Stimulation
                von Stoffwechselprozessen und Förderung der Selbstheilung. Die Magnetfeldtherapie kann eingesetzt werden zur Schmerzreduktion und
                Unterstützung der Selbstheilungsprozesse.
            </ListItem>
            <ListItem>
                Injektionen: Bei starken Schmerzen kann eine Kortikosteroid-Injektion in die Schulter zur kurzfristigen Linderung von Schmerzen und
                Entzündungen erwogen werden. Ebenso kommt eine Eigenblutspritzentherapie ggf. in Frage.
            </ListItem>
            <ListItem>
                Operation: Nach Ausschöpfen der konservativen Therapiemöglichkeiten oder auf ausdrücklichen Wunsch des Patienten kann ggf. auch eine
                Operation in Erwägung gezogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Es ist empfehlenswert bei Schulterschmerzen den Facharzt für Orthopädie Ihres Vertrauens aufzusuchen, damit eine genaue Diagnose gestellt
            und die besten Behandlungsmöglichkeiten besprochen und umgesetzt werden können. Insbesondere sollten immer schwerwiegendere Diagnosen, wie
            z. B. eine strukturelle Schädigung der für das Schultergelenk wichtigen Rotatorenmanschette, ausgeschlossen werden. Eine frühzeitige
            Behandlung kann entscheidend dazu beitragen, die Symptome zügig zu lindern, Komplikationen zu vermeiden und eine vollständige Genesung zu
            ermöglichen.
        </Text>
    </Stack>
);
