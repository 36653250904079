import * as React from 'react';
import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';

export const InfusionItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Infusionstherapie bezieht sich auf die Verabreichung von Flüssigkeiten, Medikamenten oder Nährstoffen direkt in den Blutkreislauf
            eines Patienten über einen intravenösen Zugang. Sie wird in der medizinischen Praxis in verschiedenen Situationen eingesetzt, um
            verschiedene Ziele zu erreichen, wie z. B. Flüssigkeits- und Elektrolytausgleich, Medikamentenverabreichung sowie die Zufuhr von
            Nährstoffen oder Nahrungsergänzungsmitteln.
        </Text>
        <Text>Es gibt verschiedene Arten von Infusionen, die angewendet werden können:</Text>
        <OrderedList>
            <ListItem>
                Flüssigkeitsersatz: Infusionen werden verwendet, um Flüssigkeitsverluste auszugleichen, die durch Dehydratation, Erbrechen, Durchfall,
                Blutverlust oder andere Ursachen entstehen können. Dabei werden in der Regel isotonische Lösungen wie Ringer-Laktat oder
                physiologische Kochsalzlösung verabreicht.
            </ListItem>
            <ListItem>
                Elektrolytsubstitution: Bei einem Elektrolytungleichgewicht, z.B. bei Störungen des Salz- und Mineralstoffhaushalts, können Infusionen
                eingesetzt werden, um die Elektrolyte im Körper wieder ins Gleichgewicht zu bringen.
            </ListItem>
            <ListItem>
                Medikamentenverabreichung: Einige Medikamente werden aufgrund ihrer Wirkung oder Verträglichkeit am besten über Infusionen
                verabreicht. Dies kann bei Schmerzmitteln, Osteoporose-Medikamenten, Antibiotika, Krebsmedikamenten oder anderen Medikamenten der Fall
                sein. Die Infusion ermöglicht eine kontrollierte und gezielte Verabreichung des Medikaments.
            </ListItem>
            <ListItem>
                Nährstoffunterstützung: Nahrungsergänzungsmittel und Nährstoffe wie Aminosäuren, Fette, Vitamine und Mineralstoffe können direkt in
                den Blutkreislauf verabreicht werden, z. B. zur Steigerung der Immunabwehr oder des geistigen und körperlichen Wohlbefindens oder der
                Leistungsfähigkeit.
            </ListItem>
        </OrderedList>
        <Text>
            Die Infusionstherapie erfordert eine sorgfältige Überwachung des Patienten, einschließlich der Vitalzeichen und der Flüssigkeitsbilanz.
            Die Auswahl der Infusionslösung, die Dosierung und die Dauer der Infusion werden individuell auf den Patienten und seine spezifische
            Situation abgestimmt.
        </Text>
    </Stack>
);
