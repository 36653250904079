import { CheckIcon } from '@chakra-ui/icons';
import { Card, CardBody, CardHeader, Grid, GridItem, Icon, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { MdFamilyRestroom, MdLocationPin } from 'react-icons/md';
import { FaBookMedical, FaUniversity } from 'react-icons/fa';
import { Jameda } from 'components/Jameda';
import { FaClinicMedical } from 'react-icons/fa';

const zusatzQualiItems: string[] = [
    'Zusatzbezeichnung Sportmedizin',
    'Zusatzbezeichnung Manuelle Medizin / Chirotherapie',
    'Zusatzbezeichnung Akupunktur',
];

const qualiItems: string[] = [
    'Akupunktur A-Diplom',
    'Interdisziplinäre Schmerztherapie',
    'Psychosomatische Grundversorgung',
    'Fachkunde im Strahlenschutz',
    'Osteopathie',
];

export const DrFestring: React.FC = () => {
    return (
        <Stack spacing={4}>
            <SimpleGrid spacing={4} columns={{ base: 1, md: 2 }}>
                <Card>
                    <CardHeader fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} textTransform={'uppercase'} color={'secondary.500'} pb={2}>
                        Zusatzbezeichnungen
                    </CardHeader>
                    <CardBody pt={0}>
                        <Grid templateColumns={'40px 1fr'} gap={4}>
                            {zusatzQualiItems.map((item, index) => (
                                <React.Fragment key={index}>
                                    <GridItem textAlign={'center'}>
                                        <CheckIcon boxSize={6} color='primary.500' />
                                    </GridItem>
                                    <GridItem>
                                        <Text>{item}</Text>
                                    </GridItem>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} textTransform={'uppercase'} color={'secondary.500'} pb={2}>
                        Weitere Qualifikationen
                    </CardHeader>
                    <CardBody pt={0}>
                        <Grid templateColumns={'40px 1fr'} gap={4}>
                            {qualiItems.map((item, index) => (
                                <React.Fragment key={index}>
                                    <GridItem textAlign={'center'}>
                                        <CheckIcon boxSize={6} color='primary.500' />
                                    </GridItem>
                                    <GridItem>
                                        <Text>{item}</Text>
                                    </GridItem>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </CardBody>
                </Card>
            </SimpleGrid>
            <Card boxShadow={'sm'} border='0.06666rem solid rgba(0,0,0,0.08)'>
                <CardHeader fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} textTransform={'uppercase'} color={'secondary.500'} pb={2}>
                    Privates
                </CardHeader>
                <CardBody pt={0}>
                    <Grid templateColumns={'40px 1fr'} gap={4}>
                        <GridItem textAlign={'center'}>
                            <Icon boxSize={6} as={MdLocationPin} color='primary.500' />
                        </GridItem>
                        <GridItem>
                            <Text>Geboren und aufgewachsen in Münster</Text>
                        </GridItem>
                        <GridItem textAlign={'center'}>
                            <Icon boxSize={6} as={FaUniversity} color='primary.500' />
                        </GridItem>
                        <GridItem>
                            <Text>Medizinstudium in Lübeck und Basel / Schweiz</Text>
                        </GridItem>
                        <GridItem textAlign={'center'}>
                            <Icon boxSize={6} as={FaBookMedical} color='primary.500' />
                        </GridItem>
                        <GridItem>
                            <Text>Kliniktätigkeit in Köln, Leverkusen und Neustadt</Text>
                        </GridItem>
                        <GridItem textAlign={'center'}>
                            <Icon boxSize={6} as={FaClinicMedical} color='primary.500' />
                        </GridItem>
                        <GridItem>
                            <Text>Praxistätigkeit seit 2018</Text>
                        </GridItem>
                        <GridItem textAlign={'center'}>
                            <Icon boxSize={6} as={MdFamilyRestroom} color='primary.500' />
                        </GridItem>
                        <GridItem>
                            <Text>Verheiratet, eine Tochter</Text>
                        </GridItem>
                    </Grid>
                </CardBody>
            </Card>
            <Jameda />
        </Stack>
    );
};
