import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const KnorpelschadenItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Knorpelschaden entsteht durch eine akute Verletzung oder durch eine chronische Abnutzung von Knorpelgewebe, das die Gelenkflächen
            bedeckt und schützt. Knorpel ist ein glattes, elastisches Gewebe, das als Stoßdämpfer fungiert und den reibungsarmen Bewegungsablauf in
            den Gelenken ermöglicht. Wenn der Knorpel geschädigt wird, kann dies zu Schmerzen, Entzündungen und Bewegungseinschränkungen führen.
        </Text>
        <Text>Die Ursachen für einen Knorpelschaden können vielfältig sein:</Text>
        <OrderedList>
            <ListItem>
                Akute Verletzungen: Plötzliche, traumatische Ereignisse wie Stürze, Sportverletzungen oder Unfälle können zu einem Knorpelschaden
                führen. So kann z. B. durch eine Gelenkverdrehung oder durch einen direkten Aufprall ein Knorpelschaden entstehen.
            </ListItem>
            <ListItem>
                Chronische Überlastung: Wiederholte Belastungen über einen längeren Zeitraum können zu einem allmählichen Verschleiß des
                Knorpelgewebes führen. Dies betrifft häufig Sportler oder Menschen, die ihre Gelenke übermäßig belasten.
            </ListItem>
            <ListItem>
                Gelenkerkrankungen: Bestimmte Gelenkerkrankungen wie Arthrose, rheumatoide Arthritis oder Gicht können den Knorpel schädigen und zu
                einem Knorpelschaden führen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Symptome eines Knorpelschadens können je nach Schweregrad und betroffenem Gelenk variieren. Zu den häufigsten Symptomen gehören:
        </Text>
        <OrderedList>
            <ListItem>Schmerzen: Schmerzen im betroffenen Gelenk, die bei Bewegung oder Belastung zunehmen können.</ListItem>
            <ListItem>Schwellung und Entzündung: Das Gelenk kann geschwollen, warm und entzündet sein.</ListItem>
            <ListItem>
                Bewegungseinschränkungen: Die Beweglichkeit des Gelenks kann eingeschränkt sein, insbesondere bei schweren Knorpelschäden.
            </ListItem>
            <ListItem>
                Gelenksteifheit: Das Gelenk kann steif oder unbeweglich sein, insbesondere nach Ruhephasen, z. B. nach dem Aufstehen am Morgen.
            </ListItem>
            <ListItem>
                Knirschende oder knackende Geräusche: Beim Bewegen des betroffenen Gelenks können Knirsch- oder Knackgeräusche auftreten.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung eines Knorpelschadens hängt von verschiedenen Faktoren ab, wie dem Ausmaß des Schadens, der Lokalisation des Schadens und
            den individuellen Symptomen. Mögliche Behandlungsoptionen umfassen:
        </Text>
        <OrderedList>
            <ListItem>
                Konservative Behandlung: In vielen Fällen kann eine konservative Therapie angewendet werden, um Schmerzen zu lindern und die Funktion
                des Gelenks zu verbessern. Dazu gehören Ruhe, Schonung des Gelenks, physikalische Therapie, entzündungshemmende Medikamente und ggf.
                die Anwendung von Kälte- oder Wärmeanwendungen. Eine Injektionstherapie z. B. mit Hyaluronsäure oder Eigenblut (ACP-Therapie) können
                effektive Maßnahmen zur Schmerzlinderung, Entzündungshemmung sein und die Schmierung und Stoßdämpfung des Gelenks wieder verbessern.
                Die Magnetfeldtherapie kann ggf. zur Schmerzreduktion und Unterstützung der Selbstheilungsprozesse eingesetzt werden.
            </ListItem>
            <ListItem>
                Operative Behandlung: Hier kommen arthroskopische Verfahren, unter bestimmten Umständen Knorpel- / Knorpelzelltransplantationen und
                Umstellungsosteotomien in Frage. Der Einsatz dieser Verfahren sollten jedoch äußerst sorgsam abgewogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung eines Knorpelschadens erfordert eine individuelle Herangehensweise unter Einbezug aller möglichen Vor- und Nachteile und
            sollte von einem Facharzt für Orthopädie begleitet. Eine frühzeitige Diagnose und angemessene Behandlung können helfen, weitere Schäden
            abzuwenden und die Funktion des betroffenen Gelenks nachhaltig zu verbessern.
        </Text>
    </Stack>
);
