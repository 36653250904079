import * as React from 'react';
import { Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import Biofeedback1 from 'images/leistungen/EMG-Bio1.jpg';

export const BiofeedbackItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Biofeedback ist eine Therapiemethode, bei der Menschen lernen, ihre physiologischen Körperfunktionen bewusst wahrzunehmen und zu
            kontrollieren. In der Orthopädie und Sportmedizin werden Messverfahren wie z. B. die Elektromyographie (EMG) verwendet, um die biologische
            Muskelaktivität zu erfassen und diese Informationen dem Patienten in Echtzeit zurückzumelden.
        </Text>
        <Text>
            Das Ziel des Biofeedbacks besteht darin, dem Patienten ein Bewusstsein für seine körperlichen Reaktionen zu vermitteln und ihn zu
            befähigen, diese Reaktionen aktiv zu beeinflussen. Durch die Rückmeldung in Echtzeit kann der Patient lernen, seine Körperreaktionen zu
            erkennen, zu verstehen und zu kontrollieren.
        </Text>
        <Image src={Biofeedback1} w={'100%'} alt='Biofeedback1' boxShadow={'md'} />
        <Text>Biofeedback kann bei verschiedenen Zuständen und Erkrankungen eingesetzt werden, einschließlich:</Text>
        <OrderedList>
            <ListItem>
                Stressabbau: Durch das Erlernen von Entspannungstechniken und Stressbewältigungsstrategien kann Biofeedback helfen, Stresssymptome wie
                z. B. schmerzhafte Muskelverspannungen zu reduzieren.
            </ListItem>
            <ListItem>
                Schmerzmanagement: Biofeedback kann Menschen mit chronischen Schmerzen helfen, ihre Schmerzwahrnehmung zu kontrollieren und die
                Schmerzintensität zu verringern. Durch das Lernen von Entspannungs- und Schmerzbewältigungstechniken können sie ihre Schmerzreaktionen
                beeinflussen.
            </ListItem>
            <ListItem>
                Muskelfunktionstraining: Bei Muskelverspannungen oder muskulären Dysfunktionen kann Biofeedback eingesetzt werden, um die
                Muskelaktivität zu messen und den Patienten dabei zu unterstützen, die richtige Muskelspannung und -entspannung zu erlernen.
            </ListItem>
            <ListItem>
                Neurologische Erkrankungen: Biofeedback kann bei neurologischen Störungen wie Migräne, Spannungskopfschmerzen, Tremor oder
                Aufmerksamkeitsdefizit-Hyperaktivitätsstörung (ADHS) eingesetzt werden, um die Kontrolle über die physiologischen Funktionen zu
                verbessern.
            </ListItem>
        </OrderedList>
        <Text>
            Während einer Biofeedback-Sitzung werden die Sensoren am Körper des Patienten platziert und es wird erklärt, wie das Feedbacksystem
            funktioniert. Der Patient erhält dann visuelle oder auditive Rückmeldungen über seine Körperreaktionen und lernt, diese zu beeinflussen.
            Mit der Zeit kann der Patient die erlernten Fähigkeiten in den Alltag integrieren und selbstständig anwenden.
        </Text>
        <Text>
            Biofeedback kann eine wirksame Ergänzung zu anderen Therapiemethoden sein, wie beispielsweise Entspannungstechniken, Physiotherapie oder
            kognitive Verhaltenstherapie. Biofeedback wird in der Regel als Teil einer umfassenden Behandlungsstrategie angewendet und sollte von
            einem qualifizierten Facharzt durchgeführt werden, der die individuellen Bedürfnisse und Ziele des Patienten berücksichtigt.
        </Text>
    </Stack>
);
