import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const HexenschussItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Hexenschuss, auch als akuter Lumbago oder akuter Rückenschmerz im Bereich der Lendenwirbelsäule bezeichnet, ist ein plötzlich
            auftretender und intensiver Schmerz im unteren Rückenbereich. Es handelt sich um eine sehr häufige Erkrankung, die viele Menschen
            mindestens einmal in ihrem Leben betrifft.
        </Text>
        <Text>
            Ein Hexenschuss tritt normalerweise ohne erkennbare Ursache auf, kann jedoch durch verschiedene Faktoren begünstigt werden, wie zum
            Beispiel:
        </Text>
        <OrderedList>
            <ListItem>
                Übermäßige Belastung: Schweres Heben, Tragen schwerer Gegenstände oder plötzliche, ungewohnte Bewegungen können den Rücken belasten
                und zu einem Hexenschuss führen.
            </ListItem>
            <ListItem>
                Muskelverspannungen: Muskelverspannungen im Bereich der Lendenwirbelsäule können zu einem Hexenschuss führen. Diese Verspannungen
                können durch Stress, Fehlhaltungen, langes Sitzen oder körperliche Anstrengung verursacht werden.
            </ListItem>
            <ListItem>
                Bandscheibenvorfall: In einigen Fällen kann ein Bandscheibenvorfall im unteren Rückenbereich einen Hexenschuss auslösen. Ein
                Bandscheibenvorfall tritt auf, wenn der innere Kern der Bandscheibe durch den äußeren Ring austritt.
            </ListItem>
            <ListItem>
                Alter: Das Risiko für einen Hexenschuss steigt mit zunehmendem Alter aufgrund des natürlichen Verschleißes der Wirbelsäule.
            </ListItem>
        </OrderedList>
        <Text>Die Symptome eines Hexenschusses umfassen:</Text>
        <OrderedList>
            <ListItem>Plötzlicher und intensiver Schmerz im unteren Rückenbereich, der sich wie ein eingeklemmter Nerv anfühlen kann.</ListItem>
            <ListItem>Bewegungseinschränkungen und Schwierigkeiten beim Bücken oder Aufrichten.</ListItem>
            <ListItem>Muskelverspannungen und Verhärtungen im unteren Rückenbereich.</ListItem>
            <ListItem>Eventuell Ausstrahlung des Schmerzes in die Gesäßregion oder die Beine.</ListItem>
            <ListItem>Möglicherweise besteht ein Taubheitsgefühl oder Kribbeln in den Beinen.</ListItem>
        </OrderedList>
        <Text>
            In den meisten Fällen heilt der Hexenschuss innerhalb weniger Tage bis Wochen von selbst aus. In der Regel werden konservative
            Behandlungsmethoden empfohlen, um die Symptome zu lindern und den Heilungsprozess zu unterstützen. Dazu gehören:
        </Text>
        <OrderedList>
            <ListItem>
                Ruhe und Schonung: Eine vorübergehende Begrenzung der Aktivitäten und Vermeidung von schwerem Heben oder Überanstrengung.
            </ListItem>
            <ListItem>Schmerzlinderung: Die Einnahme von schmerz- und / oder entzündungshemmenden Medikamenten.</ListItem>
            <ListItem>
                Wärme- oder ggf. Kältetherapie: Die Anwendung von warmen (oder ggf. kalten) Kompressen kann zur Linderung von Schmerzen und
                Muskelverspannungen beitragen.
            </ListItem>
            <ListItem>
                Physiotherapie: Durch gezielte Übungen und Physiotherapie kann die Rückenmuskulatur gestärkt und die Beweglichkeit verbessert werden.
            </ListItem>
            <ListItem>Infiltrationen, um die Schmerzen und die Entzündung zu reduzieren.</ListItem>
            <ListItem>Akupunktur zur Schmerz- und Entzündungshemmung.</ListItem>
            <ListItem>
                Stoßwellentherapie zur Verbesserung der Durchblutung, Stimulation von Stoffwechselprozessen und Förderung der Selbstheilung.
            </ListItem>
            <ListItem>Magnetfeldtherapie zur Schmerzreduktion und Unterstützung der Selbstheilungsprozesse.</ListItem>
            <ListItem>
                Ergonomische Anpassungen: Eine ergonomische Gestaltung des Arbeitsplatzes und korrekte Körperhaltung können helfen, Rückenschmerzen
                vorzubeugen.
            </ListItem>
        </OrderedList>
        <Text>
            Wenn die Symptome stark sind, länger als einige Tage anhalten oder spätestens wenn neurologische Symptome wie Beinschmerzen,
            Taubheitsgefühle oder Muskelschwäche auftreten, sollte umgehend ein Facharzt für Orthopädie aufgesucht werden, um eine genaue Diagnose zu
            erhalten und ggf. weitere Untersuchungen einzuleiten, um schwerwiegendere Diagnosen wie z. B. einen Bandscheibenvorfall auszuschließen und
            eine abgestimmte Behandlung zu beginnen.
        </Text>
    </Stack>
);
