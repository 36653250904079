import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const SpinalstenoseItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Spinalkanalstenose ist eine Verengung des Wirbelkanals, durch den das Rückenmark verläuft. Die Verengung kann auf das Rückenmark und
            die Nervenwurzeln drücken und dadurch Beschwerden verursachen. Die Spinalkanalstenose tritt in der Regel im Bereich der lumbalen (unteren)
            oder zervikalen (oberen) Wirbelsäule auf.
        </Text>
        <Text>
            Die Erkrankung kann angeboren sein oder sich im Laufe des Lebens entwickeln. Die erworbene Spinalkanalstenosen wird in den meisten Fällen
            durch degenerative Veränderungen der Wirbelsäule im Alter hervorgerufen. Die wichtigsten Erkrankungen, die zu einer Verengung des
            Spinalkanals führen können, sind:
        </Text>
        <OrderedList>
            <ListItem>
                Bandscheibenvorfall: Eine Bandscheibe kann sich verlagern und in den Wirbelkanal hineinragen, was zu einer Kompression des Rückenmarks
                oder / und der austretenden Nervenwurzeln führen kann.
            </ListItem>
            <ListItem>
                Degenerative Veränderungen der Wirbelsäule: Mit dem Alter können die Wirbelgelenke, Bandscheiben und Wirbelkörper verschleißen und
                knöcherne Auswüchse (Spondylophyten / Osteophyten) bilden, die den Wirbelkanal verengen.
            </ListItem>
            <ListItem>
                Wirbelgleiten (Pseudospondylolisthesis, Spondylolisthesis vera): Eine Instabilität der Wirbelsäule kann dazu führen, dass ein Wirbel
                nach vorne oder hinten gleitet und den Wirbelkanal verengt.
            </ListItem>
        </OrderedList>
        <Text>
            Die Symptome einer Spinalkanalstenose können, abhängig von der Schwere der Verengung und der betroffenen Region der Wirbelsäule, sehr
            unterschiedlich sein:
        </Text>
        <OrderedList>
            <ListItem>
                Schmerzen: Schmerzen im betroffenen Rückenabschnitt, die sich bis in die Beine oder Arme ausbreiten können. Die Schmerzen können beim
                Gehen oder Stehen zunehmen und in Ruhe oder in vornübergebeugter Haltung abnehmen.
            </ListItem>
            <ListItem>
                Taubheits- und Kribbelgefühle: Taubheits- und Kribbelgefühle oder eine Schwäche in den Beinen, im Gesäß, in den Armen oder den Händen,
                die durch die Kompression der Nervenwurzeln entstehen kann.
            </ListItem>
            <ListItem>Gangstörungen: Schwierigkeiten beim Gehen, Unsicherheit oder Schwäche in den Beinen.</ListItem>
            <ListItem>
                Blasen- oder Mastdarmentleerungsstörungen: In schweren Fällen kann eine Spinalkanalstenose zu Problemen mit der Blasen- oder
                Mastdarmentleerung führen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung einer Spinalkanalstenose hängt von der Schwere der Symptome und dem Einfluss auf die Lebensqualität ab. Zu den
            Behandlungsoptionen gehören:
        </Text>
        <OrderedList>
            <ListItem>
                Konservative Behandlung: Zu den konservativen Maßnahmen können Physiotherapie, der Ausgleich muskulärer Dysbalancen,
                Stoßwellentherapie, Magnetfeldtherapie, Akupunktur, eine Schmerzmedikation, Injektionen von ACP (Eigenbluttherapie),
                entzündungshemmenden Medikamenten oder epidurale Steroidinjektionen zur Schmerzlinderung und Entzündungshemmung gehören.
            </ListItem>
            <ListItem>
                Operation: In Einzelfällen, in denen konservative Maßnahmen keine ausreichende Linderung mehr erreichen können, kann eine Operation
                erwogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Falls Sie Symptome einer Spinalkanalstenose erkennen, ist eine Vorstellung bei dem Facharzt für Orthopädie Ihres Vertrauens sehr
            empfehlenswert, damit eine gründliche Untersuchung erfolgen und die exakte Diagnose festgestellt werden kann. Da die Festlegung der
            optimalen Behandlungsstrategie von verschiedenen individuellen Faktoren abhängt, helfen wir Ihnen sehr gerne in einem persönlichen
            Beratungsgespräch weiter.
        </Text>
    </Stack>
);
