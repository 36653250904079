import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const GonarthroseItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Gonarthrose, auch als Kniegelenksarthrose bezeichnet, ist eine verschleißbedingte Gelenkerkrankung, bei der der Knorpel im Kniegelenk
            allmählich abgebaut wird. Es ist die häufigste Form der Arthrose und betrifft in der Regel das femorotibiale Hauptgelenk, das zwischen dem
            Oberschenkelknochen (Femur) und dem Schienbein (Tibia) liegt.
        </Text>
        <Text>
            Die genauen Ursachen für eine Gonarthrose sind vielfältig und können z. B. altersbedingter Knorpelverschleiß, vorausgegangene
            Knieverletzungen, genetische Veranlagung, Übergewicht, langjährige Überlastung des Kniegelenks oder entzündliche Erkrankungen wie
            rheumatoide Arthritis sein.
        </Text>
        <Text>
            Häufig löst die Gonarthrose Schmerzen im Kniegelenk aus, die typischerweise bei Belastung auftreten und sich mit der Zeit verschlimmern
            können. Der Schmerz kann als dumpf, stechend oder ziehend empfunden werden und kann zu Steifheit und eine eingeschränkten
            Gelenkbeweglichkeit führen. Das Knie kann anschwellen, knirschen oder knacken, und es kann in fortgeschrittenen Stadien zu einer
            Deformität des Gelenks kommen.
        </Text>
        <Text>
            Die Diagnose der Gonarthrose erfolgt in der Regel durch eine Kombination aus körperlicher Untersuchung, Bewertung der Symptome des
            Patienten und bildgebenden Verfahren wie Röntgenaufnahmen oder einer Magnetresonanztomographie (MRT). Diese Untersuchungen können den
            Zustand des Knorpels im Kniegelenk darstellen und andere mögliche Ursachen der Symptome ausschließen.
        </Text>
        <Text>
            Die Behandlung von Gonarthrose zielt darauf ab, Schmerzen zu lindern, die Funktion des Kniegelenks zu verbessern und die Lebensqualität
            des Patienten zu erhalten. Konservative Behandlungsmethoden bestehen aus:
        </Text>
        <OrderedList>
            <ListItem>Schmerzmedikation und / oder entzündungshemmenden Medikamenten zur Linderung von Schmerzen und Entzündungen.</ListItem>
            <ListItem>
                Physiotherapie zur Stärkung der umgebenden Muskulatur, Verbesserung der Beweglichkeit und Optimierung der Körperhaltung und
                Biomechanik des Knies.
            </ListItem>
            <ListItem>
                Hyaluronsäure- oder Kortikosteroid-Infiltrationen in das Kniegelenk, um Entzündungen zu reduzieren und die Schmierung und Stoßdämpfung
                des Gelenks zu verbessern.
            </ListItem>
            <ListItem>Eigenbluttherapie zur Schmerz- und Entzündungshemmung.</ListItem>
            <ListItem>Akupunktur zur Schmerz- und Entzündungshemmung.</ListItem>
            <ListItem>
                Stoßwellentherapie zur Verbesserung der Durchblutung, Stimulation von Stoffwechselprozessen und Förderung der Selbstheilung.
            </ListItem>
            <ListItem>Magnetfeldtherapie zur Schmerzreduktion und Unterstützung der Selbstheilungsprozesse.</ListItem>
            <ListItem>Gewichtsmanagement, um die Belastung des Kniegelenks zu reduzieren.</ListItem>
            <ListItem>
                Verwendung von orthopädischen Hilfsmitteln wie Kniebandagen, Orthesen oder speziellen Einlagen zur Entlastung des Knies.
            </ListItem>
            <ListItem>
                Anpassung der Aktivitäten und Vermeidung von belastenden Bewegungen oder Sportarten, die den Verschleiß des Kniegelenks weiter fördern
                könnten.
            </ListItem>
        </OrderedList>
        <Text>
            In fortgeschrittenen Fällen, falls konservative Maßnahmen nicht ausreichen sollten und die Gonarthrose stark fortgeschritten und die
            Lebensqualität erheblich beeinträchtigt ist, kann eine Operation erwogen werden.
        </Text>
        <Text>
            Falls Sie Symptome einer Gonarthrose bei sich erkennen sollten, ist eine Vorstellung bei dem Facharzt für Orthopädie Ihres Vertrauens
            ratsam, um eine genaue Diagnose zu erhalten und die besten Behandlungsoptionen für die Gonarthrose zu besprechen. Eine frühzeitige
            Behandlung, ein umfassendes Behandlungskonzept und Symptom-Management können dazu beitragen, Schmerzen erfolgreich zu lindern und die
            Gelenkfunktion und damit schlussendlich auch Ihre Lebensqualität wieder entscheidend zu verbessern.
        </Text>
    </Stack>
);
