import { Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const BandscheibenprotrusionItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Eine Bandscheibenprotrusion ist eine Erkrankung der Wirbelsäule, bei der sich die Bandscheibe zwischen die Wirbelkörper nach außen
            vorwölbt. Sie entsteht in der Regel durch Alterung, Verschleiß oder übermäßige Belastung der Wirbelsäule. Eine Bandscheibenprotrusion kann
            verschiedene Symptome verursachen, abhängig von ihrer Lage und Größe.
        </Text>
        <Text>
            Typische Symptome einer Bandscheibenprotrusion sind Rückenschmerzen, die bis in die Arme oder Beine ausstrahlen können. Dies wird als
            radikuläres Schmerzsyndrom, Brachialgie oder Ischialgie bezeichnet. Die betroffene Person kann auch Taubheitsgefühle, Kribbeln oder
            Muskelschwäche in den betroffenen Bereichen verspüren. In schweren Fällen kann eine Bandscheibenprotrusion zu einer Kompression der
            Nervenwurzeln oder des Rückenmarks führen, was zu ernsthaften neurologischen Symptomen wie Lähmungen oder Blasen- und Mastdarmstörungen
            führen kann.
        </Text>
        <Text>
            Die Diagnose einer Bandscheibenprotrusion erfolgt in der Regel durch eine Kombination aus körperlicher Untersuchung, Bildgebungstechniken
            wie Magnetresonanztomographie (MRT) oder Computertomographie (CT) sowie einer Bewertung der Symptome des Patienten. Die Behandlung kann je
            nach Schweregrad der Protrusion variieren. In den meisten Fällen werden zunächst konservative Maßnahmen wie Schmerzmedikation,
            Physiotherapie, Stoßwellentherapie, Rückengymnastik und die Anpassung der Lebensgewohnheiten empfohlen. Darüberhinaus können
            Infiltrationen (Spritzen) helfen die Schmerzen zu lindern und die lokale Entzündung und Schwellung des Nervens und des umliegenden Gewebes
            zu reduzieren. Auch Infusionen, Akupunktur, oder Magnetfeldtherapie können helfen.
        </Text>
        <Text>
            Wenn konservative Maßnahmen nicht ausreichen oder die Symptome fortschreiten, kann eine chirurgische Behandlung erforderlich sein.
            Glücklicherweise ist in den meisten Fällen keine Operation erforderlich, da mit den konservativen Maßnahmen eine ausreichende
            Beschwerdebesserung erreicht wird. Die beste Vorgehensweise sollten Sie ausführlich mit dem Orthopäden Ihres Vertrauens erörtern.
        </Text>
    </Stack>
);
