import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const WirbelblockadeItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Eine Wirbelblockade ist eine Bewegungseinschränkung oder Blockade eines oder mehrerer Wirbelkörper. Diese kann zu Schmerzen und
            Funktionsstörungen führen. Eine Wirbelblockade kann verschiedene Ursachen haben:
        </Text>
        <OrderedList>
            <ListItem>
                Muskuläre Dysbalancen und Muskelverspannungen: Muskuläre Dysbalancen und verspannte und verkürzte Muskeln können zu einer
                Einschränkung der Beweglichkeit der Wirbelkörper und zu Schmerzen führen.
            </ListItem>
            <ListItem>
                Fehlhaltungen: Eine schlechte Körperhaltung oder ungünstige Bewegungsmuster können dazu führen, dass die Wirbelkörper aus ihrer
                normalen Position geraten und blockiert werden.
            </ListItem>
            <ListItem>
                Bandscheibenvorfall: Ein Bandscheibenvorfall kann dazu führen, dass eine Bandscheibe auf Nervenwurzeln drückt und die Beweglichkeit
                der Wirbelkörper beeinträchtigt.
            </ListItem>
            <ListItem>
                Arthrose / Spondylarthrose: Bei degenerativen Gelenkerkrankungen wie Arthrose können die Gelenkflächen verschleißen und die
                Beweglichkeit der Wirbelkörper einschränken.
            </ListItem>
            <ListItem>Verletzungen: Verletzungen wie Stürze, Unfälle oder Sportverletzungen können zu Wirbelblockaden führen.</ListItem>
        </OrderedList>
        <Text>
            Symptome einer Wirbelblockade können Schmerzen (ggf. mit Ausstrahlung in andere Körperregionen), eine Steifheit, eine
            Bewegungseinschränkung und Muskelverspannungen sein.
        </Text>
        <Text>Die Behandlung einer Wirbelblockade kann je nach Ursache und Schweregrad verschiedene Ansätze beinhalten:</Text>
        <OrderedList>
            <ListItem>
                Manuelle Therapie / Chirotherapie: Ein Facharzt für Orthopädie mit Zusatzbezeichnung (Zusatzausbildung) Manuelle Medizin /
                Chirotherapie kann durch manuelle Techniken wie Mobilisation oder Manipulation der Wirbelsäule die Blockaden lösen und die normale
                Beweglichkeit wiederherstellen.
            </ListItem>
            <ListItem>
                Medikamentöse Therapie: Schmerzmittel, Muskelrelaxantien oder entzündungshemmende Medikamente können begleitend eingesetzt werden.
            </ListItem>
            <ListItem>
                Injektionen: Lokale Injektionen von Lokalanästhetikum, Kortikosteroiden oder pflanzlichen Substanzen in den betroffenen Bereich können
                sinnvoll sein.
            </ListItem>
            <ListItem>
                Biofeedback-Therapie: Die Biofeedback-Therapie kann z. B. bei Blockaden durch Verspannungen und muskuläre Dysbalancen eingesetzt
                werden. Sie ist ein äußerst schonendes und nachhaltiges Verfahren.
            </ListItem>
            <ListItem>
                Physiotherapie: Übungen und Techniken zur Verbesserung der Beweglichkeit der Wirbel und zur Stärkung der umgebenden Muskulatur können
                hier demonstriert werden.
            </ListItem>
            <ListItem>
                Wärmeanwendungen und Entspannungstechniken: Die Anwendung von Wärme auf die betroffene Stelle und Entspannungstechniken wie Massage
                oder Dehnungsübungen können zur Linderung von Muskelverspannungen beitragen.
            </ListItem>
        </OrderedList>
        <Text>
            Falls Sie Symptome einer Wirbelblockade haben, ist ein zeitnaher Besuch bei dem Facharzt für Orthopädie Ihres Vertrauens, der die
            Zusatzbezeichnung Manuelle Medizin / Chirotherapie besitzen sollte, empfehlenswert, um die Diagnose bestätigen und andere Diagnosen
            ausschließen zu lassen. Oft können Blockaden unkompliziert manuell gelöst werden und es entsteht häufig eine unmittelbar anschließende
            Symptomlinderung. Spätestens wenn ein Blockadegefühl immer wieder auftritt, insbesondere an der gleichen Stelle, sollte eine orthopädische
            Abklärung erfolgen, da in diesen Fällen häufig Fehlhaltungen, unphysiologische Bewegungsmuster oder muskuläre Dysbalancen zu Grunde
            liegen.
        </Text>
        <Text>Bei Fragen beraten wir Sie selbstverständlich gerne im persönlichen Gespräch.</Text>
    </Stack>
);
