import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const IschialgieItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Mit einer Ischialgie, umgangssprachlich auch als „Ischias“ bekannt, werden Schmerzen bezeichnet, die entlang des Ischiasnervs verlaufen.
            Der Ischiasnerv ist der längste Nerv des Körpers und erstreckt sich von der Lendenwirbelsäule bis hinunter zum Fuß. Eine Ischialgie tritt
            auf, wenn der Ischiasnerv gereizt oder komprimiert wird.
        </Text>
        <Text>
            Die häufigste Ursache für eine Ischialgie ist ein Bandscheibenvorfall in der Lendenwirbelsäule. Ein Bandscheibenvorfall tritt auf, wenn
            der innere Kern einer Bandscheibe durch den äußeren Ring austritt und auf Fasern des Ischiasnervs drückt. Dies kann zu Schmerzen,
            Taubheits-, Kribbelgefühlen und einer Muskelschwäche im Versorgungsgebiet des Nervs führen.
        </Text>
        <Text>Weitere mögliche Ursachen für eine Ischialgie sind:</Text>
        <OrderedList>
            <ListItem>
                Spinalkanalstenose: Eine Verengung des Wirbelkanals, durch den die Fasern des Ischiasnervs verlaufen, kann zu einer Nervenkompression
                mit Ischialgie führen.
            </ListItem>
            <ListItem>
                Piriformis-Syndrom: Der Piriformis-Muskel, der sich tief im Gesäß befindet, kann den Ischiasnerv reizen oder komprimieren und
                Schmerzen verursachen.
            </ListItem>
            <ListItem>
                Degenerative Veränderungen: Verschleißerscheinungen in der Wirbelsäule, wie Arthrose oder eine Wirbelkanalverengung, können zu einer
                Ischialgie führen.
            </ListItem>
            <ListItem>
                Verletzungen oder Traumata: Rückenverletzungen, wie beispielsweise ein Bandscheibenvorfall nach einem Unfall, können eine Ischialgie
                hervorrufen.
            </ListItem>
            <ListItem>
                Entzündungen oder Infektionen: Entzündliche Erkrankungen wie z. B. eine rheumatoide Arthritis oder eine Infektion im Bereich der
                Wirbelsäule können zu einer Ischialgie führen.
            </ListItem>
        </OrderedList>
        <Text>Die Symptome der Ischialgie können variieren, aber typischerweise tritt Folgendes auf:</Text>
        <OrderedList>
            <ListItem>
                Schmerzen, die entlang des Ischiasnervs von der Lendenwirbelsäule bis zum Gesäß, Oberschenkel, Unterschenkel und Fuß ausstrahlen
                können.
            </ListItem>
            <ListItem>Taubheits-, Kribbelgefühle oder ein "Ameisenlaufen" in den betroffenen Bereichen.</ListItem>
            <ListItem>Muskelschwäche oder Lähmungserscheinungen im Bein oder Fuß.</ListItem>
            <ListItem>Schmerzen beim Gehen, Stehen, Sitzen oder bei bestimmten Bewegungen.</ListItem>
            <ListItem>Verschlimmerung der Symptome durch Husten, Niesen oder langes Sitzen.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung der Ischialgie zielt darauf ab, die zu Grunde liegende Ursache zu behandeln und die Schmerzen zu lindern. Die
            Behandlungsmöglichkeiten können Folgendes umfassen:
        </Text>
        <OrderedList>
            <ListItem>
                Ruhe und Schonung: Aktivitäten, die die Symptome verschlimmern können, sollten vermieden werden. Auf ausreichend Schonung / Ruhe
                sollte geachtet werden.
            </ListItem>
            <ListItem>
                Schmerzlinderung: Die Anwendung von Wärme- oder Kälteanwendungen sowie die Einnahme von schmerz- und entzündungshemmenden Medikamenten
                können zur Schmerzlinderung beitragen.
            </ListItem>
            <ListItem>
                Physiotherapie: Spezielle Übungen und Physiotherapie können helfen, die Muskulatur zu stärken, die Beweglichkeit zu verbessern und die
                Symptome zu lindern.
            </ListItem>
            <ListItem>
                Injektionen: In einigen Fällen können Injektionen von entzündungshemmenden Medikamenten oder Kortikosteroiden in den betroffenen
                Bereich zur Schmerzlinderung verabreicht werden. Auch eine Eigenbluttherapie kann sinnvoll sein.
            </ListItem>
            <ListItem>Akupunktur zur Schmerz- und Entzündungshemmung.</ListItem>
            <ListItem>
                Stoßwellentherapie zur Schmerzlinderung, Verbesserung der Durchblutung, Stimulation von Stoffwechselprozessen und Förderung der
                Selbstheilung.
            </ListItem>
            <ListItem>Magnetfeldtherapie zur Schmerzreduktion und Unterstützung der Selbstheilungsprozesse.</ListItem>
            <ListItem>
                Operation: Wenn konservative Maßnahmen nicht ausreichend erfolgreich sind oder in schwerwiegenden Einzelfällen kann eine Operation in
                Erwägung gezogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Falls Sie eine Ischialgie vermuten, sollten Sie den Facharzt für Orthopädie Ihres Vertrauens aufsuchen, um eine genaue Diagnose und die am
            besten geeignete Behandlung zu erhalten. Jeder Fall von Ischialgie ist unterschiedlich, und die Behandlung sollte individuell auf den
            Patienten abgestimmt werden. Ganzheitliche Therapiekonzepte können hier besonders helfen, eine umfassende und dauerhafte Genesung zu
            erreichen.
        </Text>
    </Stack>
);
