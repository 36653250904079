import * as React from 'react';
import { Box, Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import StossImage from 'images/leistungen/Stosswelle.jpg';

export const StosswellenItem: React.FC = (): JSX.Element => {
    return (
        <Stack textAlign={'justify'}>
            <Text>
                Die Stoßwellentherapie, auch extrakorporale Stoßwellentherapie (ESWT) genannt, ist eine nicht-invasive Behandlungsmethode, bei der
                Wellen mit hoher Energie gezielt auf krankhaft verändertes Gewebe übertragen werden. Diese hochenergetischen Schallwellen durchdringen
                das Gewebe und haben eine regenerative Wirkung, indem sie den Heilungsprozess anregen und Schmerzen reduzieren.
            </Text>
            <Text>
                Die Stoßwellentherapie wird in verschiedenen medizinischen Bereichen eingesetzt, darunter Orthopädie und Sportmedizin. Sie kann zur
                Behandlung einer Vielzahl von Erkrankungen und Verletzungen eingesetzt werden:
            </Text>
            <OrderedList>
                <ListItem>
                    Fersensporn: Die Stoßwellentherapie kann zur Schmerzlinderung und zur Förderung der Heilung bei Fersensporn eingesetzt werden,
                    einer schmerzhaften Knochenwucherung am Fersenbein.
                </ListItem>
                <ListItem>
                    Tennisellenbogen (Epicondylitis): Bei der Behandlung des Tennisellenbogens können Stoßwellen die Entzündung reduzieren und die
                    Heilung der Sehnen stimulieren.
                </ListItem>
                <ListItem>
                    Plantarfasziitis: Stoßwellen können bei der Behandlung von Plantarfasziitis eingesetzt werden, einer Entzündung der Plantarfaszie,
                    die Schmerzen in der Ferse verursacht.
                </ListItem>
                <ListItem>
                    Kalkschulter (Tendinosis calcarea): Bei der Kalkschulter bilden sich Kalkablagerungen in der Schultersehne. Stoßwellen können zur
                    Zertrümmerung der Kalkablagerungen und zur Verbesserung der Symptome eingesetzt werden.
                </ListItem>
                <ListItem>
                    Sehnenentzündungen: Stoßwellen können bei verschiedenen Sehnenentzündungen, wie beispielsweise Achillessehnenentzündung oder
                    Patellarsehnenentzündung, zur Schmerzlinderung und Förderung der Heilung eingesetzt werden.
                </ListItem>
            </OrderedList>
            <Box>
                <Image
                    src={StossImage}
                    float={'right'}
                    w={{ base: '100%', md: 520 }}
                    ml={4}
                    boxShadow={'md'}
                    mb={{ base: 2, md: 0 }}
                    alt='Stosswellen'
                />
                <Text mb={2}>
                    Die Durchführung der Stoßwellentherapie erfolgt in der Regel in einer Praxis durch einen spezialisierten Arzt oder Behandler. Die
                    Schallwellen werden mit einem Gerät erzeugt und über einen Applikator gezielt auf das betroffene Gewebe übertragen. Die Anzahl und
                    Intensität der Behandlungen hängen von der Art und Schwere der Erkrankung ab.
                </Text>
                <Text>
                    Die Stoßwellentherapie ist in der Regel gut verträglich, kann jedoch während der Behandlung zu leichten Beschwerden oder Schmerzen
                    führen. Mögliche Nebenwirkungen sind Rötungen, Blutergüsse oder vorübergehende Schmerzen im Behandlungsgebiet.
                </Text>
            </Box>
            <Text>
                Um festzustellen, ob die Stoßwellentherapie für Sie geeignet ist, sollten Sie einen Facharzt für Orthopädie konsultieren. Dieser kann
                Ihre Symptome und medizinische Vorgeschichte beurteilen und Ihnen weitere Informationen zu dieser Therapiemöglichkeit geben.
            </Text>
        </Stack>
    );
};
