import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const ZehenfrakturItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>Eine Zehenfraktur ist ein Bruch eines Zehenknochens. Mögliche Ursachen sind vielfältig:</Text>
        <OrderedList>
            <ListItem>Trauma: Ein direkter Schlag, eine Verdrehung (Distorsion) oder ein Sturz auf einen Zeh kann zu einer Fraktur führen.</ListItem>
            <ListItem>
                Sportverletzungen: Sportarten mit schnellen Richtungswechseln oder Sprüngen oder Kontaktsportarten durch direkte Anpralltraumata
                können das Risiko von Zehenfrakturen erhöhen. Beispiele sind hier Fußball, Basketball und Kampfsportarten.
            </ListItem>
            <ListItem>
                Überlastung: Wiederholte (z. B. Arbeiten mit einem Presslufthammer) oder sehr lang anhaltende Belastungen (z. B. Marathonlauf) können
                über Mikrotraumata in der Knochenstruktur zu einer Ermüdungsfraktur (Ermüdungsbruch) führen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Symptome einer Zehenfraktur können Schmerzen, Schwellungen, Blutergüsse, Verformungen oder Bewegungseinschränkungen des betroffenen
            Zehs sein. Bei den selteneren offenen Frakturen kommt es auch zu offenen Weichteilverletzungen (Wunden). Auch Durchblutungsstörungen sind
            möglich bei Weichteilverletzungen oder ausgedehnteren Blutergüssen der Weichteile.
        </Text>
        <Text>
            Die Behandlung einer Zehenfraktur kann oft konservativ (nicht-operativ) erfolgen, insbesondere bei stabilen und unverschobenen Brüchen:
        </Text>
        <OrderedList>
            <ListItem>
                Ruhigstellung: Der betroffene Zeh wird durch eine Schiene (Orthese), einen Verband oder einen speziellen Schuh ruhiggestellt, um die
                Belastung zu reduzieren und damit die Heilung zu unterstützen.
            </ListItem>
            <ListItem>
                Teilbelastung / Entlastung: Gegebenenfalls kann eine zeitlich begrenzte Teilbelastung oder Entlastung zur Förderung der Heilung
                sinnvoll sein.
            </ListItem>
            <ListItem>Schmerzmanagement: Schmerzmittel können zur Linderung von Schmerzen und Entzündungen eingesetzt werden.</ListItem>
            <ListItem>
                Kühlung: Die Anwendung von Kältepackungen an der betroffenen Stelle kann dazu beitragen, Weichteilschwellungen zu reduzieren.
            </ListItem>
            <ListItem>
                Einlagenversorgung: Nach Durchführung einer individuellen Ganganalyse durch den Facharzt für Orthopädie können spezielle orthopädische
                Einlagen angefertigt werden, um den betroffenen Zeh zu entlasten und den lokalen Druck beim Gehen zu reduzieren.
            </ListItem>
        </OrderedList>
        <Text>
            Bei verschobenen oder instabilen Brüchen, ausgedehnten, insbesondere offenen Weichteilverletzungen oder Komplikationen nach zunächst
            konservativer Behandlung (z. B. späteres Verschieben der gebrochenen Knochenanteile), kann eine Operation notwendig sein.
        </Text>
        <Text>
            Es ist wichtig schnellstmöglich den Facharzt für Orthopädie und Unfallchirurgie Ihres Vertrauens aufzusuchen, falls Sie eine Zehenfraktur
            vermuten, damit die genaue Diagnose gestellt und die für Sie optimale Behandlung eingeleitet werden kann. So kann in den meisten Fällen
            eine vollständige Genesung erreicht werden.
        </Text>
    </Stack>
);
