import { Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const ErmuedungsbruchItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Ermüdungsbruch, auch Stressfraktur genannt, ist eine Verletzung des Knochens, die durch wiederholte Überlastung und Mikrotraumata der
            Knochenstruktur entsteht. Im Gegensatz zu akuten Frakturen, die durch eine plötzliche Verletzung entstehen, entwickeln sich
            Ermüdungsbrüche in den meisten Fällen über einen längeren Zeitraum. Sie treten häufig bei Sportlern und Menschen auf, die repetitive
            Aktivitäten oder lang anhaltende Belastungen ausüben.
        </Text>
        <Text>
            Am häufigsten treten Ermüdungsbrüche am Schienbein, Fuß, Oberschenkelknochen, Wirbelkörper oder Beckenknochen auf. Die Belastung führt zu
            Mikrotraumata im Knochen, die den normalen Knochenumbau stören und zu einer Schwächung des Knochens führen.
        </Text>
        <Text>
            Die Symptome eines Ermüdungsbruchs können Schmerzen, lokale Druckschmerzen, Schwellungen oder auch ein Bluterguss im betroffenen Bereich
            sein. Die Schmerzen nehmen in der Regel während körperlicher Aktivität zu und können sich in Ruhe verbessern. In einigen Fällen kann ein
            Ermüdungsbruch jedoch schwer zu diagnostizieren sein, da die Symptome zunächst mild sein können und oft erst im Laufe der Zeit zunehmen.
        </Text>
        <Text>
            Die Diagnose eines Ermüdungsbruchs erfordert in der Regel eine gründliche Anamnese, körperliche Untersuchung und bildgebende Verfahren wie
            Röntgenaufnahmen, eine Magnetresonanztomographie (MRT) oder eine Knochenszintigraphie. Diese Untersuchungen können helfen, den Bruch zu
            erkennen und andere mögliche Ursachen der Symptome auszuschließen.
        </Text>
        <Text>
            Die Behandlung von Ermüdungsbrüchen besteht in der Regel aus Maßnahmen, die die Belastung des betroffenen Bereichs reduzieren und die
            Heilung fördern. Dazu gehören Ruhe, die Einschränkung oder Anpassung der Aktivitäten, Physiotherapie zur Stärkung der umgebenden
            Muskulatur, die Verwendung von Schuh- oder Beinorthesen zur Unterstützung und Schmerzlinderung sowie ggf. die Anpassung der Trainings-
            oder Bewegungstechniken, um die Belastung auf den Knochen dauerhaft zu verringern. Auch supportive Maßnahmen wie z. B. die
            Magnetfeldtherapie oder die Stoßwellentherapie können sinnvoll sein.
        </Text>
        <Text>
            Ein Ermüdungsbruch kann eine längere Genesungszeit erfordern und in Einzelfällen kann eine operative Intervention erforderlich werden.
        </Text>
        <Text>
            Falls Sie Symptome eines Ermüdungsbruchs feststellen, sollten Sie diese bei dem Facharzt für Orthopädie und Unfallchirurgie Ihres
            Vertrauens umgehend abklären lassen, um eine genaue Diagnose und die optimale Behandlung zu erhalten. Die frühzeitige Erkennung und
            Behandlung eines Ermüdungsbruchs kann Komplikationen vermeiden und eine schnellere Genesung ermöglichen.
        </Text>
    </Stack>
);
