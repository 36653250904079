import { Heading, Link as ChakraLink, Stack, Text } from '@chakra-ui/react';
import { EmailLink } from 'components/links';
import { PhoneLink } from 'components/links/Phone';
import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

export const Impressum: React.FC = () => {
    return (
        <>
            <Heading color='primary.500' size={{ base: 'xl', md: '2xl' }} mb={{ base: 2, md: 4 }}>
                Impressum
            </Heading>
            <Stack>
                <Heading color={'primary.500'} size={{ base: 'md', md: 'lg' }}>
                    Angaben gemäß § 5 TMG
                </Heading>
                <Text fontSize={{ base: 'sm', md: 'md' }}>
                    <Text>Dr. med. Jan Festring</Text>
                    <Text>Praxis für Orthopädie & Sportmedizin</Text>
                    <Text>Uerdinger Straße 74</Text>
                    <Text>40668 Meerbusch</Text>
                </Text>
                <Heading color={'primary.500'} size={{ base: 'md', md: 'lg' }}>
                    Kontakt
                </Heading>
                <Text fontSize={{ base: 'sm', md: 'md' }}>
                    <Text>
                        Telefon: <PhoneLink _hover={{ textDecoration: 'none' }} />
                    </Text>
                    <Text>
                        E-Mail: <EmailLink color='inherit' _hover={{ textDecoration: 'none' }} />
                    </Text>
                </Text>
                <Heading color={'primary.500'} size={{ base: 'md', md: 'lg' }}>
                    Berufsbezeichnung und berufsrechtliche Regelungen
                </Heading>
                <Text fontSize={{ base: 'sm', md: 'md' }}>
                    <Text as='b'>Berufsbezeichnung:</Text>
                    <Text>Facharzt für Orthopädie und Unfallchirurgie</Text>
                </Text>
                <Text fontSize={{ base: 'sm', md: 'md' }}>
                    <Text as='b'>Zuständige Kammer:</Text>
                    <Text>Ärztekammer Nordrhein</Text>
                    <Text>Tersteegenstraße 9</Text>
                    <Text>40474 Düsseldorf</Text>
                </Text>
                <Text fontSize={{ base: 'sm', md: 'md' }}>
                    <Text as='b'>Verliehen in:</Text>
                    <Text>Deutschland</Text>
                </Text>
                <Text fontSize={{ base: 'sm', md: 'md' }}>
                    <Text as='i'>Es gelten folgende berufsrechtliche Regelungen:</Text>
                    <Text>Berufsordnung der Ärztekammer Nordrhein Heilberufsgesetz des Landes NRW</Text>
                    <Text>
                        einsehbar unter:{' '}
                        <ChakraLink as={ReactRouterLink} to='https://www.aekno.de/aerzte/berufsordnung' color='primary.500' target='_blank'>
                            https://www.aekno.de/aerzte/berufsordnung
                        </ChakraLink>
                    </Text>
                </Text>
                <Heading color={'primary.500'} size={{ base: 'md', md: 'lg' }}>
                    Angaben zur Berufshaftpflichtversicherung
                </Heading>
                <Text fontSize={{ base: 'sm', md: 'md' }}>
                    <Text as='b'>Name und Sitz des Versicherers:</Text>
                    <Text>Versicherungskammer Bayern</Text>
                    <Text>Maximilianstraße 53</Text>
                    <Text>80530 München</Text>
                </Text>
                <Text fontSize={{ base: 'sm', md: 'md' }}>
                    <Text as='b'>Geltungsraum der Versicherung:</Text>
                    <Text>Deutschland</Text>
                </Text>
                <Heading color={'primary.500'} size={{ base: 'md', md: 'lg' }}>
                    Redaktionell verantwortlich
                </Heading>
                <Text fontSize={{ base: 'sm', md: 'md' }}>
                    <Text>Dr. med. Jan Festring</Text>
                    <Text>Uerdinger Straße 74</Text>
                    <Text>40668 Meerbusch</Text>
                </Text>
                <Heading color={'primary.500'} size={{ base: 'md', md: 'lg' }}>
                    Bildnachweis
                </Heading>
                <Text fontSize={{ base: 'sm', md: 'md' }}>Privat</Text>
                <Heading color={'primary.500'} size={{ base: 'md', md: 'lg' }}>
                    EU-Streitschlichtung
                </Heading>
                <Text fontSize={{ base: 'sm', md: 'md' }}>
                    <Text>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:</Text>
                    <ChakraLink as={ReactRouterLink} to='https://ec.europa.eu/consumers/odr/' color='primary.500' target='_blank'>
                        https://ec.europa.eu/consumers/odr/
                    </ChakraLink>
                    .<Text>Unsere E-Mail-Adresse finden Sie oben im Impressum.</Text>
                </Text>
                <Heading color={'primary.500'} size={{ base: 'md', md: 'lg' }}>
                    Verbraucherstreitbeilegung/Universalschlichtungsstelle
                </Heading>
                <Text fontSize={{ base: 'sm', md: 'md' }}>
                    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                </Text>
            </Stack>
        </>
    );
};
