import { Box, Heading, ListItem, Stack, Text, UnorderedList, useColorModeValue } from '@chakra-ui/react';
import { EmailLink } from 'components/links';
import { PhoneLink } from 'components/links/Phone';
import * as React from 'react';

interface IProps {
    children: React.ReactNode;
}

const Heading1: React.FC<IProps> = (props) => (
    <Heading size={{ base: 'lg', md: 'xl' }} color={useColorModeValue('primary.500', 'primary.200')}>
        {props.children}
    </Heading>
);

const Heading2: React.FC<IProps> = (props) => (
    <Heading size={{ base: 'md', md: 'lg' }} color={useColorModeValue('primary.500', 'primary.200')}>
        {props.children}
    </Heading>
);

const Heading3: React.FC<IProps> = (props) => (
    <Text as='b' fontSize={{ base: 'sm', md: 'md' }}>
        {props.children}
    </Text>
);

const Content: React.FC<IProps> = (props) => <Text fontSize={{ base: 'sm', md: 'md' }}>{props.children}</Text>;

export const Datenschutz: React.FC = () => {
    const textColor = useColorModeValue('primary.500', 'primary.200');

    return (
        <Stack textAlign={'justify'} spacing={{ base: 3, md: 6 }}>
            <Heading size={{ base: 'xl', md: '2xl' }} color={textColor}>
                Datenschutz
            </Heading>
            <Heading1>1. Datenschutz auf einen Blick</Heading1>
            <Heading2>Allgemeine Hinweise</Heading2>
            <Content>
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
                besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum
                Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
            </Content>
            <Heading size={{ base: 'md', md: 'lg' }} color={textColor}>
                Datenerfassung auf dieser Website
            </Heading>
            <Heading3>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</Heading3>
            <Content>
                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur
                Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
            </Content>
            <Heading3>Wie erfassen wir Ihre Daten?</Heading3>
            <Content>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
                Kontaktformular eingeben.
            </Content>
            <Content>
                Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
                automatisch, sobald Sie diese Website betreten.
            </Content>
            <Heading3>Wofür nutzen wir Ihre Daten?</Heading3>
            <Content>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse
                Ihres Nutzerverhaltens verwendet werden.
            </Content>
            <Heading3>Welche Rechte haben Sie bezüglich Ihrer Daten?</Heading3>
            <Content>
                Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten
                zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
                Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
                unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
                Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
            </Content>
            <Content>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</Content>
            <Heading2>Analyse-Tools und Tools von Dritt­anbietern</Heading2>
            <Content>
                Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten
                Analyseprogrammen.
            </Content>
            <Content>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.</Content>
            <Heading1>2. Hosting</Heading1>
            <Content>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</Content>
            <Heading2>Netcup</Heading2>
            <Content>
                Anbieter ist die Netcup GmbH, Daimlerstraße 25, 76185 Karlsruhe (nachfolgend „Netcup“). Wenn Sie unsere Website besuchen, erfasst
                Netcup verschiedene Logfiles inklusive Ihrer IP-Adressen.
            </Content>
            <Content>
                Weitere Informationen können Sie der Datenschutzerklärung von Netcup entnehmen:
                https://www.netcup.de/kontakt/datenschutzerklaerung.php.
            </Content>
            <Content>
                Die Verwendung von Netcup erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
                möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
                ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
                oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
                ist jederzeit widerrufbar.
            </Content>
            <Heading3>Auftragsverarbeitung</Heading3>
            <Content>
                Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich
                um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer
                Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
            </Content>
            <Heading1>3. Allgemeine Hinweise und Pflicht­informationen</Heading1>
            <Heading2>Datenschutz</Heading2>
            <Content>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
                vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            </Content>
            <Content>
                Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
                persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie
                nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
            </Content>
            <Content>
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
                kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
            </Content>
            <Heading2>Hinweis zur verantwortlichen Stelle</Heading2>
            <Content>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</Content>
            <Box>
                <Content>Dr. med. Jan Festring</Content>
                <Content>Praxis für Orthopädie & Sportmedizin</Content>
                <Content>Uerdinger Straße 74</Content>
                <Content>40668 Meerbusch</Content>
            </Box>
            <Box>
                <Content>
                    Telefon: <PhoneLink _hover={{ textDecoration: 'none' }} />
                </Content>
                <Content>
                    E-Mail: <EmailLink color='inherit' _hover={{ textDecoration: 'none' }} />
                </Content>
            </Box>
            <Content>
                Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
                der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
            </Content>
            <Heading2>Speicherdauer</Heading2>
            <Content>
                Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
                bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine
                Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für
                die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten
                Fall erfolgt die Löschung nach Fortfall dieser Gründe.
            </Content>
            <Heading2>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</Heading2>
            <Content>
                Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1
                lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle
                einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem
                auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr
                Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1
                TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher
                Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre
                Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die
                Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils
                im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.
            </Content>
            <Heading2>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</Heading2>
            <Content>
                Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten.
                Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir
                weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind
                US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen
                gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern
                befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten
                keinen Einfluss.
            </Content>
            <Heading2>Widerruf Ihrer Einwilligung zur Datenverarbeitung</Heading2>
            <Content>
                Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
                jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
            </Content>
            <Heading2>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</Heading2>
            <Content>
                WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
                DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES
                GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
                ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
                MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
                UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH
                NACH ART. 21 ABS. 1 DSGVO).
            </Content>
            <Content>
                WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN
                DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
                SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
                NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
            </Content>
            <Heading2>Beschwerde­recht bei der zuständigen Aufsichts­behörde</Heading2>
            <Content>
                Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
                Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
                besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
            </Content>
            <Heading2>Recht auf Daten­übertrag­barkeit</Heading2>
            <Content>
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an
                sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der
                Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </Content>
            <Heading2>Auskunft, Löschung und Berichtigung</Heading2>
            <Content>
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
                personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
                Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
            </Content>
            <Heading2>Recht auf Einschränkung der Verarbeitung</Heading2>
            <Content>
                Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
                an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
            </Content>
            <UnorderedList fontSize={{ base: 'sm', md: 'md' }} spacing={{ base: 1, md: 2 }} ml={{ base: 8, md: 10 }}>
                <ListItem>
                    Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies
                    zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                    verlangen.
                </ListItem>
                <ListItem>
                    Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die
                    Einschränkung der Datenverarbeitung verlangen.
                </ListItem>
                <ListItem>
                    Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
                    Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen
                    Daten zu verlangen.
                </ListItem>
                <ListItem>
                    Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen
                    vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der
                    Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                </ListItem>
            </UnorderedList>
            <Content>
                Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten - von ihrer Speicherung abgesehen -
                nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
                anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
                eines Mitgliedstaats verarbeitet werden.
            </Content>
            <Heading2>SSL- bzw. TLS-Verschlüsselung</Heading2>
            <Content>
                Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
                Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
                daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            </Content>
            <Content>
                Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                werden.
            </Content>
            <Heading2>Widerspruch gegen Werbe-E-Mails</Heading2>
            <Content>
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
                Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
                Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
            </Content>
            <Heading1>4. Datenerfassung auf dieser Website</Heading1>
            <Heading2>Cookies</Heading2>
            <Content>
                Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden
                an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
                Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
                Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
            </Content>
            <Content>
                Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-Cookies). Third-Party-Cookies
                ermöglichen die Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z. B. Cookies zur Abwicklung von
                Zahlungsdienstleistungen).
            </Content>
            <Content>
                Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht
                funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies können zur Auswertung des
                Nutzerverhaltens oder zu Werbezwecken verwendet werden.
            </Content>
            <Content>
                Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erwünschter
                Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies zur Messung des Webpublikums)
                erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
                Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur
                technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und
                vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung
                (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.
            </Content>
            <Content>
                Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben,
                die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des
                Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </Content>
            <Content>Welche Cookies und Dienste auf dieser Website eingesetzt werden, können Sie dieser Datenschutzerklärung entnehmen.</Content>
            <Heading2>Server-Log-Dateien</Heading2>
            <Content>
                Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch
                an uns übermittelt. Dies sind:
            </Content>
            <UnorderedList fontSize={{ base: 'sm', md: 'md' }} ml={{ base: 8, md: 10 }}>
                <ListItem>Browsertyp und Browserversion</ListItem>
                <ListItem>verwendetes Betriebssystem</ListItem>
                <ListItem>Referrer URL</ListItem>
                <ListItem>Hostname des zugreifenden Rechners</ListItem>
                <ListItem>Uhrzeit der Serveranfrage</ListItem>
                <ListItem>IP-Adresse</ListItem>
            </UnorderedList>
            <Content>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</Content>
            <Content>
                Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
                an der technisch fehlerfreien Darstellung und der Optimierung seiner Website - hierzu müssen die Server-Log-Files erfasst werden.
            </Content>
            <Heading2>Kontaktformular</Heading2>
            <Content>
                Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
                angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
                wir nicht ohne Ihre Einwilligung weiter.
            </Content>
            <Content>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
                Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
                Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
                widerrufbar.
            </Content>
            <Content>
                Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
                Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
                Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.
            </Content>
            <Heading2>Anfrage per E-Mail, Telefon oder Telefax</Heading2>
            <Content>
                Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
                Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
                Ihre Einwilligung weiter.
            </Content>
            <Content>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
                Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
                Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
                widerrufbar.
            </Content>
            <Content>
                Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung
                zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
                Zwingende gesetzliche Bestimmungen - insbesondere gesetzliche Aufbewahrungsfristen - bleiben unberührt.
            </Content>
            <Heading2>Doctolib</Heading2>
            <Content>
                Auf unserer Website haben Sie die Möglichkeit, Termine mit uns zu vereinbaren. Für die Terminbuchung nutzen wir das Doctolib. Anbieter
                ist die Doctolib GmbH, Mehringdamm 51, 10961 Berlin (nachfolgend „Doctolib“).
            </Content>
            <Content>
                Zum Zweck der Terminbuchung geben Sie die abgefragten Daten und den Wunschtermin in die dafür vorgesehene Maske ein. Die eingegebenen
                Daten werden für die Planung, Durchführung und ggf. für die Nachbereitung des Termins verwendet. Die Termindaten werden für uns auf
                den Servern von Doctolib gespeichert, dessen Datenschutzerklärung Sie hier einsehen können:
                https://media.doctolib.com/image/upload/v1657195738/legal/B2C-PrivacyPolicy-July-22-DE.pdf.
            </Content>
            <Content>
                Die von Ihnen eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen
                oder der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben
                unberührt.
            </Content>
            <Content>
                Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer
                möglichst unkomplizierten Terminvereinbarung mit Interessenten und Kunden. Sofern eine entsprechende Einwilligung abgefragt wurde,
                erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO / Art. 9 Abs. 2. lit. a DSGVO und § 25 Abs. 1
                TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
                Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
            </Content>
            <Heading2>Facebook</Heading2>
            <Content>
                Auf dieser Website sind Elemente des sozialen Netzwerks Facebook integriert. Anbieter dieses Dienstes ist die Meta Platforms Ireland
                Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und in
                andere Drittländer übertragen.
            </Content>
            <Content>
                Eine Übersicht über die Facebook Social-Media-Elemente finden Sie hier: https://developers.facebook.com/docs/plugins/?locale=de_DE.
            </Content>
            <Content>
                Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem Endgerät und dem Facebook-Server hergestellt.
                Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den Facebook
                „Like-Button“ anklicken, während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte dieser Website auf Ihrem
                Facebook-Profil verlinken. Dadurch kann Facebook den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass
                wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere
                Informationen hierzu finden Sie in der Datenschutzerklärung von Facebook unter: https://de-de.facebook.com/privacy/explanation.
            </Content>
            <Content>
                Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o. g. Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a
                DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine Einwilligung eingeholt wurde, erfolgt die Verwendung
                des Dienstes auf Grundlage unseres berechtigten Interesses an einer möglichst umfassenden Sichtbarkeit in den Sozialen Medien.
            </Content>
            <Content>
                Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an Facebook weitergeleitet
                werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für
                diese Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich dabei ausschließlich auf die
                Erfassung der Daten und deren Weitergabe an Facebook. Die nach der Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht Teil
                der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung über gemeinsame Verarbeitung
                festgehalten. Den Wortlaut der Vereinbarung finden Sie unter: https://www.facebook.com/legal/controller_addendum. Laut dieser
                Vereinbarung sind wir für die Erteilung der Datenschutzinformationen beim Einsatz des Facebook-Tools und für die datenschutzrechtlich
                sichere Implementierung des Tools auf unserer Website verantwortlich. Für die Datensicherheit der Facebook-Produkte ist Facebook
                verantwortlich. Betroffenenrechte (z. B. Auskunftsersuchen) hinsichtlich der bei Facebook verarbeiteten Daten können Sie direkt bei
                Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese an Facebook
                weiterzuleiten.
            </Content>
            <Content>
                Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier:
                https://www.facebook.com/legal/EU_data_transfer_addendum, https://de-de.facebook.com/help/566994660333381 und
                https://www.facebook.com/policy.php.
            </Content>
            <Heading2>Instagram</Heading2>
            <Content>
                Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten durch die Meta Platforms
                Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland.
            </Content>
            <Content>
                Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem Endgerät und dem Instagram-Server hergestellt.
                Instagram erhält dadurch Informationen über den Besuch dieser Website durch Sie.
            </Content>
            <Content>
                Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch Anklicken des Instagram-Buttons die Inhalte dieser Website mit
                Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf
                hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten.
            </Content>
            <Content>
                Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o. g. Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a
                DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine Einwilligung eingeholt wurde, erfolgt die Verwendung
                des Dienstes auf Grundlage unseres berechtigten Interesses an einer möglichst umfassenden Sichtbarkeit in den Sozialen Medien.
            </Content>
            <Content>
                Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an Facebook bzw. Instagram
                weitergeleitet werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
                gemeinsam für diese Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich dabei
                ausschließlich auf die Erfassung der Daten und deren Weitergabe an Facebook bzw. Instagram. Die nach der Weiterleitung erfolgende
                Verarbeitung durch Facebook bzw. Instagram ist nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen
                wurden in einer Vereinbarung über gemeinsame Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter:
                https://www.facebook.com/legal/controller_addendum. Laut dieser Vereinbarung sind wir für die Erteilung der Datenschutzinformationen
                beim Einsatz des Facebook- bzw. Instagram-Tools und für die datenschutzrechtlich sichere Implementierung des Tools auf unserer Website
                verantwortlich. Für die Datensicherheit der Facebook bzw. Instagram-Produkte ist Facebook verantwortlich. Betroffenenrechte (z. B.
                Auskunftsersuchen) hinsichtlich der bei Facebook bzw. Instagram verarbeiteten Daten können Sie direkt bei Facebook geltend machen.
                Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese an Facebook weiterzuleiten.
            </Content>
            <Content>
                Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier:
                https://www.facebook.com/legal/EU_data_transfer_addendum, https://help.instagram.com/519522125107875 und
                https://de-de.facebook.com/help/566994660333381.
            </Content>
            <Content>
                Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram: https://instagram.com/about/legal/privacy/.
            </Content>
            <Heading2>Jameda-Siegel</Heading2>
            <Content>
                Auf unserer Webseite sind Siegel der jameda GmbH, St.-Cajetan-Straße 41, 81669 München eingebunden. Das Jameda-Siegel sieht nicht
                immer gleich aus, sondern die Anzeige ändert sich. Dabei wird der entsprechende Inhalt zwar auf unserer Webseite dargestellt, er wird
                aber in diesem Moment von den jameda-Servern abgerufen. Nur so kann immer der aktuelle Inhalt mit der aktuellen Bewertung gezeigt
                werden. Zu diesem Zweck muss eine Datenverbindung von unserer Webseite zu jameda aufgebaut werden und jameda erhält gewisse technische
                Daten (Datum und Uhrzeit des Besuchs; die Seite, von der die Abfrage erfolgt; die verwendete IP-Adresse, Browsertyp und -version,
                Gerätetyp, Betriebssystem und ähnliche technische Informationen), die nötig sind, damit der Inhalt dargestellt werden kann. Diese
                Daten werden aber nur für die Bereitstellung des Inhalts verwendet und nicht gespeichert oder anderweitig genutzt. Wir verfolgen mit
                der Einbindung den Zweck und das berechtigte Interesse, aktuelle und korrekte Inhalte auf unserer Webseite darzustellen.
                Rechtsgrundlage ist Art 6 Abs. 1 f) DSGVO. Eine Speicherung der genannten Daten durch uns erfolgt aufgrund dieser Einbindung nicht.
                Weitere Informationen zur Datenverarbeitung durch jameda können Sie der Datenschutzerklärung der Seite www.jameda.de entnehmen.
            </Content>
            <Heading2>WhatsApp</Heading2>
            <Content>
                Wenn Sie unsere Seiten mit einem Mobilfunkgerät (z. B. Smartphone) aufrufen, können Sie Beiträge über Whatsapp empfehlen. Mit dem
                Antippen des WhatsApp-Buttons können Sie über WhatsApp eine Nachricht mit dem Link zum Beitrag an Ihre persönlichen WhatsApp-Kontakte
                versenden. Erst, wenn Sie auf den Button tippen, werden der Inhalt Ihrer Nachricht sowie die aufgerufene Seite an WhatsApp
                übermittelt.
            </Content>
            <Content>
                WhatsApp ist ein Dienst von WhatsApp Inc., WhatsApp Inc., 1601 Willow Road, Menlo Park, California 94025. WhatsApp Inc. ist unter dem
                EU-US Privacy Shield zertifiziert, einem Abkommen, das die Einhaltung der in der EU geltenden Datenschutzvorschriften gewährleistet.
                Die Datenschutzerklärung von WhatsApp finden Sie hier: https://www.whatsapp.com/legal/#privacy-policy.
            </Content>
            {/* <Heading1>5. Analyse-Tools und Werbung</Heading1>
            <Heading2>WP Statistics</Heading2>
            <Content>
                Diese Website nutzt das Analysetool WP Statistics, um Besucherzugriffe statistisch auszuwerten. Anbieter ist Veronalabs, Tatari 64,
                10134, Tallinn, Estland (https://veronalabs.com).
            </Content>
            <Content>
                Mit WP Statistics können wir die Nutzung unserer Website analysieren. WP Statistics erfasst dabei u. a. Logdateien (IP-Adresse,
                Referrer, verwendete Browser, Herkunft des Nutzers, verwendete Suchmaschine) und Aktionen, die die Websitebesucher auf der Seite
                getätigt haben (z. B. Klicks und Ansichten).
            </Content>
            <Content>Die mit WP Statistics erfassten Daten werden ausschließlich auf unserem eigenen Server gespeichert.</Content>
            <Content>
                Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an der
                anonymisierten Analyse des Nutzerverhaltens, um sowohl unser Webangebot als auch unsere Werbung zu optimieren. Sofern eine
                entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und §
                25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
                Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
            </Content>
            <Heading3>IP-Anonymisierung</Heading3>
            <Content>
                Wir verwenden WP Statistics mit anonymisierter IP. Ihre IP-Adresse wird dabei gekürzt, sodass diese Ihnen nicht mehr direkt zugeordnet
                werden kann.
            </Content> */}
            {/* <Heading1>6. Plugins und Tools</Heading1>
            <Heading2>iThemes Security</Heading2>
            <Content>
                Wir haben iThemes Security auf dieser Website eingebunden. Anbieter ist die iThemes Media LLC, 1720 South Kelly Avenue Edmond, OK
                73013, USA (nachfolgend „iThemes Security“).
            </Content>
            <Content>
                iThemes Security dient dem Schutz unserer Website vor unerwünschten Zugriffen oder bösartigen Cyberattacken. Zu diesem Zweck erfasst
                iThemes Security u. a. Ihre IP-Adresse, Zeitpunkt und Quelle von Login-Versuchen und Log-Daten (z. B. den verwendeten Browser).
                iThemes Security wird lokal auf unseren Servern installiert.
            </Content>
            <Content>
                iThemes Security übermittelt IP-Adressen von wiederkehrenden Angreifern an eine zentrale Datenbank von iThemes in die USA (Network
                Brute Force Protection), um derartige Angriffe künftig zu unterbinden.
            </Content>
            <Content>
                Die Verwendung von iThemes Security erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an einem möglichst effektiven Schutz seiner Website vor Cyberattacken. Sofern eine entsprechende Einwilligung abgefragt
                wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die
                Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
                Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
            </Content> */}
        </Stack>
    );
};
