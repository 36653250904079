import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const HohlfussItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Der Hohlfuß ist eine Fußdeformität, bei der das Längsgewölbe des Fußes übermäßig stark gewölbt ist. Im Gegensatz hierzu steht der
            Plattfuß, bei dem das Fußgewölbe eine abgeflachte Form aufweist.
        </Text>
        <Text>Die Ursachen für einen Hohlfuß können unterschiedlich sein:</Text>
        <OrderedList>
            <ListItem>Angeborene Faktoren: Einige Menschen haben von Geburt an eine natürliche Veranlagung zu einem hohen Fußlängsgewölbe.</ListItem>
            <ListItem>
                Neurologische Erkrankungen: Bestimmte neurologische Erkrankungen wie die Spina bifida, die Zerebralparese oder die
                Charcot-Marie-Tooth-Erkrankung können zu einem Hohlfuß führen.
            </ListItem>
            <ListItem>
                Muskuläre oder Sehnenstörungen: Dysbalancen in der Muskulatur oder Sehnenverkürzungen können die Fußgewölbeform beeinflussen und zu
                einem Hohlfuß führen.
            </ListItem>
            <ListItem>
                Verletzungen oder Traumata: Frakturen oder Verletzungen des Fußes können das Fußgewölbe verändern und zu einem Hohlfuß führen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Symptome und Beschwerden, die mit einem Hohlfuß einhergehen, können unterschiedlich sein. Typische Anzeichen eines Hohlfußes können
            sein:
        </Text>
        <OrderedList>
            <ListItem>Fußschmerzen: Insbesondere im Bereich des Fußgewölbes oder der Ferse können Schmerzen auftreten.</ListItem>
            <ListItem>
                Instabilität: Ein Hohlfuß kann zu einem unphysiologischen, instabilen Gangbild führen, da der Fußkontakt mit dem Boden reduziert ist.
            </ListItem>
            <ListItem>Eingeschränkte Beweglichkeit: Die Beweglichkeit des Fußes kann aufgrund der Fußdeformität eingeschränkt sein.</ListItem>
            <ListItem>
                Schwierigkeiten beim Schuhkauf: Aufgrund der ausgeprägten Längswölbung kann es schwierig sein, passende Schuhe zu finden.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung eines Hohlfußes richtet sich nach der Ursache und den individuellen Symptomen. In einigen Fällen sind keine spezifischen
            Behandlungen erforderlich, solange der Hohlfuß keine Beschwerden verursacht. Wenn jedoch Schmerzen oder Funktionsstörungen auftreten,
            können verschiedene Maßnahmen ergriffen werden:
        </Text>
        <OrderedList>
            <ListItem>
                Orthopädische Schuheinlagen oder Orthesen: Speziell angefertigte Einlagen oder Orthesen können den Fuß unterstützen und die
                Gewichtsverteilung verbessern.
            </ListItem>
            <ListItem>
                Physiotherapie: Gezielte Übungen und Dehnungen können helfen, die Muskulatur zu stärken, die Beweglichkeit zu verbessern und das
                Gleichgewicht zu fördern.
            </ListItem>
            <ListItem>
                Stoßwellentherapie zur Schmerzreduktion, Verbesserung der Durchblutung, Stimulation von Stoffwechselprozessen und Förderung der
                Selbstheilung.
            </ListItem>
            <ListItem>
                Fußstützen: Gelegentlich können Schienen, Schuhstützen oder Fußgelenksorthesen verwendet werden, um den Fuß zu stabilisieren.
            </ListItem>
            <ListItem>Operation: Eine chirurgische Korrektur sollte allenfalls in Ausnahmefällen in Erwägung gezogen werden.</ListItem>
        </OrderedList>
        <Text>
            Ein Hohlfuß sollte optimalerweise von einem Facharzt für Orthopädie untersucht und behandelt werden, um mögliche Schmerzen und
            Funktionsstörungen zu reduzieren und Komplikationen zu vermeiden.
        </Text>
    </Stack>
);
