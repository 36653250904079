import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const MigraeneItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Migräne ist eine Erkrankung, die sich durch wiederkehrende Kopfschmerzepisoden auszeichnet. Es handelt sich um eine komplexe
            Erkrankung, bei der genetische, umweltbedingte und neurochemische Faktoren eine Rolle spielen. Eine Migräne tritt typischerweise
            anfallsartig auf und kann erhebliche Auswirkungen auf das tägliche Leben und die Lebensqualität haben.
        </Text>
        <Text>Die Symptome einer Migräne können unterschiedlich sein, aber typische Anzeichen sind:</Text>
        <OrderedList>
            <ListItem>
                Starke, pulsierende Kopfschmerzen, die oft nur auf einer Seite des Kopfes auftreten, aber auch auf beiden Seiten auftreten können.
            </ListItem>
            <ListItem>
                Begleitsymptome wie Übelkeit, Erbrechen, Lichtempfindlichkeit (Photophobie), Geräuschempfindlichkeit (Phonophobie) und manchmal auch
                Geruchsempfindlichkeit (Osmophobie) können vorliegen.
            </ListItem>
            <ListItem>
                Vorläufer-Symptome, die als "Aura" bezeichnet werden und visuelle Störungen (z. B. Flimmern, Lichtblitze) oder sensorische Störungen
                (z. B. Kribbeln, Taubheit) umfassen können. Jedoch erleben nicht alle Migräne-Patienten eine Aura.
            </ListItem>
        </OrderedList>
        <Text>
            Die genaue Ursache einer Migräne ist noch nicht vollständig geklärt. Es wird jedoch angenommen, dass eine Kombination aus genetischen
            Faktoren und Umweltbedingungen eine Rolle spielt. Migräne wird oft durch bestimmte Ereignisse (Trigger) ausgelöst. Häufige Trigger sind z.
            B. Stress, bestimmte Lebensmittel oder Getränke, Schlafmangel, Hormonveränderungen bei Frauen (z. B. während der Menstruation),
            Veränderungen des Wetters oder starke Sinnesreize wie helles Licht oder laute Geräusche.
        </Text>
        <Text>
            Die Behandlung der Erkrankung soll Symptome lindern, die Häufigkeit und Schwere der Anfälle reduzieren und den Alltag der Betroffenen
            verbessern. Die Behandlung kann aus einer Kombination von medikamentöser Therapie und nicht-medikamentösen Maßnahmen bestehen.
        </Text>
        <Text>
            Medikamente, die zur Behandlung der Migräne eingesetzt werden, umfassen Schmerzmittel wie nichtsteroidale entzündungshemmende Medikamente,
            Triptane und in einigen Fällen auch z. B. Antidepressiva oder Antiepileptika.
        </Text>
        <Text>Nicht-medikamentöse Maßnahmen zur Migränebehandlung und -vorbeugung bestehen aus:</Text>
        <OrderedList>
            <ListItem>
                Trigger-Management: Identifikation und Vermeidung von Auslösern, die bei den betroffenen Personen Migräneanfälle auslösen können.
            </ListItem>
            <ListItem>
                Entspannungstechniken: Stressmanagement, Entspannungsübungen wie Meditation, tiefe Atmung oder Yoga können helfen, Spannungen zu
                reduzieren und Migräneanfälle zu vermeiden.
            </ListItem>
            <ListItem>Regelmäßiger Schlaf: Ausreichender und regelmäßiger Schlaf kann dazu beitragen, Migräneanfälle zu reduzieren.</ListItem>
            <ListItem>
                Regelmäßige körperliche Aktivität: Moderate körperliche Bewegung kann dazu beitragen, Migräneanfälle zu reduzieren und die allgemeine
                Gesundheit zu verbessern.
            </ListItem>
            <ListItem>Akupunktur kann helfen Migräneanfälle zu reduzieren oder die Intensität der Anfälle deutlich abzumildern.</ListItem>
        </OrderedList>
        <Text>
            Falls Sie eine Migräne vermuten, ist eine ärztliche Vorstellung und Abklärung sinnvoll, um eine genaue Diagnose zu erhalten, andere
            Erkrankungen auszuschließen und eine Behandlungsstrategie zu erstellen, die auf Ihren individuellen Bedürfnissen und Symptomen basiert.
        </Text>
    </Stack>
);
