import { Box, Button, Container, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = React.forwardRef<HTMLDivElement>((props, ref) => (
    <Box
        ref={ref}
        bg={useColorModeValue('white', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}
        borderTop='1px solid'
        borderColor={useColorModeValue('gray.200', 'gray.900')}
    >
        <Container
            as={Stack}
            maxW={'container.xl'}
            py={4}
            px={8}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}
        >
            <Text>© 2023 Dr. med. Jan Festring</Text>
            <Stack direction={'row'} spacing={4}>
                <Link to='datenschutz'>
                    <Button size={{ base: 'sm', md: 'md' }} variant={'link'}>
                        Datenschutz
                    </Button>
                </Link>
                <Link to='impressum'>
                    <Button size={{ base: 'sm', md: 'md' }} variant={'link'}>
                        Impressum
                    </Button>
                </Link>
            </Stack>
        </Container>
    </Box>
));

export const Footer2: React.FC = () => {
    return (
        <Box bg={useColorModeValue('white', 'gray.900')} color={useColorModeValue('gray.700', 'gray.200')}>
            <Container
                as={Stack}
                maxW={'container.xl'}
                py={4}
                px={8}
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'center', md: 'center' }}
            >
                <Text>© 2023 Dr. med. Jan Festring</Text>
                <Stack direction={'row'} spacing={4}>
                    <Link to='datenschutz'>
                        <Button size={{ base: 'sm', md: 'md' }} variant={'link'}>
                            Datenschutz
                        </Button>
                    </Link>
                    <Link to='impressum'>
                        <Button size={{ base: 'sm', md: 'md' }} variant={'link'}>
                            Impressum
                        </Button>
                    </Link>
                </Stack>
            </Container>
        </Box>
    );
};
