import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const KnickfussItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>Der Knickfuß, auch bekannt als Pes valgus, ist eine Fußdeformität, bei der der Fuß nach innen knickt.</Text>
        <Text>Die Ursachen für einen Knickfuß können vielfältig sein:</Text>
        <OrderedList>
            <ListItem>
                Angeborene Faktoren: Einige Menschen haben von Geburt an eine natürliche Veranlagung zu einem abgeflachten Fußgewölbe oder zu einer
                Fehlbildung der Fußknochen.
            </ListItem>
            <ListItem>Muskel- und Sehnenstörungen: Muskuläre Dysbalancen oder Sehnenverkürzungen können den Fuß nach innen knicken lassen.</ListItem>
            <ListItem>
                Neurologische Erkrankungen: Bestimmte neurologische Erkrankungen wie z. B. eine periphere Neuropathie, spastische Lähmungen oder eine
                Muskeldystrophie können den Knickfuß begünstigen.
            </ListItem>
            <ListItem>
                Verletzungen oder Traumata: Frakturen oder Verletzungen des Fußes können das Fußgewölbe beeinträchtigen und zu einem Knickfuß führen.
            </ListItem>
        </OrderedList>
        <Text>Typische Symptome eines Knickfußes können sein:</Text>
        <OrderedList>
            <ListItem>Fußschmerzen: Schmerzen können im Bereich des Knöchels, des Fußgewölbes oder der Ferse auftreten.</ListItem>
            <ListItem>
                Instabilität: Ein Knickfuß kann zu einem unphysiologischen, instabilen Gangbild führen, da der Fußkontakt mit dem Boden beeinträchtigt
                ist.
            </ListItem>
            <ListItem>
                Abnutzung der Schuhe: Aufgrund der abnormen Fußstellung kann es zu ungleichmäßigem Verschleiß an den Schuhsohlen kommen.
            </ListItem>
            <ListItem>Fußermüdung: Personen mit einem Knickfuß können schneller Ermüdungserscheinungen in den Füßen verspüren.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung eines Knickfußes richtet sich nach der Ursache und den individuellen Symptomen. Verschiedene Maßnahmen können in Frage
            kommen:
        </Text>
        <OrderedList>
            <ListItem>
                Orthopädische Schuheinlagen oder Orthesen: Speziell angefertigte Einlagen oder Orthesen können den Fuß unterstützen und die
                Fußstellung korrigieren.
            </ListItem>
            <ListItem>
                Physiotherapie: Gezielte Übungen zur Stärkung der Fuß- und Unterschenkelmuskulatur sowie zur Verbesserung des Gleichgewichts und der
                Stabilität können hilfreich sein.
            </ListItem>
            <ListItem>
                Schuhauswahl: Das Tragen von geeigneten Schuhen mit ausreichender Unterstützung und Stabilität kann die Beschwerden reduzieren.
            </ListItem>
            <ListItem>
                Operation: In besonders schweren Fällen oder bei anhaltenden Beschwerden kann im Einzelfall eine operative Korrektur des Knickfußes in
                Erwägung gezogen werden, um die Fußstellung zu verbessern.
            </ListItem>
        </OrderedList>
        <Text>
            Es ist wichtig, einen Knickfuß von dem Facharzt für Orthopädie Ihres Vertrauens untersuchen und behandeln zu lassen, um mögliche Schmerzen
            und Funktionsstörungen zu reduzieren und Komplikationen und Folgeschäden zu vermeiden.
        </Text>
    </Stack>
);
