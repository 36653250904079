import * as React from 'react';
import { Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import GanganalyseImage from 'images/leistungen/Ganganalyse.jpg';

export const GanganalyseItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Ganganalyse ist eine Methode zur Untersuchung und Bewertung des Gangmusters eines Menschen. Sie dient dazu Probleme, Abweichungen und
            Dysbalancen im Gangbild zu identifizieren und geeignete Behandlungsansätze zu entwickeln. Die Ganganalyse umfasst eine detaillierte
            Beobachtung und Bewertung verschiedener Aspekte des Gehens, einschließlich Schrittweite, Schrittlänge, Ganggeschwindigkeit, Fußaufsatz,
            Gleichgewicht, Körperhaltung und Bewegungsmuster.
        </Text>
        <Image src={GanganalyseImage} alt='GanganalyseImage' boxShadow={'md'} />
        <Text>
            Es gibt verschiedene Techniken und Instrumente, die in der Ganganalyse verwendet werden, um genaue Messungen und Bewertungen vorzunehmen:
        </Text>
        <OrderedList>
            <ListItem>
                Visuelle Beobachtung: Ein erfahrener Beobachter kann das Gangmuster visuell beobachten und mögliche Abweichungen oder Auffälligkeiten
                erkennen. Hierbei werden Faktoren wie Haltung, Ausrichtung der Gelenke, Bewegung der Arme und Beine sowie mögliche Schwierigkeiten und
                Dysbalancen berücksichtigt.
            </ListItem>
            <ListItem>
                Druckmessung: Durch den Einsatz von Drucksensoren oder Pedobarographen können Druckverteilung und Belastungsmuster während des Gehens
                gemessen werden. Dies kann helfen, übermäßige Belastung auf bestimmte Bereiche des Fußes und Dysbalancen in der Fußbelastung zu
                identifizieren.
            </ListItem>
            <ListItem>
                Elektromyografie (EMG): EMG-Messungen erfassen die elektrische Aktivität der Muskeln. Dies ermöglicht die Beurteilung von
                Muskelaktivität, Koordination und Timing.
            </ListItem>
        </OrderedList>
        <Text>
            Die Ganganalyse wird in der Orthopädie und Sportmedizin bei vielen Indikationen eingesetzt. Sie kann bei der Diagnose und Behandlung von
            verschiedenen Erkrankungen und Verletzungen des Bewegungsapparates unterstützen, wie beispielsweise Gangstörungen, Gelenkschmerzen,
            Muskelschwäche oder neurologischen Störungen.
        </Text>
        <Text>
            Basierend auf den Ergebnissen der Ganganalyse können individuell angepasste Behandlungspläne entwickelt werden, einschließlich
            Physiotherapie, Muskelkräftigung, Bewegungsübungen, orthopädischen Hilfsmitteln oder anderen therapeutischen Interventionen. Ziel ist es,
            das Gangmuster zu verbessern, Schmerzen zu lindern, die Stabilität zu erhöhen und die funktionelle Mobilität des Patienten zu fördern.
        </Text>
        <Text>
            Die Ganganalyse sollte von einem qualifizierten Facharzt bewertet werden, der über das entsprechende Wissen und die Erfahrung verfügt, um
            eine genaue Interpretation der Ergebnisse vorzunehmen.
        </Text>
    </Stack>
);
