import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const HammerzeheItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Der Begriff Hammerzehe umschreibt eine häufig auftretende Deformität eines Zehs, der eine verstärkte Beugung im Zehenmittelgelenk
            (PIP-Gelenk) aufweist.
        </Text>
        <Text>
            Die genaue Ursache von Hammerzehen ist oft nicht eindeutig feststellbar. Es gibt jedoch einige Faktoren, die zu ihrer Entstehung beitragen
            können, darunter:
        </Text>
        <OrderedList>
            <ListItem>
                Ungünstige Schuhwahl: Das Tragen von zu engen Schuhen mit hohen Absätzen kann die Zehen in eine ungünstige Position zwingen und zur
                Entwicklung von Hammerzehen beitragen.
            </ListItem>
            <ListItem>
                Muskelschwäche und muskuläre Dysbalancen: Eine Schwäche der Muskeln und Bänder um die Zehen herum kann zu einer unzureichenden
                Unterstützung führen und das Risiko der Entstehung von Hammerzehen erhöhen.
            </ListItem>
            <ListItem>
                Genetische Veranlagung: Es gibt eine genetische Komponente bei der Entwicklung von Hammerzehen und das Risiko kann höher sein, wenn
                andere Familienmitglieder ebenfalls betroffen sind.
            </ListItem>
            <ListItem>
                Vorausgegangene Verletzungen: Frühere Verletzungen oder Traumata der Zehen können die Entstehung von Hammerzehen begünstigen.
            </ListItem>
        </OrderedList>
        <Text>Die Symptome von Hammerzehen können variieren, aber typische Anzeichen sind:</Text>
        <OrderedList>
            <ListItem>Krümmung der Zehen an den Gelenken, wodurch sie wie Hämmer aussehen können.</ListItem>
            <ListItem>Schmerzen, insbesondere beim Gehen oder Tragen von Schuhen.</ListItem>
            <ListItem>Reibung oder Hautirritationen an den Stellen, an denen die Zehen den Schuh berühren.</ListItem>
            <ListItem>Schwierigkeiten beim Strecken oder Bewegen der Zehen.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung von Hammerzehen kann konservativ oder operativ sein, abhängig von der Schwere der Symptome und dem Ausmaß der Deformität.
            Konservative Maßnahmen können Folgendes umfassen:
        </Text>
        <OrderedList>
            <ListItem>Verwendung von Schuheinlagen oder -polstern zur Druckentlastung und zur Verbesserung der Zehenstellung.</ListItem>
            <ListItem>Verwendung von Zehenspreizern oder Schienen, um die Zehen in eine verbesserte Position zu bringen und zu halten.</ListItem>
            <ListItem>Durchführung von Übungen zur Stärkung der Zehenmuskulatur und Verbesserung der Flexibilität.</ListItem>
            <ListItem>
                Vermeidung von zu engen oder spitzen Schuhen oder Absatzschuhen und Tragen von Schuhen mit ausreichend Platz für die Zehen.
            </ListItem>
            <ListItem>Schmerzlindernde und / oder entzündungshemmende Medikamente.</ListItem>
        </OrderedList>
        <Text>
            Falls Sie Symptome von Hammerzehen haben sollten Sie den Facharzt für Orthopädie Ihres Vertrauens aufsuchen, um eine genaue Diagnose zu
            erhalten und die besten Behandlungsmöglichkeiten zu besprechen. Eine frühzeitige Behandlung kann hier helfen, Schmerzen zu lindern und die
            Funktion der Zehen langfristig zu erhalten.
        </Text>
    </Stack>
);
