import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const JumpersKneeItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Das Patellaspitzensyndrom, auch als Jumper's Knee oder Sinding-Larsen-Johansson-Krankheit bekannt, ist eine schmerzhafte Erkrankung, die
            durch Reizung und Entzündung der Patellasehne am unteren Ende der Kniescheibe (Patella) verursacht wird. Sie betrifft vor allem aktive
            Kinder und Jugendliche im Wachstumsalter.
        </Text>
        <Text>
            Man geht davon aus, dass wiederholte Belastungen zu einer Überlastung der Patellasehne führen. Vor allem häufige Aktivitäten mit
            Sprungbewegungen, Laufen, Springen und plötzlichen Stopps oder Richtungswechseln gelten als Ursache für das Patellaspitzensyndrom. Es kann
            auch mit Wachstumsschüben einher gehen, da die Knochen schneller wachsen als die Sehnen und Muskeln, was zu Dysbalancen und Reizungen
            führen kann.
        </Text>
        <Text>Die Symptome des Patellaspitzensyndroms umfassen in der Regel:</Text>
        <OrderedList>
            <ListItem>Schmerzen und Empfindlichkeit unterhalb der Kniescheibe, insbesondere beim Drücken oder Belasten der Patellasehne.</ListItem>
            <ListItem>Schwellung und Entzündung im Bereich der Patellasehne.</ListItem>
            <ListItem>Steifheit und eine eingeschränkte Beweglichkeit des Kniegelenks.</ListItem>
            <ListItem>Schmerzen bei Aktivitäten wie Springen, Laufen, Treppensteigen oder knienden Positionen.</ListItem>
        </OrderedList>
        <Text>Die Behandlung des Patellaspitzensyndroms beinhaltet in der Regel konservative Maßnahmen:</Text>
        <OrderedList>
            <ListItem>
                Ruhigstellung und Belastungsreduktion: Vermeidung von Aktivitäten, die die Schmerzen verschlimmern und Begrenzung von belastenden
                Bewegungen für das Kniegelenk.
            </ListItem>
            <ListItem>
                Physiotherapie: Ein spezialisiertes Übungsprogramm, das darauf abzielt, die Muskulatur rund um das Knie zu stärken, die Flexibilität
                zu verbessern und die muskuläre Balance wiederherzustellen.
            </ListItem>
            <ListItem>
                Stoßwellentherapie zur Schmerzlinderung, Verbesserung der Durchblutung, Stimulation von Stoffwechselprozessen und Förderung der
                Selbstheilung.
            </ListItem>
            <ListItem>Magnetfeldtherapie zur Schmerzreduktion und Unterstützung der Selbstheilungsprozesse.</ListItem>
            <ListItem>
                Schmerzlinderung: Die Anwendung von Eispackungen oder entzündungshemmenden Medikamenten kann helfen, Schmerzen und Entzündungen zu
                reduzieren.
            </ListItem>
            <ListItem>
                Unterstützende Maßnahmen: Verwendung von Kniebandagen zur Entlastung der Patellasehne oder orthopädischen Schuheinlagen.
            </ListItem>
            <ListItem>
                Rückkehr zum Sport: Eine schrittweise Rückkehr zum Sport sollte erst erfolgen, wenn die Schmerzen abgeklungen sind und die volle
                Funktion des Kniegelenks wiederhergestellt ist.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung eines Patellaspitzensyndroms sollte unter der Anleitung des Facharztes für Orthopädie Ihres Vertrauens erfolgen, um eine
            angemessene Diagnosestellung und eine zielführende, individuelle Behandlungsstrategie zu gewährleisten.
        </Text>
    </Stack>
);
