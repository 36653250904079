import * as React from 'react';
import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';

export const OsteoporoseItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Osteoporose-Therapie zielt darauf ab, den Knochenabbau zu verlangsamen, die Knochendichte zu erhöhen und das Risiko von Frakturen bei
            Menschen mit Osteoporose zu verringern. Der Behandlungsansatz umfasst in der Regel eine Kombination aus medikamentöser Therapie,
            Ernährungsmaßnahmen, Bewegung und Lebensstiländerungen.
        </Text>
        <Text>
            Die medikamentöse Therapie der Osteoporose umfasst verschiedene Medikamente, die den Knochenabbau hemmen und die Knochendichte erhöhen
            können. Dazu gehören:
        </Text>
        <OrderedList>
            <ListItem>
                Bisphosphonate: Diese Medikamente hemmen den Knochenabbau und verringern das Frakturrisiko. Sie werden in der Regel als Tabletten (z.
                B. Alendronsäure) eingenommen oder als intravenöse Infusion (z. B. Ibandronsäure) verabreicht.
            </ListItem>
            <ListItem>
                Selektive Östrogenrezeptormodulatoren: Sie wirken ähnlich wie Östrogen im Körper und können den Knochenabbau reduzieren. Sie werden
                oral eingenommen.
            </ListItem>
            <ListItem>
                Denosumab (Handelsname z. B. Prolia): Dieses Medikament hemmt ein Protein, das den Knochenabbau fördert. Es wird alle sechs Monate als
                subkutane Injektion verabreicht.
            </ListItem>
            <ListItem>
                Teriparatid und Abaloparatid: Diese Medikamente sind synthetische Formen des Parathormons und können den Knochenaufbau stimulieren.
                Sie werden täglich als subkutane Injektion verabreicht.
            </ListItem>
            <ListItem>
                Hormonersatztherapie: Bei postmenopausalen Frauen kann eine Hormonersatztherapie mit Östrogen und Progesteron in Erwägung gezogen
                werden, um den Knochenabbau zu verlangsamen. Die Entscheidung sollte jedoch individuell unter Berücksichtigung aller Vorteile und
                Risiken getroffen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Zusätzlich zur medikamentösen Therapie spielen auch Ernährungsmaßnahmen eine wichtige Rolle in der Osteoporose-Therapie. Eine ausreichende
            Zufuhr von Kalzium und Vitamin D ist wichtig für den Knochenstoffwechsel. Dies kann durch eine ausgewogene Ernährung mit kalziumreichen
            Lebensmitteln wie Milchprodukten, grünem Blattgemüse, Nüssen und Samen sowie durch eine ausreichende Sonneneinstrahlung zur
            Vitamin-D-Synthese erreicht werden. In einigen Fällen kann auch eine Kalzium- und/oder Vitamin-D-Zufuhr erforderlich sein.
        </Text>
        <Text>
            Regelmäßige körperliche Aktivität, insbesondere gewichtsbelastende Übungen wie Gehen, Joggen, Tanzen oder Krafttraining, ist ebenfalls
            wichtig, um die Knochendichte zu erhalten oder zu erhöhen.
        </Text>
        <Text>
            Lebensstiländerungen wie der Verzicht auf Rauchen, Alkoholkonsum und andere Noxen können ebenfalls zur Vorbeugung und Behandlung von
            Osteoporose beitragen.
        </Text>
        <Text>
            Die Behandlung der Osteoporose erfordert eine individuelle Herangehensweise, basierend auf dem Schweregrad der Erkrankung, dem Alter, dem
            Geschlecht und anderen individuellen Faktoren. Es ist wichtig, regelmäßige Kontrolluntersuchungen und Knochendichtemessungen
            durchzuführen, um den Erfolg der Therapie zu überwachen und gegebenenfalls Anpassungen vorzunehmen. Ein Facharzt für Orthopädie kann eine
            umfassende Beurteilung vornehmen und einen individuellen Therapieplan erstellen.
        </Text>
    </Stack>
);
