import { Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const BaenderrissItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Bänderriss ist eine Verletzung, bei der die Bänder, die die Knochen in einem Gelenk stabilisieren, teilweise oder vollständig reißen.
            Dies kann in verschiedenen Gelenken des Körpers auftreten, am häufigsten jedoch im Sprunggelenk.
        </Text>
        <Text>
            Ein Bänderriss tritt normalerweise auf, wenn das Gelenk übermäßig gestreckt, verdreht oder belastet wird. Dies kann z. B. während
            sportlicher Aktivitäten, insbesondere bei schnellen Richtungswechseln, plötzlichem Abstoppen oder bei einem Sturz auftreten.
        </Text>
        <Text>
            Die Symptome eines Bänderrisses bestehen in der Regel aus plötzlichen Schmerzen, Schwellungen, Blutergüssen und einer eingeschränkter
            Beweglichkeit des betroffenen Gelenks. Auch ein hörbares Knacken oder Reißen kann auftreten.
        </Text>
        <Text>
            Die Behandlung eines Bänderrisses kann je nach Schweregrad der Verletzung variieren. In leichten Fällen kann die PECH-Regel (P = Pause, E
            = Eis, C = Compression, H = Hochlagerung) angewendet werden, um Schmerzen zu lindern und Schwellungen zu reduzieren. In schwereren Fällen
            kann eine Schiene oder ein Gipsverband angelegt werden, um das Gelenk zu stabilisieren und die Heilung zu unterstützen. Physiotherapie
            kann helfen, die Muskeln rund um das betroffene Gelenk zu stärken und die Beweglichkeit wiederherzustellen.
        </Text>
        <Text>
            Die Genesungsdauer hängt von der Schwere des Bänderrisses ab. Leichte Verletzungen können innerhalb von Wochen heilen, während
            ausgedehntere Risse mehrere Monate benötigen können. Es ist sinnvoll, während des Heilungsprozesses den Empfehlungen eines Facharztes für
            Orthopädie und Unfallchirurgie zu folgen, um eine vollständige Genesung zu ermöglichen und das Risiko von Folgeverletzungen zu verringern.
        </Text>
    </Stack>
);
