import * as React from 'react';
import { Box, Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import EMG1 from 'images/leistungen/EMG-Bio1.jpg';
import EMG2 from 'images/leistungen/EMG-Bio2.jpg';

export const ElektromyographieItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Elektromyographie (EMG) ist eine diagnostische Methode zur Messung und Aufzeichnung der elektrischen Aktivität der Muskeln. Sie wird
            verwendet, um Informationen über die Funktion und Aktivität der Muskeln sowie über die Kommunikation zwischen Nerven und Muskeln zu
            erhalten. Die EMG-Messung kann Hinweise auf muskuläre Erkrankungen, Nervenschäden, Nervenkompressionen, Muskelentzündungen und andere
            Störungen des Nerven-Muskel-Systems liefern.
        </Text>
        <Box>
            <Image float={'left'} w={{ base: '100%', md: 270 }} mr={4} mb={{ base: 2, md: 0 }} src={EMG2} boxShadow={'md'} />
            <Text mb={2}>
                Bei einer EMG-Messung werden Elektroden auf der Haut über dem zu untersuchenden Muskel platziert. Die Elektroden erfassen die
                elektrischen Signale, die während der Muskelaktivität entstehen. Diese Signale werden in Form von grafischen Wellen oder als
                elektrische Werte aufgezeichnet und von einem spezialisierten Facharzt ausgewertet.
            </Text>
            <Text>
                In der Orthopädie und Sportmedizin wird in der Regel die Oberflächen-Elektromyographie (sEMG) angewendet. Bei dieser Methode werden
                die Elektroden auf der Haut über den Muskelbereichen platziert. Sie erfassen die elektrischen Signale, die von den Muskeln abgegeben
                werden. Die sEMG-Messung ist weniger invasiv und eignet sich gut für die Untersuchung größerer Muskelgruppen und die Beurteilung des
                allgemeinen Muskeltonus.
            </Text>
        </Box>
        <Text>Die EMG-Messung kann verschiedene Informationen liefern, darunter:</Text>
        <OrderedList>
            <ListItem>
                Muskelaktivität: Die EMG-Messung zeigt die elektrischen Aktivitätsmuster der Muskeln während der Ruhe, Kontraktion oder Entspannung.
                Dadurch können Muskelaktivitätsmuster beurteilt und Abweichungen oder Anomalien erkannt werden.
            </ListItem>
            <ListItem>
                Muskelkraft: Durch die Messung der elektrischen Signale kann die EMG-Messung Informationen über die Muskelkraft und -aktivität
                liefern. Dies kann helfen, Muskelkrankheiten oder Schwächen zu identifizieren.
            </ListItem>
            <ListItem>
                Nervenfunktion: Die EMG-Messung kann auch Hinweise auf Nervenfunktionsstörungen liefern, indem sie die Kommunikation zwischen Nerven
                und Muskeln bewertet. Nervenkompressionen, Nervenschäden oder Neuropathien können anhand der EMG-Messung erkannt werden.
            </ListItem>
        </OrderedList>
        <Image src={EMG1} boxShadow={'md'} />
        <Text>
            Die EMG-Messung wird in der klinischen Praxis in verschiedenen Bereichen eingesetzt, einschließlich Orthopädie und Sportmedizin. Sie kann
            zur Diagnose und Überwachung von Erkrankungen wie Muskel- und Nervenstörungen, Polyneuropathien, Muskelentzündungen, Muskeldystrophien,
            Nervenkompressionssyndromen und anderen neurologischen oder muskuloskelettalen Erkrankungen eingesetzt werden.
        </Text>
        <Text>
            Es ist wichtig zu beachten, dass die EMG-Messung von einem qualifizierten Facharzt durchgeführt werden sollte, da eine genaue
            Interpretation der Ergebnisse erforderlich ist.
        </Text>
    </Stack>
);
