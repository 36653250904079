import * as React from 'react';
import { Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import KryoImage from 'images/leistungen/Kryotherapie.jpg';

export const KryotherapieItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Kryotherapie wird in der Orthopädie häufig als Teil eines umfassenden Behandlungsplans für verschiedene orthopädische Beschwerden und
            Verletzungen eingesetzt. Hier sind einige Möglichkeiten, wie die Kryotherapie in der Orthopädie angewendet werden kann:
        </Text>
        <Image src={KryoImage} display={{ base: 'block', md: 'none' }} boxShadow={'md'} />
        <OrderedList>
            <Image float={'right'} src={KryoImage} w={275} display={{ base: 'none', md: 'block ' }} ml={4} boxShadow={'md'} alt='Kryotherapie' />
            <ListItem>
                Entzündungen und Schmerzen: Kryotherapie kann bei Entzündungen und Schmerzen, die mit orthopädischen Verletzungen oder Erkrankungen
                wie Sehnenentzündungen, Bursitis oder Arthritis verbunden sind, eingesetzt werden. Durch die Anwendung von Kälte kann die lokale
                Entzündungsreaktion verringert werden, was zu einer Schmerzlinderung führen kann.
            </ListItem>
            <ListItem>
                Verletzungen und Traumata: Nach akuten Verletzungen wie Verstauchungen, Zerrungen oder Prellungen kann die Anwendung von Kälte zur
                Verringerung von Schwellungen und zur Linderung von Schmerzen beitragen. Dies kann auch in der postoperativen Phase nach
                orthopädischen Eingriffen hilfreich sein.
            </ListItem>
            <ListItem>
                Rehabilitation nach Operationen: Nach orthopädischen Eingriffen wie Gelenkersatz oder Arthroskopie kann Kryotherapie in der
                Rehabilitationsphase eingesetzt werden, um Schwellungen zu reduzieren und die Genesung zu unterstützen.
            </ListItem>
            <ListItem>
                Sportverletzungen: Sportler verwenden häufig die Kryotherapie, um sich nach intensiven Trainingseinheiten oder Wettkämpfen schneller
                zu erholen und Muskelkater zu lindern.
            </ListItem>
        </OrderedList>
        <Text>
            Die Anwendung der Kryotherapie sollte immer in Absprache mit medizinischem Fachpersonal erfolgen. Der Facharzt für Orthopädie Ihres
            Vertrauens kann die richtige Anwendungsweise und Dauer der Kryotherapie für Ihre individuelle Situation einschätzen.
        </Text>
    </Stack>
);
