import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const KreuzbandrissItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Kreuzbandriss bezeichnet die Verletzung eines der beiden Kreuzbänder im Kniegelenk. Das vordere Kreuzband (VKB) und das hintere
            Kreuzband (HKB) befinden sich im Inneren des Kniegelenks und stabilisieren es, indem sie das Schienbein mit dem Oberschenkelknochen
            verbinden.
        </Text>
        <Text>
            Die häufigste Ursache für einen Kreuzbandriss ist eine plötzliche, traumatische Bewegung des Kniegelenks, wie z. B. ein plötzliches
            Verdrehen oder ein starkes Überstrecken. Sportarten wie Fußball, Basketball und Skifahren sind besonders risikoreich in Bezug auf
            Kreuzbandverletzungen.
        </Text>
        <Text>Die Symptome eines Kreuzbandrisses können je nach Schweregrad der Verletzung variieren:</Text>
        <OrderedList>
            <ListItem>
                Plötzlicher Schmerz im Knie: Es kann ein plötzlicher und intensiver Schmerz im Knie auftreten, oft begleitet von einem lauten Knall
                oder Knacken.
            </ListItem>
            <ListItem>
                Schwellung: Innerhalb weniger Stunden nach der Verletzung kann durch einen Gelenkerguss eine deutliche Schwellung des Knies auftreten.
            </ListItem>
            <ListItem>Instabilität: Das Knie kann instabil oder "wackelig" sein, insbesondere bei seitlichen Bewegungen oder Belastungen.</ListItem>
            <ListItem>
                Bewegungseinschränkung: Die Bewegungsfreiheit des Kniegelenks kann eingeschränkt sein, insbesondere bei der Streckung oder Beugung.
            </ListItem>
            <ListItem>Unsicherheit beim Gehen: Aufgrund der Instabilität des Knies kann das Gehen unsicher oder schwierig sein.</ListItem>
        </OrderedList>
        <Text>
            Wenn ein Kreuzbandriss vermutet wird, sollte eine zeitnahe ärztliche Untersuchung erfolgen, im Bestfall durch einen Facharzt für
            Orthopädie und Unfallchirurgie. Das Kniegelenk wird manuell untersucht und eine medizinische Bildgebung (z. B. Ultraschall,
            Röntgendiagnostik, MRT-Untersuchung etc.) wird durchgeführt, sodass eine exakte Diagnose gestellt werden kann.
        </Text>
        <Text>
            Die Behandlung eines Kreuzbandrisses kann sowohl konservativ als auch operativ sein und hängt von mehreren Faktoren ab wie der Schwere der
            Verletzung, dem Alter und der Aktivität des Patienten sowie den individuellen Bedürfnissen. Die Behandlungsmöglichkeiten umfassen:
        </Text>
        <OrderedList>
            <ListItem>
                Konservative Behandlung: Bei einigen Patienten, insbesondere solchen mit einem inaktiven Lebensstil oder älteren Menschen, kann eine
                konservative Behandlung empfohlen werden. Diese beinhaltet eine Ruhigstellung des Kniegelenks, das Tragen einer Knieorthese zur
                Stabilisierung des Knies, ggf. eine Entlastung und die Vermeidung von Aktivitäten, die das Knie belasten. Im Verlauf ist eine
                Physiotherapie zur Stärkung der umliegenden Muskulatur und Erhaltung der Beweglichkeit sinnvoll. Zudem können z. B. eine
                Stoßwellentherapie für die umgebenden Weichteile und ggf. Punktionen und / oder Infiltrationen für den Heilungsverlauf hilfreich sein.
            </ListItem>
            <ListItem>
                Operation: Bei jungen, aktiven Menschen oder Patienten mit Begleitverletzungen kann eine Operation zur Rekonstruktion des Kreuzbandes
                ratsam sein. Dabei wird das gerissene Kreuzband in der Regel durch ein körpereigenes Sehnentransplantat ersetzt.
            </ListItem>
        </OrderedList>
        <Text>
            Die Nachbehandlung eines Kreuzbandrisses ist ein für das Endergebnis der Genesung entscheidender Bestandteil. Ein Facharzt für Orthopädie
            und Unfallchirurgie sollte diesen Prozess engmaschig begleiten und regelmäßig nachkontrollieren, damit Folgeschäden abgewendet werden
            können. Unbehandelte Kreuzbandrisse können zu dauerhaften Beschwerden wie z. B. einer Instabilität des Kniegelenks führen und erhöhen das
            Risiko für eine vorzeitige Kniegelenkarthrose (Gonarthrose).
        </Text>
    </Stack>
);
