import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const NackenschmerzenItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Nackenschmerzen sind ein häufiges Symptom, das verschiedene Ursachen haben kann. Oft werden sie durch Muskelverspannungen im Nackenbereich
            verursacht. Hier sind einige mögliche Ursachen für Nackenschmerzen:
        </Text>
        <OrderedList>
            <ListItem>
                Verspannte Muskulatur: Langes Sitzen in einer schlechten Haltung, Stress, Überlastung oder wiederholte Bewegungen können zu einer
                Verspannung der Nackenmuskulatur führen.
            </ListItem>
            <ListItem>
                Fehlhaltung: Eine schlechte Körperhaltung, insbesondere beim Sitzen oder Schlafen, kann Nackenschmerzen verursachen. Z. B. durch
                stundenlanges Sitzen am Schreibtisch oder das Verwenden eines ungeeigneten Kissens während des Schlafens.
            </ListItem>
            <ListItem>
                Nackenverletzungen: Unfälle, Stürze oder Sportverletzungen können zu Nackenverletzungen führen, wie z. B. ein Schleudertrauma.
            </ListItem>
            <ListItem>
                Degenerative Erkrankungen: Verschleißbedingte Veränderungen der Hals- und Brustwirbelsäule, wie z. B. Bandscheibenerkrankungen oder
                Arthrose, können zu Nackenschmerzen führen.
            </ListItem>
            <ListItem>
                Nervenkompression: Eine Kompression oder Irritation von Nervenwurzeln im Nackenbereich, wie z. B. eine Neuroforamenstenose (Verengung
                eines Nervenaustrittskanals) oder ein Bandscheibenvorfall, kann Nackenschmerzen verursachen, die auch in den Arm oder Kopf ausstrahlen
                können.
            </ListItem>
            <ListItem>
                Stress und psychische Belastungen: Emotionale Belastungen und Stress können zu Muskelverspannungen im Nackenbereich führen und
                Nackenschmerzen verursachen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung von Nackenschmerzen hängt von der zu Grunde liegenden Ursache ab. Im Allgemeinen können folgende Maßnahmen zur Linderung
            von Nackenschmerzen beitragen:
        </Text>
        <OrderedList>
            <ListItem>
                Ruhe und Schonung: Vermeiden Sie Aktivitäten, die die Schmerzen verschlimmern und legen Sie Ihren Kopf zwischendurch ab, um die
                Nackenmuskulatur zu entlasten.
            </ListItem>
            <ListItem>
                Wärme- oder Kältetherapie: Die Anwendung von Wärme (z. B. mit einem warmen Handtuch oder einer Wärmflasche) oder Kälte (z. B. mit
                einem Eisbeutel) auf den betroffenen Bereich kann die Muskulatur entspannen und Schmerzen lindern. Probieren Sie aus, was für Sie
                angenehm ist.
            </ListItem>
            <ListItem>
                Stoßwellentherapie: Eine Stoßwellentherapie kann zur Verbesserung der Durchblutungssituation und damit zur Schmerzlinderung sinnvoll
                sein.
            </ListItem>
            <ListItem>
                Infiltrationstherapie: Eine Infiltrationstherapie z. B. mit ACP (Eigenbluttherapie), Lokalanästhetikum oder pflanzlichen Substanzen
                kann zur Förderung der Heilung und zur Entzündungshemmung eingesetzt werden.
            </ListItem>
            <ListItem>
                Magnetfeldtherapie: Zur Förderung der muskulären Stoffwechselsituation kann ebenfalls der Einsatz einer Magnetfeldtherapie von Vorteil
                für die Heilung und die Schmerzen sein.
            </ListItem>
            <ListItem>
                Biofeedback: Die Biofeedback-Therapie mittels EMG ist bei Nackenverspannungen eine gleichermaßen schonende und nachhaltige
                Therapiemethode.
            </ListItem>
            <ListItem>
                Schmerzlindernde Medikamente: Bei Bedarf können Schmerzmittel wie Paracetamol oder nichtsteroidale entzündungshemmende Medikamente
                eingenommen werden, um die Schmerzen zu lindern. Lesen und befolgen Sie jedoch die Anweisungen zur Dosierung und konsultieren Sie bei
                Fragen oder Bedenken einen Arzt.
            </ListItem>
            <ListItem>
                Physiotherapie: Eine gezielte physiotherapeutische Behandlung kann helfen, die Muskulatur zu lockern, die Haltung zu verbessern und
                die Beweglichkeit des Nackens zu fördern.
            </ListItem>
            <ListItem>
                Ergonomische Anpassungen: Achten Sie auf eine gute Körperhaltung beim Sitzen und Stehen, verwenden Sie eine ergonomische Unterstützung
                am Arbeitsplatz und achten Sie auf ein passendes Kissen und eine gute Schlafposition.
            </ListItem>
            <ListItem>
                Stressmanagement: Stressbewältigungstechniken wie Entspannungsübungen, Meditation oder Yoga können helfen, Stress abzubauen und
                Muskelverspannungen zu reduzieren.
            </ListItem>
        </OrderedList>
        <Text>
            Falls die Nackenschmerzen länger anhalten, sehr stark sind, sich verschlimmern oder von anderen Symptomen wie Taubheit- oder
            Kribbelgefühlen oder Schwindel begleitet werden, sollten Sie sich zeitnah an den Facharzt für Orthopädie und Unfallchirurgie Ihres
            Vertrauens wenden, damit die genaue Diagnose gestellt, die Ursache ermittelt und eine individuell abgestimmte Behandlung begonnen werden
            kann.
        </Text>
    </Stack>
);
