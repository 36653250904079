import * as React from 'react';
import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';

export const OrthesenItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Schuheinlagen, auch orthopädische Einlagen oder orthopädische Schuheinlagen genannt, sind individuell angepasste Einlagen, die in Schuhen
            getragen werden, um Fußprobleme zu behandeln oder vorzubeugen und den Komfort und die Unterstützung beim Gehen und Stehen zu verbessern.
            Sie können sowohl für medizinische als auch für sportliche Zwecke verwendet werden.
        </Text>
        <OrderedList>
            <ListItem>
                Stützende Einlagen: Bieten zusätzliche Unterstützung und Stabilität für den Fußgewölbebereich. Hilfreich bei Plattfüßen (Pes planus)
                oder Überpronation, bei der der Fuß nach innen rollt.
            </ListItem>
            <ListItem>
                Dämpfende Einlagen: Auf das Abfedern von Stößen und Belastungen beim Gehen oder Laufen ausgelegt. Nützlich bei Fuß- oder
                Fersenschmerzen, Fersensporn oder Arthrose im Fußbereich.
            </ListItem>
            <ListItem>
                Korrigierende Einlagen: Verwendet, um Fehlstellungen oder Dysbalancen im Fuß zu korrigieren. Können bei Hammerzehen, Hallux valgus
                (Schiefstand des großen Zehs), Knick-Senkfuß oder anderen strukturellen Fußproblemen eingesetzt werden.
            </ListItem>
            <ListItem>
                Sporteinlagen: Speziell für sportliche Aktivitäten entwickelt, bieten sie zusätzliche Unterstützung, Dämpfung und Stabilität. Können
                helfen, Fußbeschwerden zu reduzieren und die Leistungsfähigkeit beim Sport zu verbessern.
            </ListItem>
        </OrderedList>
        <Text>
            Schuheinlagen können aus verschiedenen Materialien wie Schaumstoff, Gel, Kunststoff oder Leder bestehen. Sie werden individuell an die
            Fußform und Bedürfnisse des Einzelnen angepasst, indem Fußabdrücke, Pedobarographien (Fußdruckmessungen), digitale Scans des Fußes oder
            Ganganalysen genommen werden. Die Einlagen können dann in vorhandene Schuhe eingepasst oder speziell für bestimmte Schuhe angefertigt
            werden.
        </Text>
        <Text>
            Es ist wichtig zu beachten, dass Schuheinlagen allein möglicherweise nicht ausreichen, um bestimmte Fußprobleme zu behandeln. Sie können
            in Kombination mit anderen therapeutischen Maßnahmen wie physiotherapeutischen Übungen, Schuhempfehlungen oder medizinischen Behandlungen
            verwendet werden. Eine fachkundige Beratung und Kontrolle durch einen Facharzt für Orthopädie und einen qualifizierten
            Orthopädieschuhtechniker ist ratsam, um die geeigneten Schuheinlagen für Ihre individuellen Bedürfnisse zu erhalten.
        </Text>
        <Text>
            Orthesen sind medizinische Hilfsmittel, die dazu dienen, Körperteile zu stabilisieren, zu unterstützen, zu entlasten oder in ihrer
            Funktion zu verbessern. Sie werden häufig zur Behandlung von orthopädischen Erkrankungen oder Verletzungen eingesetzt.
        </Text>
        <OrderedList>
            <ListItem>
                Gelenkorthesen: Um ein betroffenes Gelenk, wie z. B. das Knie, den Knöchel, das Handgelenk oder die Schulter, angelegt, um Stabilität
                zu bieten, die Bewegung einzuschränken oder die Gelenkfunktion zu unterstützen.
            </ListItem>
            <ListItem>
                Rückenorthesen: Zur Stabilisierung der Wirbelsäule eingesetzt. Können bei Wirbelsäulenverletzungen, Wirbelsäulendeformitäten wie
                Skoliose, Bandscheibenvorfällen oder zur Unterstützung bei Rückenschmerzen verwendet werden.
            </ListItem>
            <ListItem>
                Beinorthesen: Zur Stabilisierung oder Unterstützung des Beins verwendet. Können bei Beinlähmungen, Muskelschwäche, Fußfehlstellungen
                oder nach Verletzungen oder Operationen eingesetzt werden.
            </ListItem>
            <ListItem>
                Hand- und Armschienen: Zur Stabilisierung und Unterstützung des Handgelenks, der Hand oder des Arms verwendet. Können bei
                Handgelenksverletzungen, Arthrose, Sehnenscheidenentzündungen oder nach Operationen eingesetzt werden.
            </ListItem>
            <ListItem>
                Fußorthesen: In Schuhen getragen, um Fußfehlstellungen zu korrigieren, den Fuß zu entlasten oder die Fußfunktion zu verbessern. Können
                bei Plattfüßen, Spreizfüßen, Fersensporn, Fußschmerzen oder zur Unterstützung bei bestimmten Sportarten verwendet werden.
            </ListItem>
        </OrderedList>
        <Text>
            Orthesen sollten individuell angepasst und unter fachkundiger Anleitung verwendet werden. Ein Facharzt für Orthopädie kann bei der Auswahl
            und Anpassung einer passenden Orthese helfen. Die korrekte Anwendung und regelmäßige Kontrolle sind wichtig, um die bestmögliche Wirkung
            zu gewährleisten und mögliche unerwünschte Wirkungen zu vermeiden.
        </Text>
    </Stack>
);
