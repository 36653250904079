import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const TennisellenbogenItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Der Tennisellenbogen, auch als Epicondylitis radialis humeri oder Epicondylitis lateralis humeri bekannt, ist eine schmerzhafte
            Erkrankung, die durch die Überlastung der Sehnen am äußeren Ellenbogen entsteht. Er tritt nicht nur bei Tennisspielern auf, sondern kann
            auch bei oder nach anderen Aktivitäten auftreten, die repetitive Hand- und Handgelenksbewegungen erfordern.
        </Text>
        <Text>
            Die Hauptursache für einen Tennisellenbogen ist die Überlastung der Sehnen, die die Handgelenksstreckmuskulatur (Handgelenksextensoren)
            mit dem äußeren Ellenbogen verbinden. Diese Muskelgruppe bewegt die Hand Richtung Handrücken. Wiederholte Bewegungen wie Schlagbewegungen
            beim Tennis, intensives Tippen an der Computertastatur oder schwere Hebearbeiten können zu Mikroverletzungen und Entzündungen in den
            Sehnen führen.
        </Text>
        <Text>Die Symptome eines Tennisellenbogens können sein:</Text>
        <OrderedList>
            <ListItem>
                Schmerzen am äußeren Ellenbogen: Der Schmerz tritt normalerweise an der Stelle auf, wo die Sehnen am Knochen befestigt sind. Er kann
                sich allmählich entwickeln und zu einem intermittierenden oder konstanten Schmerz werden.
            </ListItem>
            <ListItem>
                Schmerzen bei bestimmten Bewegungen: Aktivitäten wie das Heben von Gegenständen, das Drehen des Unterarms oder das Festhalten eines
                Gegenstands können den Schmerz verstärken.
            </ListItem>
            <ListItem>Schmerzen beim Greifen: Das Greifen von Gegenständen, insbesondere mit den Fingerspitzen, kann schmerzhaft sein.</ListItem>
            <ListItem>
                Schwäche im Unterarm: Ein Tennisellenbogen kann zu einer Muskelschwäche und einer eingeschränkten Griffkraft (z. B. beim Händedruck)
                führen.
            </ListItem>
        </OrderedList>
        <Text>Die Behandlung eines Tennisellenbogens erfolgt in der Regel konservativ:</Text>
        <OrderedList>
            <ListItem>
                Ruhigstellung und Schonung: Reduzieren Sie vorübergehend Aktivitäten, die den Ellenbogen belasten, um die Heilung zu ermöglichen.
            </ListItem>
            <ListItem>Kälte: Kühlung des betroffenen Bereichs, um Schmerzen und die Entzündung zu lindern.</ListItem>
            <ListItem>
                Epicondylitis-Bandage: Das Tragen einer speziellen Bandage oder Orthese kann die Sehnen entlasten und die Schmerzen lindern.
            </ListItem>
            <ListItem>
                Schmerzmanagement: Die Einnahme von nichtsteroidalen entzündungshemmenden Medikamenten wie Ibuprofen kann zur Schmerzlinderung
                beitragen. Auch Salben oder Gele können verwendet werden.
            </ListItem>
            <ListItem>Eigenbluttherapie: Eine Eigenbluttherapie z. B. mit ACP kann eine Schmerzlinderung und Entzündungshemmung bewirken.</ListItem>
            <ListItem>
                Injektionstherapie: In einigen Fällen kann eine Injektion von Kortikosteroiden in den Sehnenansatz erwogen werden, um Entzündungen zu
                reduzieren.
            </ListItem>
            <ListItem>Akupunktur: Sie wird bereits seit Jahrtausenden erfolgreich zur Schmerz- und Entzündungshemmung eingesetzt.</ListItem>
            <ListItem>
                Stoßwellentherapie: Die Stoßwellentherapie kann zur Verbesserung der Durchblutung, Stimulation von Stoffwechselprozessen und Förderung
                der Selbstheilung beitragen.
            </ListItem>
            <ListItem>
                Magnetfeldtherapie: Die Durchführung einer Magnetfeldtherapie dient der Schmerzreduktion und begünstigt die Selbstheilungsprozesse.
            </ListItem>
            <ListItem>
                Physiotherapie: Übungen und Techniken zur Stärkung und Dehnung der Unterarmmuskulatur können die Genesung fördern. Massagen können
                ebenfalls sinnvoll sein.
            </ListItem>
            <ListItem>
                Anpassung der Techniken im Sport: Die Optimierung von Techniken, z. B. bei Schlägersportarten, können zur Beschwerdefreiheit
                beitragen.
            </ListItem>
            <ListItem>
                Ergonomische Verbesserungen: Ergonomische Verbesserungen, z. B. am Arbeitsplatz, können helfen die Belastung zu verringern.
            </ListItem>
        </OrderedList>
        <Text>
            Falls die konservativen Behandlungsmöglichkeiten nicht ausreichen sollten, kann in Ausnahmefällen eine Operation in Betracht kommen. Diese
            Entscheidung sollte jedoch sorgfältig abgewogen werden.
        </Text>
        <Text>
            Es ist wichtig frühzeitig einen Arzt aufzusuchen, falls Sie Symptome eines Tennisellenbogens haben, um die genaue Diagnose zu erhalten und
            eine angemessene und individuelle Behandlungsstrategie festzulegen. Ein früher Therapiebeginn mit einem umfassenden Behandlungskonzept und
            konsequenter Umsetzung sind maßgebliche Voraussetzungen für eine zeitnahe, effektive und vollständige Genesung.
        </Text>
    </Stack>
);
