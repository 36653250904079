import * as React from 'react';
import { Box, Image, Stack, Text } from '@chakra-ui/react';
import AkupunkturImage from 'images/leistungen/Akupunktur.jpg';

export const AkupunkturItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Akupunktur ist eine traditionelle chinesische Heilmethode, die seit über 3.000 Jahren angewendet wird. Bei der Akupunktur werden
            dünnen Nadeln an spezifischen Stellen des Körpers, sogenannten Akupunkturpunkten, platziert. Diese Akupunkturpunkte liegen entlang
            bestimmter Energielinien, den sogenannten Meridianen.
        </Text>
        <Box>
            <Image
                float='left'
                w={{ base: '100%', md: 285 }}
                mr={4}
                src={AkupunkturImage}
                alt='Akupunktur'
                boxShadow={'md'}
                mb={{ base: 2, md: 0 }}
            />
            <Text mb={2}>
                Der Grundgedanke der Akupunktur basiert auf dem Konzept des Qi (Lebensenergie), das durch die Meridiane fließt. Das Ziel der
                Akupunktur besteht darin, den Energiefluss im Körper auszugleichen und zu harmonisieren, um die Gesundheit zu fördern und Krankheiten
                zu behandeln. Durch das Setzen der Nadeln an den Akupunkturpunkten wird der Energiefluss reguliert und das Gleichgewicht im Körper
                wiederhergestellt.
            </Text>
            <Text mb={2}>
                Die Anwendungsbereiche der Akupunktur sind vielfältig. Sie wird häufig zur Behandlung von Schmerzen eingesetzt, insbesondere bei
                chronischen Schmerzzuständen wie Rückenschmerzen, Arthrose, muskulären Verspannungen, Kopfschmerzen, Migräne, Allergien, Tinnitus,
                Rauchentwöhnung etc. Die Akupunktur kann auch bei verschiedenen anderen Erkrankungen eingesetzt werden, darunter Verdauungsstörungen,
                Atemwegserkrankungen, Schlafstörungen, Stress und Angstzustände.
            </Text>
            <Text mb={2}>
                Die Akupunkturbehandlung erfolgt im Idealfall durch speziell ausgebildete Fachärzte, die fundierte Kenntnisse über die
                Akupunkturpunkte und die Anwendungstechniken haben. Die Nadeln werden in der Regel für eine bestimmte Zeit im Körper belassen,
                typischerweise etwa 20-30 Minuten. Während dieser Zeit können Patienten eine Entspannung oder ein leichtes Kribbeln an der
                Einstichstelle spüren.
            </Text>
            <Text mb={2}>
                Die Akupunktur wird als sichere Methode angesehen, wenn sie von geschultem Personal durchgeführt wird. Neben der klassischen
                Nadelakupunktur gibt es auch andere Formen der Akupunktur, wie zum Beispiel die Laserakupunktur, bei der Laserlicht anstelle von
                Nadeln verwendet wird oder die Ohrakupunktur.
            </Text>
            <Text>Wenn Sie Interesse an einer Akupunkturbehandlung haben, kontaktieren Sie uns gerne.</Text>
        </Box>
    </Stack>
);
