import * as React from 'react';
import { Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import LaborImage from 'images/leistungen/Labor.jpg';

export const LabordiagnostikItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Labordiagnostik bezieht sich auf den Einsatz von Labortests und Analysen, um Informationen über den Gesundheitszustand eines Patienten
            zu erhalten. Es ist eine wichtige Komponente der medizinischen Diagnostik und ermöglicht es verschiedene Parameter im Blut, Urin, Gewebe
            oder anderen Körperflüssigkeiten zu messen, um Informationen über Krankheiten, Funktionsstörungen oder andere medizinische Zustände zu
            erhalten.
        </Text>
        <Image src={LaborImage} boxShadow={'md'} display={{ base: 'block', md: 'none' }} />
        <Text>
            Es gibt eine Vielzahl von Labortests, die in der Diagnostik eingesetzt werden können, je nach den spezifischen Symptomen, Beschwerden oder
            Verdachtsdiagnosen. Einige der häufigsten Labortests umfassen:
        </Text>
        <OrderedList>
            <Image float={'right'} ml={4} src={LaborImage} w={560} boxShadow={'md'} display={{ base: 'none', md: 'block' }} alt='Labordiagnostik' />
            <ListItem>
                Blutbild: Ein Blutbild gibt Informationen über die Anzahl der roten und weißen Blutkörperchen, die Hämoglobin- und Hämatokritwerte
                sowie die Anzahl der Blutplättchen. Es kann Hinweise auf Anämie, Infektionen oder andere Blutkrankheiten geben.
            </ListItem>
            <ListItem>
                Klinische Chemie: Die klinische Chemie misst verschiedene Substanzen und chemische Parameter im Blut, wie Elektrolyte, Leber- und
                Nierenfunktionstests, Blutzucker- und Cholesterinwerte. Sie kann helfen, den Stoffwechselstatus, die Funktion der Organe und das
                Vorhandensein bestimmter Krankheiten zu beurteilen.
            </ListItem>
            <ListItem>
                Entzündungsmarker: Entzündungsmarker wie C-reaktives Protein (CRP) und Blutsenkungsgeschwindigkeit (BSG) können auf eine aktuelle
                Entzündungsreaktion im Körper hinweisen.
            </ListItem>
            <ListItem>
                Hormontests: Hormontests werden verwendet, um den Hormonspiegel im Blut zu messen und können bei der Diagnose und Überwachung von
                Erkrankungen des endokrinen Systems helfen, wie z. B. Schilddrüsenproblemen oder Hormonstörungen.
            </ListItem>
            <ListItem>
                Infektionsserologie: Serologische Tests werden eingesetzt, um das Vorhandensein von Antikörpern gegen bestimmte Infektionserreger wie
                Viren oder Bakterien nachzuweisen. Sie können bei der Diagnose von Infektionskrankheiten helfen.
            </ListItem>
            <ListItem>
                Tumormarker: Tumormarker sind spezifische Substanzen, die von bestimmten Tumorzellen produziert werden. Der Nachweis von Tumormarkern
                im Blut kann zur Diagnose, Überwachung und Nachsorge von bestimmten Krebserkrankungen verwendet werden.
            </ListItem>
        </OrderedList>
        <Text>
            Diese sind nur einige Beispiele für Labortests, die in der Diagnostik eingesetzt werden können. Die Auswahl der Tests hängt von den
            individuellen Gegebenheiten des Patienten und den vermuteten Erkrankungen ab. Die Ergebnisse der Labortests werden interpretiert und in
            Zusammenhang mit anderen klinischen Informationen des Patienten betrachtet, um eine Diagnose zu stellen oder den Verlauf einer Krankheit
            zu überwachen.
        </Text>
    </Stack>
);
