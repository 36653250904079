import { ACGelenksprengungItem } from './acgelenksprengung.item';
import { AchillessehnenreizungItem } from './achillessehnenreizung.item';
import { AchillessehnenrissItem } from './achillessehnenriss.item';
import { ArthroseItem } from './arthrose.item';
import { BaenderrissItem } from './baenderriss.item';
import { BandscheibenprotrusionItem } from './bandscheibenprotrusion.item';
import { BandscheibenvorfallItem } from './bandscheibenvorfall.item';
import { BizepssehnenrissItem } from './bizepssehnenriss.item';
import { CoxarthroseItem } from './coxarthrose.item';
import { ErmuedungsbruchItem } from './ermuedungsbruch.item';
import { FemoropatellararthroseItem } from './femoropatellararthrose.item';
import { FersenspornItem } from './fersensporn.item';
import { GolferellenbogenItem } from './golferellenbogen.item';
import { GonarthroseItem } from './gonarthrose.item';
import { HalluxRigidusItem } from './halluxrigidus.item';
import { HalluxValgusItem } from './halluxvalgus.item';
import { HammerzeheItem } from './hammerzehe.item';
import { HexenschussItem } from './hexenschuss.item';
import { HohlfussItem } from './hohlfuss.item';
import { ImpingementSyndromItem } from './impingementsyndrom.item';
import { IschialgieItem } from './ischialgie.item';
import { JumpersKneeItem } from './jumpersknee.item';
import { KalkschulterItem } from './kalkschulter.item';
import { KarpaltunnelsyndromItem } from './karpaltunnelsyndrom.item';
import { KnickfussItem } from './knickfuss.item';
import { KnorpelschadenItem } from './knorpelschaden.item';
import { KopfschmerzenItem } from './kopfschmerzen.item';
import { KreuzbandrissItem } from './kreuzbandriss.item';
import { LumboischialgieItem } from './lumboischialgie.item';
import { MeniskusrissItem } from './meniskusriss.item';
import { MigraeneItem } from './migraene.item';
import { MortonNeuromItem } from './mortonneurom.item';
import { MuskelfaserrissItem } from './muskelfaserriss.item';
import { MuskelschmerzenItem } from './muskelschmerzen.item';
import { NackenschmerzenItem } from './nackenschmerzen.item';
import { NervenschmerzenItem } from './nervenschmerzen.item';
import { OmarthroseItem } from './omarthrose.item';
import { OsteochondroseItem } from './osteochondrose.item';
import { OsteoporoseItem } from './osteoporose.item';
import { QuadrizepssehnenreizungItem } from './quadrizepssehnenreizung.item';
import { RheumaItem } from './rheuma.item';
import { RueckenschmerzenItem } from './rueckenschmerzen.item';
import { RunnersKneeItem } from './runnersknee.item';
import { SchleimbeutelentzuendungItem } from './schleimbeutelentzuendung.item';
import { SchnellenderFingerItem } from './schnellenderfinger.item';
import { SchulterluxationItem } from './schulterluxation.item';
import { SchwindelItem } from './schwindel.item';
import { SenkfussItem } from './senkfuss.item';
import { SpinalstenoseItem } from './spinalstenose.item';
import { SpondylarthroseItem } from './spondylarthrose.item';
import { SpondyloseItem } from './spondylose.item';
import { SpreizfussItem } from './spreizfuss.item';
import { TennisellenbogenItem } from './tennisellenbogen.item';
import { TinnitusItem } from './tinnitus.item';
import { VordererKnieschmerzItem } from './vordererknieschmerz.item';
import { WadenkraempfeItem } from './wadenkraempfe.item';
import { WirbelblockadeItem } from './wirbelblockade.item';
import { ZehenfrakturItem } from './zehenfraktur.item';

export interface DiagnoseItem {
    title: string;
    subtitle?: string;
    content: React.ComponentType<any>;
}

export const diagnoseItems: DiagnoseItem[] = [
    { title: 'AC-Gelenksprengung', content: ACGelenksprengungItem },
    { title: 'Achillessehnenreizung / Achillodynie', content: AchillessehnenreizungItem },
    { title: 'Achillessehnenriss', content: AchillessehnenrissItem },
    { title: 'Arthrose', content: ArthroseItem },
    { title: 'Bänderriss', content: BaenderrissItem },
    { title: 'Bandscheibenprotrusion', content: BandscheibenprotrusionItem },
    { title: 'Bandscheibenvorfall', content: BandscheibenvorfallItem },
    { title: 'Bizepssehnenriss', content: BizepssehnenrissItem },
    { title: 'Coxarthrose', subtitle: '(Hüftarthrose)', content: CoxarthroseItem },
    { title: 'Ermüdungsbruch', content: ErmuedungsbruchItem },
    { title: 'Fersensporn / Plantarfasziitis', content: FersenspornItem },
    { title: 'Femoropatellararthrose', content: FemoropatellararthroseItem },
    { title: 'Golferellenbogen', content: GolferellenbogenItem },
    { title: 'Gonarthrose', subtitle: '(Kniearthrose)', content: GonarthroseItem },
    { title: 'Hallux rigidus', content: HalluxRigidusItem },
    { title: 'Hallux valgus', content: HalluxValgusItem },
    { title: 'Hammerzehe', content: HammerzeheItem },
    { title: 'Hexenschuss', content: HexenschussItem },
    { title: 'Hohlfuß', content: HohlfussItem },
    { title: 'Impingement-Syndrom', content: ImpingementSyndromItem },
    { title: 'Ischialgie', content: IschialgieItem, subtitle: '(Ischiasschmerzen)' },
    { title: "Jumper's Knee", content: JumpersKneeItem, subtitle: '(Patellaspitzensyndrom)' },
    { title: 'Kalkschulter', content: KalkschulterItem },
    { title: 'Karpaltunnelsyndrom', content: KarpaltunnelsyndromItem },
    { title: 'Knickfuß', content: KnickfussItem },
    { title: 'Knorpelschaden', content: KnorpelschadenItem },
    { title: 'Kopfschmerzen', content: KopfschmerzenItem },
    { title: 'Kreuzbandriss', content: KreuzbandrissItem },
    { title: 'Lumboischialgie', content: LumboischialgieItem },
    { title: 'Meniskusriss / Meniskusschaden', content: MeniskusrissItem },
    { title: 'Migräne', content: MigraeneItem },
    { title: 'Morton-Neurom', content: MortonNeuromItem },
    { title: 'Muskelfaserriss', content: MuskelfaserrissItem },
    { title: 'Muskelschmerzen', content: MuskelschmerzenItem, subtitle: '(Myalgie)' },
    { title: 'Nackenschmerzen', content: NackenschmerzenItem },
    { title: 'Nervenschmerzen', content: NervenschmerzenItem },
    { title: 'Omarthrose', content: OmarthroseItem, subtitle: '(Schulterarthrose)' },
    { title: 'Osteochondrose', content: OsteochondroseItem },
    { title: 'Osteoporose', content: OsteoporoseItem },
    { title: 'Quadrizepssehnenreizung', content: QuadrizepssehnenreizungItem },
    { title: 'Rheuma', content: RheumaItem },
    { title: 'Rückenschmerzen', content: RueckenschmerzenItem },
    { title: "Runner's Knee / Läuferknie", content: RunnersKneeItem },
    { title: 'Schleimbeutelentzündung', content: SchleimbeutelentzuendungItem, subtitle: '(Bursitis)' },
    { title: 'Schnellender Finger', content: SchnellenderFingerItem },
    { title: 'Schulterluxation', content: SchulterluxationItem },
    { title: 'Schwindel', content: SchwindelItem },
    { title: 'Senkfuß', content: SenkfussItem },
    { title: 'Spinalstenose', content: SpinalstenoseItem, subtitle: '(Spinalkanalstenose)' },
    { title: 'Spondylarthrose', content: SpondylarthroseItem },
    { title: 'Spondylose', content: SpondyloseItem },
    { title: 'Spreizfuß', content: SpreizfussItem },
    { title: 'Tennisellenbogen', content: TennisellenbogenItem },
    { title: 'Tinnitus', content: TinnitusItem },
    { title: 'Vorderer Knieschmerz', content: VordererKnieschmerzItem, subtitle: '(Patellofemorales Schmerzsyndrom)' },
    { title: 'Wadenkrämpfe', content: WadenkraempfeItem },
    { title: 'Wirbelblockade / Wirbelblockierung', content: WirbelblockadeItem },
    { title: 'Zehenfraktur', content: ZehenfrakturItem },
];
