import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const MuskelfaserrissItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Bin einem Muskelfaserriss kommt es zu einer Verletzung von Muskelgewebe. Im Gegensatz zur Muskelzerrung lassen sich hier eine Zerstörung
            von Muskelzellen und eine Einblutung erkennen. Ein Muskelfaserriss tritt in der Regel in Folge einer plötzlichen Überdehnung oder
            Überlastung des betroffenen Muskels auf.
        </Text>
        <Text>Die Symptome eines Muskelfaserrisses unterscheiden sich je nach Schwere der Verletzung:</Text>
        <OrderedList>
            <ListItem>Plötzlicher, stechender Schmerz im betroffenen Muskel.</ListItem>
            <ListItem>Schwellung und Bluterguss im Bereich der Verletzung.</ListItem>
            <ListItem>Einschränkung der Beweglichkeit des betroffenen Muskels oder Gelenks.</ListItem>
            <ListItem>Kraftverlust oder Unfähigkeit den Muskel aktiv einzusetzen.</ListItem>
            <ListItem>Empfindlichkeit oder Druckschmerz im verletzten Bereich.</ListItem>
        </OrderedList>
        <Text>Die Behandlung eines Muskelfaserrisses besteht aus konservativen Maßnahmen zur Symptomlinderung und Heilungsförderung:</Text>
        <OrderedList>
            <ListItem>
                Ruhe und Schonung: Der betroffene Muskel sollte vorübergehend entlastet und geschont werden, um weitere Gewebeschäden zu vermeiden.
            </ListItem>
            <ListItem>
                Kühlung: Die Anwendung von Kälte (z. B. mit einem Eisbeutel) im verletzten Bereich kann Schwellungen und Entzündungen reduzieren.
            </ListItem>
            <ListItem>
                Kompression: Das Anlegen eines Kompressionsverbandes um den verletzten Muskel kann ebenfalls Schwellungen reduzieren und die Heilung
                unterstützen.
            </ListItem>
            <ListItem>
                Hochlagerung: Das Hochlagern des betroffenen Körperteils kann helfen, den Blutfluss zu verbessern und Schwellungen zu reduzieren.
            </ListItem>
            <ListItem>
                Schmerzmittel: Bei Bedarf können Schmerzmittel eingenommen werden, um Schmerzen und Entzündungen zu lindern. Es ist jedoch wichtig,
                die Dosierungsempfehlungen zu befolgen und bei Fragen oder Bedenken einen Arzt aufzusuchen.
            </ListItem>
            <ListItem>
                Stoßwellentherapie: Eine Stoßwellentherapie kann im Verlauf zur Verbesserung der Durchblutungssituation und Stimulation des
                Heilungsprozesses eingesetzt werden.
            </ListItem>
            <ListItem>
                Eigenbluttherapie: Eine Eigenbluttherapie (z. B. ACP) kann zur Förderung der Heilung und zur Entzündungshemmung eingesetzt werden.
                Auch im Leistungssport ist dies möglich, da es sich um ein Substrat handelt, dass aus dem eigenen Blut gewonnen (= autolog), durch
                Zentrifugieren hochkonzentriert und anschließend lokal injiziert wird und somit den Dopingrichtlinien entspricht.
            </ListItem>
            <ListItem>
                Magnetfeldtherapie: Zur Förderung der muskulären Stoffwechselsituation kann ebenfalls der Einsatz einer Magnetfeldtherapie von Vorteil
                für die Heilung und die Schmerzen sein.
            </ListItem>
            <ListItem>
                Physiotherapie: Sobald die akute Phase abgeklungen ist, kann eine Physiotherapie empfohlen werden, um die Muskulatur zu stärken, die
                Beweglichkeit wiederherzustellen und das Risiko weiterer Verletzungen zu verringern.
            </ListItem>
        </OrderedList>
        <Text>
            Bei größeren Muskelverletzungen wie z. B. Muskelrissen oder bei erheblichen Blutergüssen nach Muskelverletzungen kann in Einzelfällen ein
            operatives Vorgehen notwendig sein.
        </Text>
        <Text>
            Falls ein Muskelfaserriss bestehen könnte, sollten Sie den Facharzt für Orthopädie und Unfallchirurgie Ihres Vertrauens aufsuchen. Durch
            eine manuelle Untersuchung und eine geeignete Bildgebung (z. B. eine Ultraschalluntersuchung) kann die genaue Diagnose gestellt und die
            optimale Behandlung eingeleitet werden, um eine optimale Genesung zu ermöglichen und Folgeschäden abzuwenden.
        </Text>
    </Stack>
);
