import * as React from 'react';
import { Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import Wirbel1 from 'images/leistungen/4D-Wirbel1.jpg';
import Wirbel2 from 'images/leistungen/4D-Wirbel2.jpg';

export const WirbelsaeulenvermessungItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die 4D-Wirbelsäulenvermessung beschreibt eine moderne und innovative Methode zur Untersuchung und Analyse der Wirbelsäule. Im Gegensatz
            zur traditionellen 2D- oder 3D-Bildgebung ermöglicht die 4D-Wirbelsäulenvermessung eine dynamische und umfassende Beurteilung der
            Wirbelsäule in Bewegung.
        </Text>
        <Text>
            Die 4D-Wirbelsäulenvermessung basiert auf der Verwendung von fortschrittlicher Bildgebungstechnologie ohne jegliche schädigende Strahlung.
            Insofern ist sie ein äußerst schonendes Verfahren, dass auch bei Kindern oder im Falle der Notwendigkeit von (engmaschigen)
            Verlaufskontrollen unkritisch eingesetzt werden kann. So wird es beispielsweise häufig zur Verlaufsbeurteilung von Skoliosen bei Kindern
            eingesetzt. Während der Untersuchung werden serienweise Bilder der Wirbelsäule in verschiedenen Positionen aufgenommen, um eine
            kontinuierliche Bewegung und Ausrichtung der Wirbel zu erfassen.
        </Text>
        <Image src={Wirbel1} alt='4D-Wirbelsäulenvermessung1' />
        <Text>Die 4D-Wirbelsäulenvermessung bietet mehrere Vorteile:</Text>
        <OrderedList>
            <ListItem>
                Analyse der Wirbelsäule in Bewegung: Durch die Erfassung der Wirbelsäule in verschiedenen Positionen ermöglicht die
                4D-Wirbelsäulenvermessung eine detaillierte Beurteilung der Bewegungsfreiheit, Stabilität und Ausrichtung der Wirbel. Dadurch können
                Probleme wie Wirbelsäuleninstabilitäten, Fehlstellungen oder Abweichungen während der Bewegung erkannt werden.
            </ListItem>
            <ListItem>
                Genaue Vermessung von Wirbelsäulenparametern: Die 4D-Wirbelsäulenvermessung kann präzise Daten über verschiedene Wirbelsäulenparameter
                liefern, einschließlich Wirbelrotation, Wirbelverschiebung, Bandscheibenhöhe und Bandscheibendegeneration. Dadurch können detaillierte
                Diagnosen gestellt und individuelle Behandlungspläne erstellt werden.
            </ListItem>
            <ListItem>
                Bessere Planung von Wirbelsäulenoperationen: Für Patienten, die sich einer Wirbelsäulenoperation unterziehen müssen, kann die
                4D-Wirbelsäulenvermessung wichtige Informationen liefern, um den Eingriff optimal zu planen. Sie ermöglicht es dem Chirurgen, die
                Wirbelsäulenbewegungen und -ausrichtungen im Vorfeld zu analysieren und die Operationstechniken entsprechend anzupassen.
            </ListItem>
        </OrderedList>
        <Image src={Wirbel2} alt='4D-Wirbelsäulenvermessung2' />
        <Text>
            Wenn Sie Interesse an einer 4D-Wirbelsäulenvermessung haben, kontaktieren Sie uns gerne. Wir können Ihre Symptome und medizinische
            Vorgeschichte bewerten und Ihnen weitere Informationen zur Durchführung einer 4D-Wirbelsäulenvermessung geben.
        </Text>
    </Stack>
);
