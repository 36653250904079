import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const HalluxRigidusItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Der Hallux rigidus bezeichnet die Arthrose des Großzehengrundgelenks, bei der es zu einer Steifheit und Einschränkung der Beweglichkeit
            kommt.
        </Text>
        <Text>
            Die genaue Ursache für den Hallux rigidus ist oft nicht eindeutig feststellbar. Es wird jedoch angenommen, dass verschiedene Faktoren dazu
            beitragen können wie z. B.:
        </Text>
        <OrderedList>
            <ListItem>
                Überlastung des Gelenks durch bestimmte Aktivitäten oder Sportarten, die repetitive Bewegungen des Großzehs erfordern.
            </ListItem>
            <ListItem>Erlittene Verletzungen oder Traumata des Großzehengrundgelenks.</ListItem>
            <ListItem>Angeborene Anomalien oder Deformitäten im Bereich des Großzehengelenks.</ListItem>
            <ListItem>Genetische Veranlagung für Arthrose.</ListItem>
        </OrderedList>
        <Text>Die Symptome des Hallux rigidus werden oft bestimmt durch:</Text>
        <OrderedList>
            <ListItem>Schmerzen im Großzehengrundgelenk, die bei Belastung und Bewegung auftreten.</ListItem>
            <ListItem>Steifheit und Einschränkung der Beweglichkeit des Großzehs.</ListItem>
            <ListItem>Schwellungen und Entzündungen im Bereich des Großzehengrundgelenks.</ListItem>
            <ListItem>Bildung von knöchernen Anbauten (Osteophyten) um das Gelenk herum.</ListItem>
            <ListItem>Veränderungen des Gangbilds, um das schmerzhafte Gelenk zu entlasten.</ListItem>
        </OrderedList>
        <Text>
            Die Diagnose des Hallux rigidus erfolgt in der Regel durch eine körperliche Untersuchung des betroffenen Bereichs, eine Bewertung der
            Symptome des Patienten und bildgebende Verfahren wie z. B. Röntgenaufnahmen. Diese können das Ausmaß der Arthrose und etwaige strukturelle
            Veränderungen im Gelenk darstellen.
        </Text>
        <Text>
            Die Behandlung des Hallux rigidus soll die Schmerzen lindern, die Beweglichkeit verbessern und die Funktion des Großzehengrundgelenks
            erhalten. Konservative Behandlungsmethoden können sein:
        </Text>
        <OrderedList>
            <ListItem>Schmerzmedikation und entzündungshemmende Medikamente, um Schmerzen und Entzündungen zu lindern.</ListItem>
            <ListItem>Verwendung von speziellen Schuh-Einlagen oder Schuhen, die den Druck auf das Großzehengrundgelenk reduzieren.</ListItem>
            <ListItem>Physiotherapie, um die umgebende Muskulatur zu stärken und die Beweglichkeit des Gelenks zu verbessern.</ListItem>
            <ListItem>
                Einsatz von orthopädischen Hilfsmitteln wie Zehenspreizern oder Schienen, um die Funktion des Großzehs zu unterstützen und Schmerzen
                zu lindern.
            </ListItem>
            <ListItem>Injektionen von Kortikosteroiden in das Gelenk, um Entzündungen zu reduzieren und die Symptome zu lindern.</ListItem>
            <ListItem>
                Hyaluronsäure-Infiltrationen, um Entzündungen zu reduzieren und die Schmierung und Stoßdämpfung des Gelenks zu verbessern.
            </ListItem>
            <ListItem>Eigenbluttherapie zur Schmerz- und Entzündungshemmung.</ListItem>
            <ListItem>Magnetfeldtherapie zur Schmerzreduktion und Unterstützung der Selbstheilungsprozesse.</ListItem>
            <ListItem>
                Anpassung der Aktivitäten und Vermeidung von belastenden Bewegungen oder Sportarten, die den Gelenkverschleiß weiter fördern könnten.
            </ListItem>
        </OrderedList>
        <Text>
            In wenigen fortgeschrittenen Fällen, in denen konservative Maßnahmen nicht ausreichend sind und der Hallux rigidus die Lebensqualität des
            Patienten erheblich beeinträchtigt, kann eine Operation in Erwägung gezogen werden.
        </Text>
        <Text>
            Um eine genaue Diagnose zu erhalten und die besten Behandlungsmöglichkeiten für den Hallux rigidus zu besprechen ist eine Beratung durch
            den Facharzt für Orthopädie Ihres Vertrauens sinnvoll. Der Behandlungsansatz hängt von der Schwere der Symptome, dem Grad der Arthrose und
            den individuellen Bedürfnissen des Patienten ab.
        </Text>
    </Stack>
);
