import { CloseIcon, InfoIcon } from '@chakra-ui/icons';
import {
    Box,
    chakra,
    Container,
    Heading,
    HStack,
    Icon,
    IconButton,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    shouldForwardProp,
    SimpleGrid,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { AnimatePresence, isValidMotionProp, motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';
import { LeistungItem, leistungenItems } from './items';

export const Leistungen: React.FC = () => {
    return (
        <Stack p={4} spacing={8}>
            <Stack
                spacing={4}
                as={Container}
                maxW={'container.xl'}
                textAlign={'center'}
                bg={{ base: 'transparent', md: useColorModeValue('white', 'gray.900') }}
                p={{ base: 0, md: 4 }}
                boxShadow={{ base: '', md: 'md' }}
            >
                <Heading>Leistungen</Heading>
                <Text fontSize={{ base: 'xx-small', md: 'x-small' }} color='gray.500'>
                    Aus Gründen der Lesbarkeit wird auf die gleichzeitige Verwendung der Sprachformen männlich, weiblich und divers verzichtet.
                    Sämtliche Personenbezeichnungen gelten selbstverständlich gleichermaßen für alle Geschlechter.
                </Text>
            </Stack>

            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                <AnimatePresence>
                    {leistungenItems.map((leistung, index) => (
                        <GridItem key={index} index={index} {...leistung} />
                    ))}
                </AnimatePresence>
            </SimpleGrid>
        </Stack>
    );
};

interface IGridItemProps extends LeistungItem {
    index: number;
}

const GridItem: React.FC<IGridItemProps> = ({ title, subTitle, content, index }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const isInView = useInView(ref, { once: true });

    const { isOpen, onOpen, onClose } = useDisclosure();

    const bgColor = useColorModeValue('white', 'gray.900');
    const iconColor = useColorModeValue('primary.500', 'primary.200');

    const delay = useBreakpointValue({ base: 0, lg: (Math.random() * 1500 + 500) / 1000 });
    const isLarge = useBreakpointValue({ base: false, lg: true });
    const isSmall = useBreakpointValue({ base: true, md: false });

    const ContentComponent = content;

    return (
        <>
            <MotionDiv
                ref={ref}
                display={'flex'}
                gap={'0.5rem'}
                bg={bgColor}
                p={4}
                borderRadius={'md'}
                boxShadow={'md'}
                alignItems={'center'}
                onClick={onOpen}
                initial={{ opacity: 0 }}
                animate={{
                    opacity: isLarge ? 1 : isInView ? 1 : 0,
                    x: isLarge ? 0 : isInView ? 0 : index % 2 === 1 || isSmall ? 50 : -50,
                }}
                //@ts-ignore
                transition={{
                    duration: 1,
                    ease: 'easeInOut',
                    delay,
                }}
            >
                <Box color={iconColor} px={2}>
                    <Icon as={InfoIcon} boxSize={8} />
                </Box>
                <Text fontWeight={600}>{title}</Text>
            </MotionDiv>
            <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
                <ModalOverlay backdropFilter='blur(10px)' />
                <ModalContent>
                    <ModalHeader>
                        <HStack>
                            <Text as='b' flex={1}>
                                {title} {subTitle}
                            </Text>
                            <IconButton size={'sm'} aria-label='Close' icon={<CloseIcon />} colorScheme='primary' onClick={onClose} />
                        </HStack>
                    </ModalHeader>
                    <ModalBody>
                        <ContentComponent />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

const MotionDiv = chakra(motion.div, {
    shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});
