import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const OsteochondroseItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Osteochondrose ist eine degenerative Erkrankung der Wirbelsäule, bei der der Knorpel zwischen den Wirbelkörpern geschädigt ist. Sie
            betrifft in der Regel die Bandscheiben, die als Stoßdämpfer zwischen den Wirbelkörpern fungieren. Die genaue Ursache der Osteochondrose
            ist häufig nicht bekannt, aber es gibt einige Faktoren, die zu ihrer Entwicklung beitragen können:
        </Text>
        <OrderedList>
            <ListItem>
                Alter: Mit zunehmendem Alter nimmt die Elastizität und Belastbarkeit der Bandscheiben ab, was das Risiko einer Osteochondrose erhöht.
            </ListItem>
            <ListItem>
                Überlastung oder wiederholte Überbeanspruchung der Wirbelsäule: Bestimmte Aktivitäten oder Berufe, die eine übermäßige Belastung oder
                wiederholte Überbeanspruchung der Wirbelsäule mit sich bringen, können das Risiko einer Osteochondrose erhöhen.
            </ListItem>
            <ListItem>
                Fehlhaltung: Eine schlechte Körperhaltung, insbesondere beim Sitzen oder Heben schwerer Lasten, kann zu einer übermäßigen Belastung
                der Bandscheiben führen und die Entwicklung von Osteochondrose begünstigen.
            </ListItem>
            <ListItem>
                Genetische Veranlagung: Es wird angenommen, dass bestimmte genetische Faktoren das Risiko für die Entwicklung einer Osteochondrose
                erhöhen können.
            </ListItem>
        </OrderedList>
        <Text>Die Symptome der Osteochondrose können je nach betroffenem Bereich der Wirbelsäule variieren. Häufige Symptome sind:</Text>
        <OrderedList>
            <ListItem>Rückenschmerzen, die sich verschlimmern können, insbesondere bei Bewegung oder längerem Sitzen oder Stehen.</ListItem>
            <ListItem>Steifheit und eingeschränkte Beweglichkeit der Wirbelsäule.</ListItem>
            <ListItem>
                Taubheit- und Kribbelgefühle oder Schwäche in den Armen oder Beinen, wenn die Nervenwurzeln durch die geschädigten Bandscheiben
                beeinträchtigt sind.
            </ListItem>
            <ListItem>Muskelverspannungen im betroffenen Bereich der Wirbelsäule.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung der Osteochondrose soll Schmerzen lindern, die Funktion der Wirbelsäule verbessern und Komplikationen verhindern. Hier sind
            einige mögliche Behandlungsoptionen:
        </Text>
        <OrderedList>
            <ListItem>
                Schmerzlinderung: Schmerzmittel, entzündungshemmende Medikamente oder Muskelrelaxantien können zur Linderung von Rückenschmerzen
                eingesetzt werden.
            </ListItem>
            <ListItem>
                Physiotherapie: Spezielle Übungen und therapeutische Maßnahmen können dazu beitragen, die Muskulatur um die Wirbelsäule zu stärken,
                die Beweglichkeit zu verbessern und die Wirbelsäule zu stabilisieren.
            </ListItem>
            <ListItem>
                Stoßwellentherapie: Eine Stoßwellentherapie kann zur Verbesserung der Durchblutungssituation und Stimulation des Heilungsprozesses
                eingesetzt werden.
            </ListItem>
            <ListItem>
                Magnetfeldtherapie: Zur Förderung der muskulären Stoffwechselsituation kann ebenfalls der Einsatz einer Magnetfeldtherapie von Vorteil
                für die Heilung und die Schmerzen sein.
            </ListItem>
            <ListItem>
                Haltungskorrektur: Die Verbesserung der Körperhaltung und die Anpassung von Gewohnheiten, die zu einer Überlastung der Wirbelsäule
                führen, können helfen, den Zustand zu verbessern.
            </ListItem>
            <ListItem>
                Wärme- oder Kältetherapie: Die Anwendung von Wärme oder Kälte im betroffenen Bereich kann zur Schmerzlinderung beitragen.
            </ListItem>
            <ListItem>
                Operation: In Einzelfällen kann eine Operation in Erwägung gezogen werden, insbesondere falls neurologische Symptome vorliegen oder
                alle konservativen Maßnahmen keine ausreichende Linderung bieten.
            </ListItem>
        </OrderedList>
        <Text>
            Falls Sie Symptome einer Osteochondrose haben, ist es immer sinnvoll diese bei dem Facharzt für Orthopädie Ihres Vertrauens abklären zu
            lassen, um eine genaue Diagnose zu erhalten und gemeinsam den für Sie optimalen, individuellen Behandlungsplan zu erstellen.
        </Text>
    </Stack>
);
