import * as React from 'react';
import { Stack, Text } from '@chakra-ui/react';

export const PraeventionItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>„Der gute Arzt behandelt die Krankheit, der bessere Arzt verhindert sie.“</Text>
        <Text>
            Unser Auto fahren wir regelmäßig zum Service und zum „TÜV“. Wir wollen bereits im Voraus Verschleiß entgegenwirken und Schäden vermeiden.
            Warum also sollten wir unseren eigenen Körper schlechter behandeln als unser Auto?
        </Text>
        <Text>
            Ziel des orthopädischen Check-Up ist es insbesondere muskuläre Fehlbelastungen und / oder Fehlstellungen zu erkennen, Ursachen zu
            analysieren und Ihnen spezifische Therapievorschläge und Verhaltensempfehlungen zu geben.
        </Text>
        <Text>
            Unseren Check-Up schneiden wir individuell auf Ihre persönlichen Bedürfnisse zu. Mit unserem speziell entwickelten Orthopädie Check-Up und
            einer erstklassigen medizinischen Prävention bieten wir ein passendes Angebot für Patienten, Freizeit- und Leistungssportler mit höchsten
            Ansprüchen.
        </Text>
        <Text>
            Nach den Untersuchungen besprechen wir mit Ihnen in einem umfassenden Abschlussgespräch die medizinischen Befunde sowie Ihre persönliche
            Lebenssituation. Anhand dessen entwickeln wir gemeinsam ein individuelles und realistisches Programm mit gesundheitsfördernden Maßnahmen
            für eine gesunde Lebensführung. Wir bleiben auch bei einer notwendigen weiterführenden Betreuung ihr Ansprechpartner.
        </Text>
        <Text>Haben wir Ihr Interesse geweckt? Zögern Sie nicht uns anzusprechen - wir beraten Sie gerne.</Text>
    </Stack>
);
