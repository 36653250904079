import * as React from 'react';
import { Stack, Text } from '@chakra-ui/react';

export const HausbesuchItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Falls Sie keine Möglichkeit haben in unsere Praxis zu kommen, bieten wir nach vorheriger Vereinbarung auch Hausbesuche an und kommen zu
            Ihnen!
        </Text>
    </Stack>
);
