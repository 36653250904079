import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const KarpaltunnelsyndromItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Das Karpaltunnelsyndrom ist eine Erkrankung, die durch die Kompression des mittleren Handnervs (Nervus medianus) im Bereich des
            Handgelenks verursacht wird. Der Nerv verläuft durch einen engen Kanal, den Karpaltunnel, der von Handwurzelknochen und einem
            bindegewebigen Band gebildet wird. Wenn der Druck im Karpaltunnel erhöht wird, kann der Nerv zusammengedrückt werden, was zu verschiedenen
            Symptomen führt.
        </Text>
        <Text>Es gibt verschiedene Faktoren, die das Risiko für ein Karpaltunnelsyndrom erhöhen können:</Text>
        <OrderedList>
            <ListItem>
                Handgelenksverletzungen: Frakturen, Verstauchungen oder andere Verletzungen des Handgelenks können den Druck auf den Nerv erhöhen.
            </ListItem>
            <ListItem>
                Wiederholte Handbewegungen: Berufliche oder sportliche Aktivitäten, die repetitive Handbewegungen erfordern, wie zum Beispiel das
                Bedienen von Tastaturen, können zu Überlastung und Entzündungen im Karpaltunnel führen.
            </ListItem>
            <ListItem>
                Hormonelle Veränderungen: Hormonelle Veränderungen während der Schwangerschaft oder in den Wechseljahren können zu einer erhöhten
                Flüssigkeitsansammlung im Karpaltunnel führen und den Druck auf den Nerv erhöhen.
            </ListItem>
            <ListItem>
                Erkrankungen: Diabetes mellitus, rheumatoide Arthritis, eine Schilddrüsenunterfunktion und andere Erkrankungen können das Risiko für
                das Auftreten eines Karpaltunnelsyndroms erhöhen.
            </ListItem>
            <ListItem>Veranlagung: Eine familiäre Veranlagung kann das Risiko für die Entwicklung eines Karpaltunnelsyndroms erhöhen.</ListItem>
        </OrderedList>
        <Text>Die Symptome des Karpaltunnelsyndroms können verschieden sein:</Text>
        <OrderedList>
            <ListItem>Taubheits-, Kribbelgefühle oder brennende Schmerzen in Daumen, Zeige-, Mittel- und Ringfinger.</ListItem>
            <ListItem>Muskelschwäche der Hand und Schwierigkeiten mit der Feinmotorik.</ListItem>
            <ListItem>Schmerzen, die in den Unterarm und bis zum Ellenbogen ausstrahlen können.</ListItem>
            <ListItem>Verlust der Geschicklichkeit und Koordination in der betroffenen Hand.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung des Karpaltunnelsyndroms kann konservativ oder operativ sein, abhängig von der Schwere der Symptome und dem Ansprechen auf
            konservative Maßnahmen. Zu den konservativen Behandlungsmöglichkeiten gehören:
        </Text>
        <OrderedList>
            <ListItem>
                Handgelenksbandagen oder Schienen: Das Tragen einer stabilisierenden Schiene während der Nacht oder bei Aktivitäten, die die Symptome
                verschlimmern, kann den Druck im Karpaltunnel reduzieren.
            </ListItem>
            <ListItem>
                Medikamente: Entzündungshemmende Medikamente oder Kortikosteroide können zur Linderung von Schmerzen und Entzündungen eingesetzt
                werden.
            </ListItem>
            <ListItem>
                Physiotherapie: Spezielle Übungen zur Stärkung der Hand- und Unterarmmuskulatur sowie zur Verbesserung der Beweglichkeit können
                helfen.
            </ListItem>
            <ListItem>
                Ergonomische Anpassungen: Anpassungen am Arbeitsplatz oder bei anderen Aktivitäten, um Überlastung und wiederholte Handbewegungen zu
                reduzieren.
            </ListItem>
            <ListItem>Injektionen: Kortikosteroid-Injektionen können vorübergehend Entzündungen und Schmerzen lindern.</ListItem>
            <ListItem>Infusionen: Schmerzstillende und entzündungshemmende Infusionen können sinnvoll sein.</ListItem>
        </OrderedList>
        <Text>
            Wenn konservative Maßnahmen nicht ausreichend wirksam sind oder das Karpaltunnelsyndrom schwerwiegende Symptome verursacht, kann eine
            Operation in Betracht gezogen werden. Dabei wird der Druck auf den Nerv im Karpaltunnel durch Entlastung des Gewebes im Bereich des
            Handgelenks reduziert.
        </Text>
        <Text>
            Es ist wichtig, das Karpaltunnelsyndrom frühzeitig zu erkennen und behandeln zu lassen, um langfristige Schäden zu vermeiden. Eine genaue
            Diagnose und ein individuelles Behandlungskonzept durch den Facharzt für Orthopädie Ihres Vertrauens sind empfehlenswert.
        </Text>
    </Stack>
);
