import * as React from 'react';
import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';

export const ChirotherapieItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Chirotherapie, auch manuelle Medizin oder manuelle Therapie genannt, ist ein Bereich der Medizin, der sich mit der Diagnose,
            Behandlung und Prävention von Funktionsstörungen des Bewegungsapparates beschäftigt. Der Schwerpunkt liegt auf der Untersuchung und
            Behandlung von Störungen im Bereich der Wirbelsäule, der Gelenke und der Muskulatur.
        </Text>
        <Text>
            Chirotherapeuten verwenden spezifische manuelle Techniken, um Funktionsstörungen zu korrigieren und die normale Beweglichkeit und Funktion
            der Gelenke und der Wirbelsäule wiederherzustellen. Dazu gehören unter anderem:
        </Text>
        <OrderedList>
            <ListItem>Mobilisation: Durch sanfte Bewegungen und Dehnungen der Gelenke wird deren Beweglichkeit verbessert.</ListItem>
            <ListItem>
                Manipulation: Dabei werden schnelle, kontrollierte Bewegungen an bestimmten Gelenken durchgeführt, um Blockaden zu lösen und die
                Beweglichkeit zu verbessern. Manipulationen werden häufig an der Wirbelsäule durchgeführt und werden auch als "Einrenken" bezeichnet.
            </ListItem>
            <ListItem>
                Weichteiltechniken: Durch spezielle Massagetechniken und Dehnungen werden Verspannungen und Verhärtungen in der Muskulatur gelöst.
            </ListItem>
            <ListItem>
                Stabilisierung und Kräftigung: Chirotherapeuten können auch Übungen zur Stärkung der Muskulatur und zur Verbesserung der Stabilität
                der Gelenke empfehlen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Chirotherapie wird häufig bei Erkrankungen des Bewegungsapparates eingesetzt, wie z.B. Rückenschmerzen, Nackenschmerzen,
            Gelenkschmerzen, Muskelverspannungen und eingeschränkter Beweglichkeit. Sie kann sowohl zur akuten Schmerzlinderung als auch zur
            langfristigen Verbesserung der Funktion eingesetzt werden.
        </Text>
        <Text>
            Es ist wichtig zu beachten, dass die Chirotherapie eine spezialisierte medizinische Technik ist und von qualifizierten und erfahrenen
            Chirotherapeuten durchgeführt werden sollte. Eine gründliche Untersuchung und Anamnese sind vor der Behandlung erforderlich, um eine
            genaue Diagnose zu stellen und individuell angepasste Behandlungspläne zu erstellen.
        </Text>
        <Text>
            Es ist auch wichtig zu wissen, dass die Chirotherapie nicht für alle Patienten geeignet ist. Es gibt bestimmte Kontraindikationen und
            Risiken, insbesondere bei bestimmten Erkrankungen oder bei Vorliegen von Wirbelsäulenerkrankungen wie Bandscheibenvorfällen oder
            Osteoporose. Vor einer Chirotherapie-Behandlung ist es ratsam, mit einem qualifizierten Facharzt zu sprechen, um zu entscheiden, ob diese
            Behandlungsmethode für Sie geeignet ist.
        </Text>
    </Stack>
);
