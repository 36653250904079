import { InfoIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, Link, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { DoctolibButton, EmailButton, MobileButton } from 'components/links';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import mainImageDesktop from 'images/IMG_2030_square.png';
import mainImageMobile from 'images/_W9A8192_grau.JPG';
import './side-menu.scss';
import { PhoneButton } from 'components/links/Phone';

interface ISideMenuProps {
    isOpen: boolean;
    onToggle: () => void;
}

export const SideMenu = React.forwardRef<HTMLDivElement, ISideMenuProps>((props, ref) => {
    const { isOpen, onToggle } = props;
    const mainColor = useColorModeValue('primary.500', 'primary.200');

    const handleLinkClick = () => {
        if (isOpen) {
            onToggle();
        }
    };

    return (
        <Box
            ref={ref}
            position={'fixed'}
            right={0}
            mt={2}
            p={{ base: 2, md: 4 }}
            w={{ base: 250, md: 300 }}
            bg={mainColor}
            borderLeftRadius={'xl'}
            transform={`translateX(${isOpen ? '0' : '100%'})`}
            transition='transform 0.5s ease-in-out'
            zIndex={99}
            boxShadow={'md'}
        >
            <VStack
                bg={useColorModeValue('white', 'gray.900')}
                p={{ base: 2, md: 4 }}
                borderRadius={'xl'}
                textAlign={'center'}
                spacing={{ base: 2, md: 4 }}
            >
                <Avatar display={['inline-flex', null, 'none']} size={'md'} name='Dr. Jan Festring' src={mainImageMobile} />
                <Avatar display={['none', null, 'inline-flex']} size={'lg'} name='Dr. Jan Festring' src={mainImageDesktop} bg='#b7c6c1' />
                <Text fontSize={{ base: 'md', md: '2xl' }}>Dr. Jan Festring</Text>
                <PhoneButton w={'100%'} />
                <MobileButton w={'100%'} />
                <EmailButton w={'100%'} />
                <Link as={RouterLink} w={'100%'} to='kontakt'>
                    <Button
                        size={{ base: 'sm', md: 'md' }}
                        leftIcon={<InfoIcon color={mainColor} />}
                        w={'100%'}
                        variant={'outline'}
                        colorScheme='primary'
                        onClick={handleLinkClick}
                    >
                        Anfahrt
                    </Button>
                </Link>
                <DoctolibButton w={'100%'} />
            </VStack>
        </Box>
    );
});
