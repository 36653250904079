import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const GolferellenbogenItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Der Golferellenbogen, auch als Epicondylitis ulnaris humeri oder Epicondylitis medialis humeri bekannt, ist eine schmerzhafte Erkrankung,
            die durch Entzündung oder Reizung der Sehnenansätze an der Innenseite des Ellenbogens verursacht wird. Der Name weist zwar auf den
            Golfsport hin, jedoch kann der Golferellenbogen bei jeder Person auftreten, die repetitive Hand- und Handgelenksbewegungen ausführt.
        </Text>
        <Text>
            Die Hauptursache für den Golferellenbogen ist die Überbeanspruchung der Sehnen, die den Unterarm beugen und die Handgelenksbewegungen
            kontrollieren. Diese Überlastung kann durch Aktivitäten wie Golfschwünge, Tennisaufschläge, Wurf- oder Schlagbewegungen, aber auch durch
            andere repetitive Tätigkeiten wie das Heben von Gewichten, die Verwendung von Werkzeugen oder das Tippen auf einer Tastatur verursacht
            werden.
        </Text>
        <Text>
            Die Symptome des Golferellenbogens umfassen Schmerzen und Druckempfindlichkeit an der Innenseite des Ellenbogens, die sich oft bis zum
            Unterarm oder bis ins Handgelenk erstrecken können. Die Schmerzen können bei greifenden, beugenden oder drehenden Bewegungen des
            Handgelenks oder der Finger auftreten. In einigen Fällen kann es zu einer Schwäche oder Einschränkung der Handfunktion kommen.
        </Text>
        <Text>
            Die Diagnose eines Golferellenbogens erfolgt in der Regel anhand der Symptome des Patienten und einer körperlichen Untersuchung des
            Ellenbogens. In einigen Fällen können bildgebende Verfahren wie Röntgenaufnahmen oder eine Magnetresonanztomographie (MRT) durchgeführt
            werden, um andere mögliche Ursachen der Symptome auszuschließen.
        </Text>
        <Text>
            Die Behandlung des Golferellenbogens zielt darauf ab, Schmerzen zu lindern, Entzündungen zu reduzieren und die Heilung zu fördern. Zu den
            konservativen Behandlungsmethoden gehören:
        </Text>
        <OrderedList>
            <ListItem>Ruhe und Vermeidung von Aktivitäten, die den Ellenbogen belasten.</ListItem>
            <ListItem>Kühlung des betroffenen Bereichs, um Schmerzen und die Entzündung zu lindern.</ListItem>
            <ListItem>Verwendung von Schienen oder Bandagen, um den Ellenbogen zu stabilisieren und die Belastung der Sehnen zu verringern.</ListItem>
            <ListItem>Eigenbluttherapie oder andere Infiltrationsbehandlungen zur Schmerz- und Entzündungshemmung.</ListItem>
            <ListItem>Akupunktur zur Schmerz- und Entzündungshemmung.</ListItem>
            <ListItem>
                Stoßwellentherapie zur Verbesserung der Durchblutung, Stimulation von Stoffwechselprozessen und Förderung der Selbstheilung.
            </ListItem>
            <ListItem>Magnetfeldtherapie zur Schmerzreduktion und Unterstützung der Selbstheilungsprozesse.</ListItem>
            <ListItem>
                Physiotherapie, um die umgebende Muskulatur zu stärken, die Beweglichkeit zu verbessern und Techniken zur Schmerzlinderung zu
                erlernen.
            </ListItem>
            <ListItem>Entzündungshemmende Medikamente können zur Schmerzlinderung eingesetzt werden.</ListItem>
            <ListItem>
                In einigen Fällen kann eine Injektion von Kortikosteroiden in den Sehnenansatz erwogen werden, um Entzündungen zu reduzieren.
            </ListItem>
            <ListItem>
                Exzentrisches Training, das spezielle Übungen umfasst, um die Sehnen zu stärken und die Belastung des Ellenbogens zu reduzieren.
            </ListItem>
        </OrderedList>
        <Text>
            Eine frühzeitige Diagnosestellung und Behandlung kann dazu beitragen, die Symptome effizient zu lindern und eine (schnellere) Genesung zu
            ermöglichen.
        </Text>
    </Stack>
);
