import { AspectRatio, Card, CardBody, CardHeader, Heading, Spacer, Stack, Text, chakra, useColorModeValue } from '@chakra-ui/react';
import { DoctolibButton, EmailButton, MobileButton } from 'components/links';
import { Jameda } from 'components/Jameda';
import React from 'react';
import { PhoneButton } from 'components/links/Phone';

const w = 350;

export const Kontakt: React.FC = () => {
    return (
        <Stack spacing={4}>
            <Text color='secondary.500' fontSize={'2xl'}>
                Praxis für Orthopädie & Sportmedizin | Dr. Festring
            </Text>
            <Text>
                <Text>Uerdinger Straße 74</Text>
                <Text>40668 Meerbusch (Lank-Latum)</Text>
            </Text>
            <DoctolibButton w={w} />
            <PhoneButton w={w} />
            <MobileButton w={w} />
            <EmailButton w={w} />
            <Spacer />
            <Jameda width={w} />
            <Card w={'100%'}>
                <CardHeader>
                    <Heading fontFamily={'Roboto'}>Anfahrt</Heading>
                </CardHeader>
                <CardBody pt={0}>
                    <AspectRatio ratio={4 / 3}>
                        <chakra.iframe
                            title='Praxis Dr. Festring'
                            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2494.1653736008834!2d6.672823376920935!3d51.308081725244286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8b7601ea15a9d%3A0x86567c932db7e4c6!2sORTHOP%C3%84DIE%20DR.%20FESTRING%20%7C%20Privatpraxis%20f%C3%BCr%20Orthop%C3%A4die%20%26%20Sportmedizin%20%7C%20Dr.%20med.%20Jan%20Festring%20%7C%20Meerbusch%20Lank!5e0!3m2!1sde!2sde!4v1696507275474!5m2!1sde!2sde'
                            style={{ filter: useColorModeValue('invert(0%)', 'invert(100%)') }}
                            loading='lazy'
                        />
                    </AspectRatio>
                </CardBody>
            </Card>
        </Stack>
    );
};
