import { CheckIcon } from '@chakra-ui/icons';
import { Text, VStack, Button, SimpleGrid, List, ListIcon, useColorModeValue, ListItem, Stack, VisuallyHidden } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { HyphenText } from 'components/controls';
import { OpeningHours } from 'components/main/OpeningHours';
import { Doctolib, DoctolibButton } from 'components/links/Doctolib';
import { EmailButton, MobileButton } from 'components/links';
import { PhoneButton } from 'components/links/Phone';

export const Home: React.FC = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <VStack spacing={{ base: 3, md: 6 }}>
            <VStack
                spacing={{ base: 3, md: 6 }}
                bgGradient='linear(to-tr, primary.600, primary.400)'
                p={8}
                boxShadow={'md'}
                borderRadius={'md'}
                textAlign={'center'}
                w={'100%'}
            >
                <Text
                    bg={'secondary.500'}
                    boxShadow={'md'}
                    p={2}
                    borderRadius={'md'}
                    fontSize={{ base: 'xl', md: '42px' }}
                    textShadow={'1px 1px 2px white'}
                >
                    Praxis für Orthopädie & Sportmedizin | Dr. Festring
                </Text>
                <Text color={'white'} fontSize={{ base: 'md', md: '2xl' }}>
                    - Privatpatienten und gesetzlich versicherte Patienten als Selbstzahler -
                </Text>
                <VStack spacing={0}>
                    <Text color='white' fontSize={{ base: 'md', md: 'lg' }}>
                        Uerdinger Straße 74
                    </Text>
                    <Text color='white' fontSize={{ base: 'md', md: 'lg' }}>
                        40668 Meerbusch (Lank-Latum)
                    </Text>
                </VStack>
                <SimpleGrid gap={{ base: 2, md: 8 }} columns={{ base: 1, md: 4 }}>
                    <PhoneButton w={'100%'} variant={'solid'} colorScheme={'secondary'} />
                    <MobileButton w={'100%'} variant={'solid'} colorScheme={'secondary'} />
                    <EmailButton w={'100%'} variant={'solid'} colorScheme={'secondary'} />
                    <DoctolibButton w={'100%'} variant={'solid'} colorScheme={'secondary'} />
                </SimpleGrid>
            </VStack>
            <Stack w={'100%'} bg={useColorModeValue('white', 'gray.800')} boxShadow={'md'} borderRadius={'md'} p={6}>
                <Stack>
                    <Text textTransform={'uppercase'} color='secondary.500' fontSize={'xl'}>
                        Zeit für eine ganzheitliche Perspektive - Denn Ihre Gesundheit ist unser Ziel
                    </Text>
                    <HyphenText textAlign={'justify'}>
                        Liebe Patientinnen und Patienten, herzlich willkommen auf der Website unserer Praxis!
                    </HyphenText>
                    <HyphenText textAlign={'justify'}>
                        Wir folgen einem ganzheitlichen orthopädischen Ansatz, der anatomische, funktionelle und andere Einflüsse gleichermaßen
                        berücksichtigt. Am Beginn steht immer eine ausführliche Anamnese mit einer umfassenden Befragung zu Ihren Lebensumständen. Zur
                        ursachenorientierten Diagnostik ziehen wir zunächst eine ausführliche körperliche Untersuchung heran. Auch bildgebende
                        Verfahren wie z. B. Ultraschall oder andere modernste Diagnostikmethoden können notwendig sein. Unter Berücksichtigung aller
                        Aspekte entsteht Ihr individuelles Behandlungskonzept.
                    </HyphenText>
                    <HyphenText textAlign={'justify'}>
                        Dabei nehmen wir uns viel Zeit für Sie. Wir hören zu, hinterfragen und geben Ihnen Antworten. Es sollen keine Fragen offen
                        bleiben. Ihre Befunde, Diagnosen und deren Behandlungsmöglichkeiten besprechen wir in aller Ruhe mit Ihnen, sodass Sie sich zu
                        jeder Zeit rundum wohl und gut aufgehoben fühlen.
                    </HyphenText>
                    <HyphenText textAlign={'justify'}>
                        Auch nach Ihrer Genesung stehen wir Ihnen jederzeit vertrauensvoll zur Seite. Im Rahmen unseres Nachhaltigkeitskonzepts
                        stellen wir durch nachgelagerte Check-Up's den langfristigen Behandlungserfolg sicher und betreiben zusammen mit Ihnen eine
                        aktive Prävention, damit Sie auch langfristig gesund bleiben.
                    </HyphenText>
                    <Text textAlign={'justify'}>Wir freuen uns auf Ihren Besuch!</Text>
                    <Text fontFamily={'Dancing Script'} fontWeight={500} fontSize={{ base: '2xl', md: '4xl' }}>
                        Ihr Dr. med. Jan Festring & Team
                    </Text>
                </Stack>

                <Stack>
                    <Text textTransform={'uppercase'} color='secondary.500' fontSize={'xl'} mt={2}>
                        Exzellente Privatmedizin für alle - Denn Ihre Gesundheit ist das Wichtigste
                    </Text>
                    <HyphenText textAlign={'justify'}>
                        Wir unterscheiden nicht zwischen Privat- und Kassenpatient:innen. Unsere gesetzlich versicherten Patienten sind ebenso
                        herzlich willkommen wie unsere Privatpatienten. Unser Angebot richtet sich an alle Patientinnen und Patienten, die bereit
                        sind, etwas für ihre Gesundheit zu tun und neue Wege in der Medizin gehen wollen - für mehr Wohlbefinden, Beweglichkeit,
                        Leistung und Lebensqualität bis ins hohe Alter.
                    </HyphenText>
                    <HyphenText textAlign={'justify'}>
                        Wir geben unser Geld für Urlaub, Kleidung oder Restaurantbesuche aus. Warum also investieren wir nicht auch einen Teil in das
                        Wichtigste, das wir besitzen, in unsere Gesundheit? Der finanzielle Aufwand für privatärztliche Behandlungen ist durchaus
                        überschaubar und wahrscheinlich deutlich geringer, als die meisten von uns vermuten.
                    </HyphenText>
                    <HyphenText textAlign={'justify'}>
                        Profitieren Sie von individueller, empathischer Medizin, genug Arztzeit und einem maßgeschneiderten, lösungsorientierten
                        Behandlungskonzept, modernster Gerätediagnostik und schonenden und zugleich hocheffektiven Therapieverfahren. Wir bieten Ihnen
                        herzlichen Service, schnelle Terminvergaben und kaum Wartezeiten.
                    </HyphenText>
                    <HyphenText textAlign={'justify'}>
                        Damit Sie von den Kosten nicht überrascht werden, haben wir hier eine beispielhafte Honorarübersicht für Sie zusammengestellt.
                        Das Honorar errechnet sich auf Grundlage der Gebührenordnung für Ärzte (GOÄ). Bitte beachten Sie, dass es sich um Richtwerte
                        handelt, die für unsere gesetzlich versicherten Selbstzahler gelten. Kontaktieren Sie uns gerne.
                    </HyphenText>
                    <List spacing={2} ml={{ base: 2, md: 4 }} my={2}>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Beratung mit Untersuchung: ab ca. 30 € (ca. 15 Min.)
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Hyaluronsäure-Gelenkinjektion: ab ca. 73 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Eigenbluttherapie: pro Termin ab ca. 184 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Stoßwellentherapie (fokussiert): pro Termin ab ca. 86 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Magnetfeldtherapie / Induktionstherapie: pro Termin ab ca. 57 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Hochenergie-Lasertherapie: pro Termin ab ca. 46 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            4D-Wirbelsäulenvermessung (strahlenfrei): ab ca. 125 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Ganganalyse (Beinachsenvermessung + Fußdruckmessung): ab ca. 80 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Fußdruckmessung: ab ca. 41 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Bewegungsanalyse (strahlenfreie 4D-Wirbelsäulenvermessung + Ganganalyse): ab ca. 184 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Bewegungsanalyse (strahlenfreie 4D-Wirbelsäulenvermessung + Ganganalyse + Muskelfunktionsdiagnostik): ab ca. 259 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Muskelfunktionsdiagnostik: ab ca. 75 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Elektromyographie (EMG) mit Biofeedback: ab ca. 52 €
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckIcon} color={'primary.500'} />
                            Injektion: ab ca. 34 €
                        </ListItem>
                    </List>
                    <Doctolib>
                        <Button w={'100%'} colorScheme='primary' variant={'solid'} display={{ base: 'none', md: 'inline-flex' }}>
                            Buchen Sie jetzt Ihren Termin - ganz nach Wunsch !
                        </Button>
                    </Doctolib>
                    <Doctolib>
                        <Button w={'100%'} size={'sm'} colorScheme='primary' variant={'solid'} display={{ base: 'inline-flex', md: 'none' }}>
                            Jetzt Termin nach Wunsch buchen
                        </Button>
                    </Doctolib>
                </Stack>
            </Stack>
            <OpeningHours />
            <VisuallyHidden>
                Arthrosetherapie, Knorpeltherapie, Sehnentherapie, interventionelle Schmerztherapie, Schmerzen, Rückenschmerzen, Diagnose, Therapie,
                Arthrose, Arthrosespezialist, Arthrose-Spezialist, Hyaluron, Hyaluronsäure, Beschwerden, Computertomographie, CT, Kernspintomographie,
                Magnetresonanztomographie, MRT, Mikrotherapie, Osteoporose, Düsseldorf, Meerbusch, Orthopäde, Büderich, Lank, Lank-Latum,
                Langst-Kierst, Osterath, Strümp, Nierst, Ilverich, Ossum, Bösinghoven, Willich, Krefeld, Kaarst, Neuss, Wirbelsäule, Knie, Rücken,
                Ischialgie, Lumboischialgie, Nackenschmerzen, Operation, Zweitmeinung, Spondylarthrose, Osteochondrose, Knorpelschaden,
                Bandscheibenvorfall, Bandscheibenprotrusion, Protrusion, Bandscheibenvorwölbung, Beinschmerzen, Muskelschmerzen, Nervenschmerzen,
                Spinalkanalstenose, Kalkschulter, Fersensporn, Gelenkschmerzen, Nackenschmerzen, Wirbelsäulenvermessung, Haltungsanalyse,
                Bewegungsanalyse, Ganganalyse, PRT, Facetteninfiltration, fokussierte Stoßwelle, Stoßwellentherapie, konservativ, konservative
                Therapie, Ischias, Spritze, Eigenbluttherapie, ACP-Therapie, PRP-Therapie, Spezialist
            </VisuallyHidden>
        </VStack>
    );
};
