import { Box, chakra } from '@chakra-ui/react';
import React, { useEffect } from 'react';

interface IProps {
    width?: number;
}

export const Jameda: React.FC<IProps> = (props) => {
    const { width = 378 } = props;

    useEffect(() => {
        const loadScript = () => {
            const $_document = document;
            const _script = 'script';
            const id = 'zl-widget-s';

            var js,
                fjs = $_document.getElementsByTagName(_script)[0];

            if (!fjs) {
                console.error('No fjs');
                return;
            }

            if (!$_document.getElementById(id)) {
                console.log('Creating');
                js = $_document.createElement(_script);
                js.id = id;
                js.src = '//platform.docplanner.com/js/widget.js';
                fjs.parentNode?.insertBefore(js, fjs);
            }
        };

        loadScript();
    }, []);

    return (
        <Box width={width}>
            <chakra.a
                id='zl-url'
                className='zl-url'
                href='https://www.jameda.de/jan-festring/orthopaede-unfallchirurg-akupunkteur-sportmediziner/krefeld'
                rel='nofollow'
                data-zlw-doctor='jan-festring'
                data-zlw-type='big'
                data-zlw-opinion='false'
                data-zlw-hide-branding='true'
                data-zlw-saas-only='false'
            >
                Jan Festring - jameda.de
            </chakra.a>
        </Box>
    );
};
