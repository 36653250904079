import { List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { EmailLink } from 'components/links';
import React from 'react';
import { MdFace } from 'react-icons/md';

export const Jobs: React.FC = () => {
    return (
        <>
            <Text fontSize='xl'>Wir suchen:</Text>
            <List spacing={4} my={6}>
                <ListItem>
                    <ListIcon as={MdFace} color='primary.500' boxSize={6} />
                    Medizinische Fachangestellte in Teilzeit/Vollzeit (m/w/d)
                </ListItem>
                <ListItem>
                    <ListIcon as={MdFace} color='primary.500' boxSize={6} />
                    Reinigungskraft auf Stundenbasis (m/w/d)
                </ListItem>
            </List>
            <Text>
                Bitte senden Sie uns Ihre Bewerbung und Ihren Lebenslauf. Gerne per E-Mail an: <EmailLink />
            </Text>
        </>
    );
};
