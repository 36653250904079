import { Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import HeLaserImage from 'images/leistungen/Laser.jpg';

export const HochenergieLasertherapieItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>Hochenergie-Laser werden in verschiedenen medizinischen Bereichen erfolgreich eingesetzt, darunter auch in der Orthopädie.</Text>
        <Image src={HeLaserImage} w={'100%'} boxShadow={'md'} display={{ base: 'block', md: 'none' }} />
        <Text>
            Die Lasertherapie kann zur Behandlung von verschiedenen Erkrankungen und Beschwerden verwendet werden. Hier sind einige Beispiele:
        </Text>
        <OrderedList>
            <Image float={'right'} src={HeLaserImage} w={415} ml={4} boxShadow={'md'} display={{ base: 'none', md: 'block' }} />
            <ListItem>
                Schmerzlinderung bei Arthrose: Hochenergie-Laser können zur Schmerzlinderung bei Patienten mit Arthrose eingesetzt werden. Die
                Laserstrahlung kann Entzündungen reduzieren und die Regeneration von geschädigtem Gewebe fördern.
            </ListItem>
            <ListItem>
                Behandlung von Sehnen- und Bänderverletzungen: Bei Verletzungen von Sehnen und Bändern kann die Hochenergie-Lasertherapie helfen, die
                Heilung zu beschleunigen. Der Laser fördert die Zellregeneration und verbessert die Durchblutung, was zu einer schnelleren Ausheilung
                beitragen kann.
            </ListItem>
            <ListItem>
                Entzündungshemmung bei Gelenkerkrankungen: Hochenergie-Laser können entzündungshemmende Effekte haben, was besonders bei entzündlichen
                Gelenkerkrankungen wie rheumatoider Arthritis (Rheuma) von Vorteil sein kann.
            </ListItem>
            <ListItem>
                Narbenbehandlung nach Operationen: Der Hochenenergie-Laser kann zur Behandlung von Narben nach Operationen eingesetzt werden. Dies
                kann helfen, die Flexibilität des Gewebes zu verbessern und postoperative Beschwerden zu reduzieren.
            </ListItem>
            <ListItem>
                Beschleunigung der Knochenheilung: Die Hochenergie-Lasertherapie wird auch zur Förderung der Heilung von Knochenbrüchen eingesetzt.
                Der Laser kann das Zellwachstum und damit die Bildung von neuem Knochengewebe fördern.
            </ListItem>
        </OrderedList>
        <Text>
            Ob der Einsatz der Hochenergie-Lasertherapie sinnvoll ist, hängt von verschiedenen Faktoren ab, die individuell zu betrachten sind. Die
            genaue Vorgehensweise und die Erfolgsaussichten sollten Sie mit dem Facharzt für Orthopädie Ihres Vertrauens besprechen, der Ihre
            individuelle Situation bewerten kann.
        </Text>
    </Stack>
);
