import { Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const BandscheibenvorfallItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Bandscheibenvorfall, auch bekannt als Bandscheibenprolaps oder -hernie, tritt auf, wenn der innere Kern einer Bandscheibe durch den
            äußeren Faserring austritt. Im Gegensatz zur Bandscheibenprotrusion, bei der die Bandscheibe lediglich nach außen vorgewölbt ist, kommt es
            beim Bandscheibenvorfall zu einem Austritt von Bandscheibengewebe.
        </Text>
        <Text>
            Ein Bandscheibenvorfall tritt häufig im unteren Rückenbereich (Lendenwirbelsäule) oder im Nacken (Halswirbelsäule) auf. Die Ursachen
            können vielfältig sein, darunter Alterung, Verschleiß, Überlastung, Fehlhaltungen, muskuläre Dysbalancen, Verletzungen oder plötzliche,
            starke Belastung der Wirbelsäule.
        </Text>
        <Text>
            Die Symptome eines Bandscheibenvorfalls können sich je nach Lage und Ausmaß unterscheiden. Häufige Symptome sind plötzliche oder langsam
            zunehmende Nacken- oder Rückenschmerzen, die sich in die Arme bzw. die Beine ausbreiten können. Es können auch Taubheitsgefühle, Kribbeln
            oder eine Muskelschwäche in den betroffenen Bereichen auftreten. In einigen Fällen kann ein Bandscheibenvorfall zur Kompression von
            Nervenwurzeln oder des Rückenmarks führen, was zu ernsthaften neurologischen Symptomen wie Lähmungen, Störungen der Blasen- und
            Mastdarmfunktion oder Einschränkungen der Feinmotorik führen kann.
        </Text>
        <Text>
            Die Diagnose eines Bandscheibenvorfalls erfolgt in der Regel durch eine Kombination aus körperlicher Untersuchung, Bewertung der Symptome
            des Patienten und bildgebenden Verfahren wie Magnetresonanztomographie (MRT) oder Computertomographie (CT).
        </Text>
        <Text>
            Die Behandlung eines Bandscheibenvorfalls ist in den meisten Fällen konservativ (nicht-operativ) möglich. Konservative Maßnahmen können z.
            B. Ruhe, Schmerzmedikation, Physiotherapie, Stoßwellentherapie, Rückengymnastik, Anpassung der Lebensgewohnheiten, Akupunktur,
            Magnetfeldtherapie und Injektionen zur Schmerzlinderung umfassen. Diese Maßnahmen zielen darauf ab, die Schmerzen zu lindern, die
            Entzündung zu reduzieren und die Funktion der Wirbelsäule zu verbessern. In den meisten Fällen klingen die Symptome eines
            Bandscheibenvorfalls innerhalb von Wochen bis Monaten ab und eine Operation ist nicht erforderlich.
        </Text>
        <Text>
            Es ist wichtig, dass ein Facharzt für Orthopädie die Diagnose stellt und ein umfassendes Behandlungskonzept entwickelt wird, da neben dem
            Schweregrad des Bandscheibenvorfalls auch die individuelle Situation der Patientin / des Patienten berücksichtigt werden muss.
        </Text>
    </Stack>
);
