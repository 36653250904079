import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const SpondyloseItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Spondylose ist eine Erkrankung der Wirbelsäule, die durch den Verschleiß der Wirbelkörper und der Bandscheiben gekennzeichnet ist. Es
            handelt sich um eine altersbedingte Veränderung, die im Laufe der Zeit auftritt und bei vielen Menschen im fortgeschrittenen Lebensalter
            festgestellt werden kann. Die Bandscheiben, die als Stoßdämpfer zwischen den Wirbelkörpern dienen, nutzen sich ab und verlieren an Höhe
            und Elastizität. Dies führt zu einer Verengung des Abstands zwischen den Wirbelkörpern und zur Bildung von Knochenauswüchsen, sogenannten
            osteophytären Spangen (Spondylophyten). Diese Spangen können auf Grund der Reibung und des Drucks auf die umliegenden Strukturen wie z. B.
            Nervenwurzeln oder das Rückenmark Schmerzen und andere Symptome verursachen. Die Spondylose betrifft oft mehrere Abschnitte der
            Wirbelsäule.
        </Text>
        <Text>Zu den häufigsten Symptomen der Spondylose gehören:</Text>
        <OrderedList>
            <ListItem>
                Rückenschmerzen: Chronische oder intermittierende Schmerzen im betroffenen Wirbelsäulenabschnitt, die sich bei wirbelsäulenbelastenden
                Aktivitäten wie langes Stehen oder Gehen verschlimmern können.
            </ListItem>
            <ListItem>
                Steifheit und Bewegungseinschränkung: Die Beweglichkeit der Wirbelsäule kann bei Vorliegen einer Spondylose eingeschränkt sein, was zu
                Steifheit und Schwierigkeiten beim Beugen, Drehen oder Aufrichten führen kann.
            </ListItem>
            <ListItem>
                Ausstrahlende Schmerzen: In einigen Fällen können die Schmerzen von der Wirbelsäule auch in andere Regionen wie z. B. die Arme oder
                Beine ausstrahlen.
            </ListItem>
            <ListItem>
                Taubheits- und Kribbelgefühle: Die Kompression von Nervenwurzeln durch die osteophytären Spangen (Spondylophyten) kann zu Taubheits-,
                Kribbelgefühlen und Schwächen in den Armen oder Beinen führen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung der Spondylarthrose soll die Schmerzen lindern, die Beweglichkeit verbessern und die Lebensqualität erhalten. Zu den
            Behandlungsmöglichkeiten gehören:
        </Text>
        <OrderedList>
            <ListItem>
                Konservative Maßnahmen: Dazu gehören eine Schmerzmedikation (z. B. nichtsteroidale entzündungshemmende Medikamente), Akupunktur,
                Stoßwellentherapie, Magnetfeldtherapie, Physiotherapie, Wärme- oder Kälteanwendungen, Gewichtsreduktion bei Übergewicht sowie die
                Anpassungen der körperlichen Aktivitäten, um eine Überlastung der Wirbelsäule zu vermeiden.
            </ListItem>
            <ListItem>
                Injektionen: Lokale Injektionen mit Hyaluronsäure, ACP (Eigenbluttherapie), entzündungshemmenden Medikamenten oder Kortikosteroiden
                können erfolgreich zur Schmerzlinderung, Entzündungshemmung und Verlangsamung der Erkrankung eingesetzt werden.
            </ListItem>
            <ListItem>
                Operation: In schweren Fällen, in denen konservative Maßnahmen keine ausreichende Linderung erzielen können, kann eine Operation in
                Betracht gezogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Es ist sinnvoll den Facharzt für Orthopädie Ihres Vertrauens aufzusuchen, um eine genaue Diagnose zu erhalten und die besten
            Behandlungsoptionen zu besprechen und einzuleiten. So können die Schmerzen in der Regel effektiv gelindert, das Fortschreiten des
            Verschleißes verlangsamt und die Lebensqualität wieder erheblich verbessert werden.
        </Text>
    </Stack>
);
