import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const RheumaItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Rheuma ist ein Sammelbegriff für eine Vielzahl von Erkrankungen, die vorwiegend die Gelenke, Muskeln und das Bindegewebe betreffen. Es
            gibt über 100 verschiedene Formen von rheumatischen Erkrankungen, wobei die häufigsten Formen die rheumatoide Arthritis, der systemische
            Lupus erythematodes, die Spondylitis ankylosans (Morbus Bechterew) und die Gicht sind.
        </Text>
        <Text>
            Die genaue Ursache von rheumatischen Erkrankungen ist oft nicht bekannt. Es wird angenommen, dass eine Kombination von genetischen und
            immunologischen Faktoren sowie Umwelteinflüssen zu ihrer Entstehung beiträgt. Das Immunsystem spielt eine entscheidende Rolle, da es
            irrtümlicherweise gesundes Gewebe im Körper angreift und dabei eine Entzündungsreaktion auslöst.
        </Text>
        <Text>Die Symptome von rheumatischen Erkrankungen können variieren, aber einige gemeinsame Anzeichen sind:</Text>
        <OrderedList>
            <ListItem>Gelenkschmerzen, -schwellungen und -Steifheit, insbesondere am Morgen oder nach Ruhephasen.</ListItem>
            <ListItem>Bewegungseinschränkungen der betroffenen Gelenke.</ListItem>
            <ListItem>Müdigkeit, Erschöpfung und allgemeines Unwohlsein.</ListItem>
            <ListItem>Entzündungen und Schmerzen in den Muskeln, Sehnen und Bändern.</ListItem>
            <ListItem>Schwellungen an den Gelenken oder anderen Körperregionen.</ListItem>
            <ListItem>
                Allgemeines Krankheitsgefühl, begleitet von Fieber, Gewichtsverlust oder anderen systemischen Symptomen (bei einigen Formen von
                rheumatischen Erkrankungen).
            </ListItem>
        </OrderedList>
        <Text>
            Die Diagnose einer rheumatischen Erkrankung erfordert in der Regel eine gründliche medizinische Untersuchung, einschließlich einer
            Anamnese, einer körperlichen Untersuchung, Bluttests und bildgebender Verfahren wie Röntgenaufnahmen, Ultraschalluntersuchungen oder MRT.
            In einigen Fällen kann auch die chirurgische Entnahme eine Gewebeprobe (Biopsie) sinnvoll sein.
        </Text>
        <Text>
            Die Behandlung von rheumatischen Erkrankungen hat zum Ziel Schmerzen zu lindern, Entzündungen zu reduzieren, das Fortschreiten der
            Erkrankung zu verlangsamen und die Lebensqualität des Patienten zu verbessern.
        </Text>
        <Text>
            Die Behandlung kann medikamentös sein und umfasst entzündungshemmende Medikamente, Schmerzmittel, Kortikosteroide und immunsuppressive
            Medikamente. Physiotherapie, Stoßwellentherapie, Eigenbluttherapie (ACP), Magnetfeldtherapie, Biofeedback-Therapie bei muskulären
            Dysbalancen, ergotherapeutische Maßnahmen, Bewegungstherapie und alternative Therapien wie Akupunktur können ebenfalls empfohlen werden.
        </Text>
        <Text>
            Es ist wichtig frühzeitig auch den Facharzt für Orthopädie Ihres Vertrauens aufzusuchen, falls Sie Symptome einer rheumatischen Erkrankung
            haben, um die genaue Diagnose und das für Sie optimale Behandlungskonzept zu erhalten. Ein multidisziplinärer Ansatz mit der
            Zusammenarbeit Ihres Orthopäden, Rheumatologen und anderen Fachkräften kann helfen das Fortschreiten der Erkrankung effektiv zu
            verlangsamen und Ihre Lebensqualität zu verbessern.
        </Text>
    </Stack>
);
