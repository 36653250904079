import * as React from 'react';
import { Stack, Text } from '@chakra-ui/react';

export const TriggerosteopathieItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Osteopathie ist ein ganzheitlicher Ansatz in der Medizin, der sich mit der Diagnose, Behandlung und Prävention von Erkrankungen und
            Funktionsstörungen des Körpers beschäftigt. Sie basiert auf dem Prinzip, dass der Körper als Einheit betrachtet wird und dass eine gute
            Gesundheit von einer harmonischen Balance im Körper abhängt.
        </Text>
        <Text>
            Osteopathisch ausgebildete Ärzte betrachten den Körper als ein komplexes System, in dem alle Gewebe, Organe und Strukturen miteinander
            verbunden sind. Sie konzentrieren sich auf die manuelle Untersuchung und Behandlung von Bewegungseinschränkungen und Dysfunktionen des
            Gewebes, der Muskeln, der Gelenke und der Organe.
        </Text>
        <Text>
            Die osteopathische Behandlung beinhaltet verschiedene manuelle Techniken, wie z. B. sanfte Manipulationen, Mobilisationen, Dehnungen und
            Massagen, um die Beweglichkeit der Gewebe und Gelenke zu verbessern, Spannungen zu lösen und die Durchblutung und den Lymphfluss zu
            fördern. Der Fokus liegt auf der Wiederherstellung des Gleichgewichts im Körper, um die Selbstheilungskräfte des Körpers zu unterstützen.
        </Text>
        <Text>
            Die Osteopathie kann bei einer Vielzahl von Beschwerden eingesetzt werden, darunter Rückenschmerzen, Nackenschmerzen, Gelenkschmerzen,
            Muskelverspannungen, Kopfschmerzen, Verdauungsstörungen und Atemwegserkrankungen. Sie wird auch zur Prävention von Verletzungen und zur
            Förderung des allgemeinen Wohlbefindens eingesetzt.
        </Text>
        <Text>
            Die Osteopathie ist ein eigenständiger medizinischer Ansatz, der auf einer umfangreichen Ausbildung in Anatomie, Physiologie, Pathologie
            und manuellen Techniken basiert.
        </Text>
        <Text>
            Es ist ratsam vor einer osteopathischen Behandlung mit einem Facharzt für Orthopädie zu besprechen, ob diese Behandlungsmethode für Ihre
            spezifischen Beschwerden geeignet ist.
        </Text>
    </Stack>
);
