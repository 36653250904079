import { AkupunkturItem } from './akupunktur-item';
import { BiofeedbackItem } from './biofeedback-item';
import { ChirotherapieItem } from './chirotherapie-item';
import { EigenblutItem } from './eigenblut-item';
import { ElektromyographieItem } from './elektromyographie-item';
import { GanganalyseItem } from './ganganalyse-item';
import { HyaluronItem } from './hyaluron-item';
import { InfiltrationstherapieItem } from './infiltrationstherapie-item';
import { KinesioItem } from './kinesio-item';
import { MagnetfeldItem } from './magnetfeld-item';
import { MuskelkrafttestungItem } from './muskelkrafttestung-item';
import { OrthesenItem } from './orthesen-item';
import { PedographieItem } from './pedographie-item';
import { SchroepfenItem } from './schroepfen-item';
import { SportmedizinItem } from './sportmedizin';
import { StosswellenItem } from './stosswellen-item';
import { TriggerosteopathieItem } from './triggerosteopathie-item';
import { TriggerpunktinfiltrationItem } from './triggerpunktinfiltration-item';
import { WirbelsaeulentheapieItem } from './wirbelsaeulentheapie-item';
import { WirbelsaeulenvermessungItem } from './wirbelsaeulenvermessung-item';
import { LabordiagnostikItem } from './labordiagnostik-item';
import { InfusionItem } from './infusion-item';
import { OrthomolekularItem } from './orthomolekular-item';
import { OsteoporoseItem } from './osteoporose-item';
import { PraeventionItem } from './praevention-item';
import { ZweitmeinungItem } from './zweitmeinung-item';
import { HausbesuchItem } from './hausbesuch-item';
import { SchmerztherapieItem } from './schmerztherapie-item';
import { KryotherapieItem } from './kryotherapie-item';
import { HochenergieLasertherapieItem } from './hochenergielaser-item';

export interface LeistungItem {
    title: string;
    subTitle?: string;
    content: React.ComponentType<any>;
}

export const leistungenItems: LeistungItem[] = [
    { title: 'Stoßwellentherapie', subTitle: '(fokussierte Stoßwellentherapie / radiale Stoßwellentherapie)', content: StosswellenItem },
    { title: 'Hyaluronsäure-Infiltration', content: HyaluronItem },
    { title: 'Eigenbluttherapie / ACP / PRP', content: EigenblutItem },
    { title: 'Hochenergetische Induktionstherapie / Magnetfeldtherapie', content: MagnetfeldItem },
    { title: 'Hochenergie-Lasertherapie', content: HochenergieLasertherapieItem },
    { title: '4D-Wirbelsäulenvermessung', subTitle: '(Haltungsanalyse und Bewegungsanalyse)', content: WirbelsaeulenvermessungItem },
    { title: 'Ganganalyse', content: GanganalyseItem },
    { title: 'Pedographie', subTitle: '(Pedobarographie) / Fußdruckmessung', content: PedographieItem },
    { title: 'Elektromyographie (EMG)', content: ElektromyographieItem },
    { title: 'Muskelfunktionsdiagnostik', content: MuskelkrafttestungItem },
    { title: 'Biofeedback-Diagnostik und Biofeedback-Therapie', content: BiofeedbackItem },
    { title: 'Chirotherapie / Manuelle Medizin', content: ChirotherapieItem },
    { title: 'Triggerosteopathie / Osteopathie', content: TriggerosteopathieItem },
    { title: 'Triggerpunktinfiltration / Neuraltherapie', content: TriggerpunktinfiltrationItem },
    { title: 'Akupunktur', content: AkupunkturItem },
    { title: 'Schröpfen', content: SchroepfenItem },
    { title: 'Infiltrationstherapie / Injektionstherapie', content: InfiltrationstherapieItem },
    { title: 'Minimalinvasive Wirbelsäulentherapie (PRT)', content: WirbelsaeulentheapieItem },
    { title: 'Labordiagnostik', subTitle: '(Blutuntersuchung)', content: LabordiagnostikItem },
    { title: 'Infusionen / Infusionstherapie', content: InfusionItem },
    { title: 'Orthomolekulare Therapie', content: OrthomolekularItem },
    { title: 'Sportmedizin', content: SportmedizinItem },
    { title: 'Kryotherapie', content: KryotherapieItem },
    { title: 'Check-up / Vorsorgeuntersuchung', subTitle: '(Prävention)', content: PraeventionItem },
    { title: 'Kinesio-Taping', content: KinesioItem },
    { title: 'Osteoporose-Therapie', content: OsteoporoseItem },
    { title: 'Minimalinvasive, multimodale Schmerztherapie', content: SchmerztherapieItem },
    { title: 'Zweitmeinung', content: ZweitmeinungItem },
    { title: 'Einlagen-Versorgung, Orthesen-Versorgung', content: OrthesenItem },
    { title: 'Hausbesuch', content: HausbesuchItem },
];
