import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const KalkschulterItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Kalkschulter, auch als Tendinosis calcarea bekannt, ist eine schmerzhafte Erkrankung, die durch Kalkablagerungen in der
            Rotatorenmanschette (Gruppe von Muskeln und Sehnen, die das Schultergelenk stabilisiert) verursacht wird. Die genaue Ursache der
            Kalkschulter ist noch nicht vollständig verstanden, aber wiederholte Mikrotraumata, Durchblutungsstörungen und Entzündungen spielen
            wahrscheinlich eine wesentliche Rolle.
        </Text>
        <Text>Typischerweise durchläuft die Kalkschulter drei Phasen:</Text>
        <OrderedList>
            <ListItem>
                Bildungsphase: Hier kommt es zur Ablagerung von Kalziumsalzen in der Rotatorenmanschette. Dies kann durch wiederholte
                Überkopfbewegungen, Mikrotraumata oder degenerative Veränderungen ausgelöst werden.
            </ListItem>
            <ListItem>
                Reizungsphase: Die Ablagerungen können die umgebenden Gewebe reizen und Entzündungen verursachen, was häufig zu Schmerzen und
                Bewegungseinschränkungen führt.
            </ListItem>
            <ListItem>
                Auflösungsphase: Die Kalkablagerungen können sich auflösen und vom Körper resorbiert werden. Sie können jedoch auch verhärten und
                Narbengewebe bilden.
            </ListItem>
        </OrderedList>
        <Text>Die Kalkschulter kann folgende Symptome mit sich bringen:</Text>
        <OrderedList>
            <ListItem>Schmerzen in der Schulter, die sich bei Bewegung des Arms oder bei Druck auf die Schulter verschlimmern können.</ListItem>
            <ListItem>Bewegungseinschränkungen und Steifheit des Schultergelenks, insbesondere bei Überkopfbewegungen.</ListItem>
            <ListItem>Schwäche und Muskelschwund in der betroffenen Schulterregion.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung der Kalkschulter zielt darauf ab, Schmerzen zu lindern, die Entzündung zu reduzieren und die Beweglichkeit des
            Schultergelenks wiederherzustellen. Die Behandlungsmöglichkeiten beinhalten:
        </Text>
        <OrderedList>
            <ListItem>
                Konservative Maßnahmen: Ruhe und Schonung der Schulter, Vermeidung von belastenden Bewegungen, physikalische Therapie zur Verbesserung
                der Beweglichkeit und Stärkung der Muskulatur, Anwendung von Eis oder Wärme zur Schmerzlinderung, sowie entzündungshemmende
                Medikamente.
            </ListItem>
            <ListItem>
                Injektionen: In einigen Fällen kann eine Kortikosteroid-Injektion in die Schulter zur kurzfristigen Schmerzlinderung und
                Entzündungshemmung verabreicht werden.
            </ListItem>
            <ListItem>
                Stoßwellentherapie: Die Anwendung von Stoßwellen zur Zerstörung der Kalkablagerungen, zur Verbesserung der Durchblutung und
                Stimulation von Stoffwechselprozessen.
            </ListItem>
            <ListItem>Eigenbluttherapie zur Schmerz- und Entzündungshemmung.</ListItem>
            <ListItem>Magnetfeldtherapie zur Schmerzreduktion und Unterstützung der Selbstheilungsprozesse.</ListItem>
            <ListItem>
                Operation: In seltenen Ausnahmefällen, in denen konservative Maßnahmen nicht erfolgreich sind, kann eine Operation in Betracht gezogen
                werden, um die Kalkablagerungen zu entfernen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Behandlung der Kalkschulter sollte individuell auf den Patienten abgestimmt sein und erfordert eine genaue Diagnose und Überwachung
            durch den Facharzt für Orthopädie Ihres Vertrauens.
        </Text>
    </Stack>
);
