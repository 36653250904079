import { PhoneIcon } from '@chakra-ui/icons';
import { Button, Link, LinkProps } from '@chakra-ui/react';

const href = 'tel:+4921507790820';
const content = '02150 - 77 90 82 0';

interface IProps extends LinkProps {
    colorScheme?: 'primary' | 'secondary';
    variant?: 'outline' | 'solid';
}

export const PhoneButton: React.FC<IProps> = ({ colorScheme = 'primary', variant = 'outline', ...rest }) => (
    <Link href={href} {...rest}>
        <Button size={{ base: 'sm', md: 'md' }} leftIcon={<PhoneIcon />} w={'100%'} variant={variant} colorScheme={colorScheme}>
            {content}
        </Button>
    </Link>
);

export const PhoneLink: React.FC<LinkProps> = (props) => (
    <Link {...props} href={href}>
        {content}
    </Link>
);
