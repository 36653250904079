import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const TinnitusItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Tinnitus ist die Wahrnehmung von Geräuschen, die nicht durch externe Schallquellen verursacht werden. Es handelt sich um ein Symptom,
            das als Ohrgeräusch wie Klingeln, Summen, Pfeifen, Zischen oder Rauschen beschrieben wird. Ein Tinnitus kann in einem oder beiden Ohren
            auftreten und unterschiedlich stark ausgeprägt sein.
        </Text>
        <Text>
            Die genaue Ursache eines Tinnitus kann oft nicht bestimmt werden. Verschiedene Einflussfaktoren können die Entstehung eines Tinnitus
            begünstigen:
        </Text>
        <OrderedList>
            <ListItem>
                Lärmbelastung: Die langfristige Exposition gegenüber lauten Geräuschen kann zu Schädigungen der Hörsinneszellen im Innenohr führen und
                eine Tinnitus verursachen.
            </ListItem>
            <ListItem>
                Altersbedingter Hörverlust: Mit zunehmendem Alter kann es zu einem natürlichen Abbau der Hörfähigkeit kommen, der mit einem Tinnitus
                einhergehen kann.
            </ListItem>
            <ListItem>
                Hörstörungen: Verschiedene Hörstörungen wie die Schallempfindungsschwerhörigkeit, ein Hörsturz oder eine Hörnervschädigung können mit
                einem Tinnitus verbunden sein.
            </ListItem>
            <ListItem>Ohrverletzungen oder Ohrinfektionen: Verletzungen des Ohrs oder Infektionen können zu einem Tinnitus führen.</ListItem>
            <ListItem>
                Medikamente: Einige Medikamente, insbesondere ototoxische Medikamente, können als Nebenwirkung einen Tinnitus verursachen.
            </ListItem>
            <ListItem>
                Stress und psychische Belastungen: Stress, Angstzustände und Depressionen können das Risiko für die Entstehung eines Tinnitus erhöhen
                oder seine Symptome verschlimmern.
            </ListItem>
        </OrderedList>
        <Text>Obwohl ein Tinnitus nicht heilbar ist, gibt es verschiedene Ansätze zur Behandlung und Bewältigung der Symptome:</Text>
        <OrderedList>
            <ListItem>
                Tinnitus-Retraining-Therapie: Diese Therapie zielt darauf ab, die Wahrnehmung von Tinnitus zu verändern, indem sie den Betroffenen
                hilft, den Tinnitus zu akzeptieren und nicht mehr als bedrohlich oder störend wahrzunehmen.
            </ListItem>
            <ListItem>
                Geräuschtherapie: Das Hinzufügen von Hintergrundgeräuschen oder die Verwendung von Hörgeräten oder Geräten zur Geräuschmodifikation
                kann dazu beitragen den Tinnitus zu überdecken und die Wahrnehmung zu verringern.
            </ListItem>
            <ListItem>
                Stressbewältigung und Entspannungstechniken: Stressmanagement-Techniken wie Meditation, Atemübungen oder Entspannungstherapie können
                dazu beitragen den Tinnitus zu reduzieren.
            </ListItem>
            <ListItem>
                Tinnitus-Beratung und Selbsthilfegruppen: Der Austausch mit anderen Betroffenen in Selbsthilfegruppen oder der Besuch einer
                Beratungsstelle kann hilfreich sein, um den Umgang mit einem Tinnitus zu erleichtern.
            </ListItem>
            <ListItem>
                Behandlung von Grunderkrankungen: Wenn der Tinnitus durch eine zu Grunde liegende Erkrankung verursacht wird, kann die Behandlung
                dieser Erkrankung auch zur Linderung des Tinnitus beitragen.
            </ListItem>
            <ListItem>
                Akupunktur: Die Akupunktur wird bereits seit Jahrtausenden erfolgreich in der Medizin eingesetzt und kann auch hier zu einer Linderung
                beitragen.
            </ListItem>
        </OrderedList>
        <Text>
            Falls die Symptome eines Tinnitus auftreten, ist zunächst eine ärztliche Abklärung und Ursachendiagnostik wichtig. Anschließend sollte ein
            individueller Behandlungsplan erstellt werden, der alle persönlichen Einflussfaktoren berücksichtigt.
        </Text>
    </Stack>
);
