import * as React from 'react';
import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';

export const InfiltrationstherapieItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Infiltrationstherapie, auch als Injektionstherapie oder lokale Infiltrationstherapie bezeichnet, ist eine medizinische
            Behandlungsmethode, bei der Medikamente oder andere Substanzen in das umliegende Gewebe oder in bestimmte Körperregionen injiziert werden.
            Das Ziel der Infiltrationstherapie ist es, Schmerzen zu lindern, Entzündungen zu reduzieren und/oder die Durchblutung zu verbessern.
        </Text>
        <Text>
            Die Infiltrationstherapie kann verschiedene Formen und Anwendungen haben, je nach den spezifischen Bedürfnissen und der zugrunde liegenden
            Erkrankung des Patienten. Einige häufig verwendete Medikamente oder Substanzen, die bei der Infiltrationstherapie verwendet werden,
            umfassen:
        </Text>
        <OrderedList>
            <ListItem>
                Lokalanästhetika: Diese werden zur kurzfristigen Schmerzlinderung eingesetzt. Sie blockieren vorübergehend die Schmerzrezeptoren in
                der betroffenen Region und können bei verschiedenen Schmerzzuständen, wie beispielsweise bei akuten Verletzungen oder bei chronischen
                Schmerzen, angewendet werden.
            </ListItem>
            <ListItem>
                Kortikosteroide: Kortikosteroide haben entzündungshemmende Eigenschaften und können bei entzündlichen Erkrankungen wie Arthrose,
                Bursitis, Sehnenentzündungen oder Rückenschmerzen eingesetzt werden. Sie helfen, Entzündungen zu reduzieren, Schwellungen zu
                verringern und Schmerzen zu lindern.
            </ListItem>
            <ListItem>
                Hyaluronsäure: Hyaluronsäure ist eine Substanz, die natürlicherweise in den Gelenken vorkommt und zur Schmierung und Dämpfung
                beiträgt. Bei Gelenkerkrankungen wie Arthrose kann die Infiltration von Hyaluronsäure in das betroffene Gelenk dazu beitragen, die
                Gelenkfunktion zu verbessern und Schmerzen zu lindern.
            </ListItem>
            <ListItem>
                ACP/Eigenbluttherapie: ACP (autologes, konditioniertes Plasma) ist ein aus dem Eigenblut des Patienten gewonnenes Substrat, das bei
                Arthrose, Sehnenentzündungen und/oder Muskelverletzungen eingesetzt wird. Das hier nur körpereigene Substanzen zum Einsatz kommen, ist
                die Eigenbluttherapie besonders für Allergiker oder Leistungssportler geeignet, die den Dopingrichtlinien unterliegen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Auswahl der Medikamente oder Substanzen für die Infiltrationstherapie hängt von der spezifischen Erkrankung, den Symptomen und der
            individuellen Situation des Patienten ab. Die Infiltrationstherapie wird in der Regel von einem Facharzt durchgeführt, der über fundierte
            Kenntnisse in der Durchführung der Injektionen verfügt.
        </Text>
        <Text>
            Es ist wichtig zu beachten, dass die Infiltrationstherapie individuell angepasst werden sollte und dass mögliche Risiken und
            Nebenwirkungen berücksichtigt werden müssen. Vor einer Infiltrationstherapie sollte eine umfassende Anamnese und Untersuchung durchgeführt
            werden, um die genaue Diagnose zu stellen und die geeignete Behandlungsmethode festzulegen.
        </Text>
    </Stack>
);
