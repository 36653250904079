import * as React from 'react';
import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';

export const SchmerztherapieItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            Die multimodale Schmerztherapie ist ein Behandlungsansatz, der darauf abzielt, chronische Schmerzen ganzheitlich zu behandeln, indem
            verschiedene therapeutische Modalitäten kombiniert werden. Sie basiert auf dem Konzept, dass chronische Schmerzen oft durch eine komplexe
            Wechselwirkung von körperlichen, psychischen und sozialen Faktoren entstehen und daher eine umfassende Behandlung erfordern.
        </Text>
        <Text>
            Die multimodale Schmerztherapie besteht aus einer Kombination verschiedener Therapieelemente, die individuell auf den Patienten und seine
            spezifischen Bedürfnisse zugeschnitten sind. Zu den häufig verwendeten Komponenten gehören:
        </Text>
        <OrderedList>
            <ListItem>
                Medikamentöse Therapie: Die medikamentöse Behandlung kann Schmerzmittel, entzündungshemmende Medikamente, Antidepressiva,
                Antikonvulsiva und andere Medikamente umfassen, die darauf abzielen, Schmerzen zu lindern, Entzündungen zu reduzieren und andere
                Symptome zu kontrollieren.
            </ListItem>
            <ListItem>
                Physikalische Therapie: Die physikalische Therapie beinhaltet verschiedene Techniken und Modalitäten wie Wärme- und Kältetherapie,
                Elektrotherapie, Ultraschalltherapie und TENS (transkutane, elektrische Nervenstimulation), um die Schmerzen zu lindern, die
                Beweglichkeit zu verbessern und die Funktionsfähigkeit wiederherzustellen.
            </ListItem>
            <ListItem>
                Psychotherapie: Die psychotherapeutische Komponente zielt darauf ab, den psychischen und emotionalen Aspekt von Schmerzen zu
                behandeln. Hierzu können kognitive Verhaltenstherapie, Entspannungstechniken, Achtsamkeitstraining, Biofeedback (z. B. EMG) und andere
                Therapieformen eingesetzt werden, um den Umgang mit Schmerzen zu verbessern, Stress abzubauen und negative Denkmuster zu verändern.
            </ListItem>
            <ListItem>
                Physiotherapie: Die Physiotherapie umfasst gezielte Übungen und Rehabilitationstechniken, um die Muskulatur zu stärken, die
                Beweglichkeit zu verbessern und die Körperhaltung zu optimieren. Dadurch können Schmerzen reduziert werden und die Funktionsfähigkeit
                des Körpers wird verbessert.
            </ListItem>
            <ListItem>
                Interventionelle Verfahren: Bei Bedarf können auch interventionelle Verfahren wie Injektionstherapien, Nervenblockaden oder ggf.
                Verfahren wie die Implantation von Schmerzpumpen in die multimodale Schmerztherapie einbezogen werden.
            </ListItem>
            <ListItem>
                Komplementäre Therapien: Ergänzend zu den oben genannten Komponenten können auch Therapien wie Akupunktur, Schröpfen,
                Magnetfeldtherapie oder Kinesio-Taping in die multimodale Schmerztherapie einbezogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Der genaue Behandlungsplan wird individuell auf den Patienten abgestimmt, wobei die verschiedenen Therapieelemente je nach Bedarf
            kombiniert und angepasst werden. Das Ziel der multimodalen Schmerztherapie ist es, die Schmerzen zu lindern, die Funktionsfähigkeit zu
            verbessern, den Umgang mit Schmerzen zu erlernen und die Lebensqualität des Patienten zu steigern. Eine umfassende Evaluation und
            regelmäßige Überprüfung des Therapieerfolgs sind ebenfalls wichtige Bestandteile dieses Ansatzes.
        </Text>
    </Stack>
);
