import * as React from 'react';
import { Stack, Text } from '@chakra-ui/react';

export const WirbelsaeulentheapieItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Text>
            PRT steht für "Periradikuläre Therapie". Es handelt sich um eine Behandlungsmethode, die in der Schmerztherapie bei Rückenschmerzen und
            radikulären Beschwerden (Schmerzen, die entlang eines Nervenverlaufs ausstrahlen) eingesetzt wird.
        </Text>
        <Text>
            Bei der PRT wird ein entzündungshemmendes Medikament wie zum Beispiel ein Kortikosteroid und/oder ein Lokalanästhetikum in die Nähe des
            betroffenen Nervenwurzelbereichs injiziert. Die Injektion erfolgt oft unter Ultraschall-, Röntgen- oder CT-Kontrolle, um die Platzierung
            der Nadel zu kontrollieren.
        </Text>
        <Text>
            Die Periradikuläre Therapie zielt darauf ab, lokale Entzündungen und Schwellungen um die betroffene Nervenwurzel herum zu reduzieren und
            dadurch Schmerzen und andere Symptome zu lindern. Durch die Blockade oder Verminderung der Entzündungsreaktion wird der Druck auf den Nerv
            reduziert und die Nervenfunktion kann sich wieder normalisieren.
        </Text>
        <Text>
            Die PRT kann bei verschiedenen Erkrankungen und Zuständen angewendet werden, einschließlich Bandscheibenvorfällen, Spinalstenosen,
            degenerativen Veränderungen der Wirbelsäule und anderen Ursachen von radikulären Schmerzen.
        </Text>
        <Text>
            Vor einer PRT sollte eine umfassende Untersuchung und Diagnostik durchgeführt werden, um die genaue Ursache der Beschwerden zu ermitteln
            und sicherzustellen, dass die PRT eine geeignete Behandlungsoption ist. Wie bei jeder medizinischen Behandlung gibt es auch bei der PRT
            potenzielle Risiken und Nebenwirkungen, die individuell besprochen werden sollten.
        </Text>
    </Stack>
);
