import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const AchillessehnenrissItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Ein Achillessehnenriss tritt auf, wenn die Achillessehne, die den Wadenmuskel (Musculus gastrocnemius und Musculus soleus) mit der Ferse
            verbindet, teilweise oder vollständig reißt. Es ist eine relativ häufige Verletzung, die vor allem bei Sportlern und Menschen mittleren
            Alters auftritt.
        </Text>
        <Text>
            Ein Achillessehnenriss kann durch plötzliche, starke Belastung oder eine schnelle Bewegung der Wade verursacht werden. Dies geschieht oft
            während sportlicher Aktivitäten wie Springen, Sprinten oder abruptem Anhalten und Drehen. Gelegentlich kann ein Achillessehnenriss auch
            durch degenerative Veränderungen der Sehne im Laufe der Zeit ohne ein akutes Trauma auftreten.
        </Text>
        <Text>Die Symptome eines Achillessehnenrisses können sein:</Text>
        <OrderedList>
            <ListItem>Plötzlicher, intensiver Schmerz im Bereich der Ferse oder des Unterschenkels.</ListItem>
            <ListItem>Ein lauter Knall oder das Gefühl eines Schlags an der Rückseite des Unterschenkels.</ListItem>
            <ListItem>Unfähigkeit, das Körpergewicht auf den betroffenen Fuß zu bringen oder zu gehen.</ListItem>
            <ListItem>Schwellung, Bluterguss und Empfindlichkeit im Bereich des Risses.</ListItem>
            <ListItem>Verlust der Kraft und Beweglichkeit im Knöchel und Fuß.</ListItem>
        </OrderedList>
        <Text>Es gibt zwei Arten von Achillessehnenrissen:</Text>
        <OrderedList>
            <ListItem>Teilriss (partieller Riss): Die Sehne ist nicht vollständig durchtrennt, sondern nur teilweise beschädigt.</ListItem>
            <ListItem>Komplettriss: Die Achillessehne ist vollständig durchtrennt und es besteht eine Lücke zwischen den Enden der Sehne.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung eines Achillessehnenrisses kann konservativ (nicht-operativ) oder operativ sein, abhängig von verschiedenen Faktoren wie
            dem Ausmaß des Risses, dem Alter des Patienten, dem Aktivitätsniveau und den individuellen Bedürfnissen.
        </Text>
        <OrderedList>
            <ListItem>
                Konservative Behandlung: Diese beinhaltet normalerweise das Tragen eines Gipsverbandes, einer Schiene oder eines speziellen Stiefels,
                um die Sehne zu schützen und ruhig zu stellen. Physiotherapie mit Bewegungsübungen wird ebenfalls empfohlen, um die Muskeln zu stärken
                und die Beweglichkeit wiederherzustellen.
            </ListItem>
            <ListItem>
                Operative Behandlung: Bei jüngeren und aktiven Personen oder bei einem vollständigen Riss kann eine Operation empfohlen werden. Dies
                beinhaltet die Wiederherstellung der Sehne durch Naht oder die Verwendung von Sehnenersatzmaterial. Nach der Operation ist eine
                fachärztliche Nachbehandlung erforderlich, um die normale Funktion und Stärke der Achillessehne wiederherzustellen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Genesungszeit variiert je nach Behandlungsart und individuellen Faktoren. Bei einer konservativen Behandlung dauert es normalerweise
            mehrere Monate, bis die Sehne wieder stabil ist und die normale Aktivität wieder aufgenommen werden kann. Nach einer Operation kann die
            Genesungszeit länger sein und eine intensivere Nachbehandlung erfordern.
        </Text>
        <Text>
            Es ist wichtig zeitnah den Facharzt für Orthopädie und Unfallchirurgie Ihres Vertrauens aufzusuchen, sobald der Verdacht auf einen
            Achillessehnenriss besteht, um eine genaue Diagnose zu erhalten und eine angemessene Behandlung zu beginnen. Eine gute fachärztliche
            Betreuung ist entscheidend, um eine erfolgreiche Genesung zu ermöglichen.
        </Text>
    </Stack>
);
