import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@fontsource/dancing-script/500.css';

import { extendTheme, StyleFunctionProps, theme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const customTheme = extendTheme({
    colors: {
        primary: theme.colors.cyan,
        secondary: {
            50: '#ffffff',
            100: '#d9cd91',
            200: '#d2c47e',
            300: '#e0d666',
            400: '#ccbc6b',
            500: '#c5b358',
            600: '#beaa45',
            700: '#ae9b3c',
            800: '#9b8a36',
            900: '#000000',
        },
    },
    fonts: {
        body: 'Roboto, sans-serif',
    },
    styles: {
        global: (props: StyleFunctionProps) => ({
            'html, body': {
                color: mode('gray.700', 'white')(props),
                bg: mode('#f4f5fd', 'gray.800')(props),
            },
            nav: {
                bg: mode('white', 'gray.800')(props),
            },
        }),
    },
});
