import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const ArthroseItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Arthrose ist eine degenerative Gelenkerkrankung, die durch den fortschreitenden Abbau des Knorpels in den Gelenken gekennzeichnet ist.
            Knorpel ist das glatte Gewebe, das die Enden der Knochen in einem Gelenk bedeckt und als Stoßdämpfer fungiert. Arthrose kann in jedem
            Gelenk des Körpers auftreten, ist jedoch am häufigsten in den großen Gelenken wie Knie, Hüfte und Wirbelsäule zu finden. Es betrifft
            normalerweise ältere Menschen, kann jedoch auch bei jüngeren Menschen aufgrund von Verletzungen, Überbeanspruchung oder angeborenen
            Faktoren auftreten.
        </Text>
        <Text>
            Die Ursachen für Arthrose sind vielfältig und können mit der genetischen Veranlagung, Alterung, Gelenküberlastung, wiederholten
            Verletzungen, Fehlstellungen und / oder entzündlichen Erkrankungen zusammenhängen. Der Knorpelverschleiß führt zu einer Abnahme der
            Gelenkflüssigkeit und einer unzureichenden Schmierung des Gelenks, was zu physikalischer Reibung und Entzündung führt. Im Laufe der Zeit
            kann dies zu Schmerzen, Steifheit, Schwellung und eingeschränkter Beweglichkeit des betroffenen Gelenks führen.
        </Text>
        <Text>Die Symptome von Arthrose variieren je nach betroffenem Gelenk:</Text>
        <OrderedList>
            <ListItem>Schmerzen, die sich bei Bewegung oder Belastung verschlimmern und in Ruhe nachlassen können.</ListItem>
            <ListItem>Steifheit, insbesondere nach längeren Ruhephasen oder am Morgen.</ListItem>
            <ListItem>Schwellung und / oder Gelenkergüsse.</ListItem>
            <ListItem>Knirschende Geräusche während der Bewegung.</ListItem>
            <ListItem>Einschränkung der Beweglichkeit des Gelenks.</ListItem>
            <ListItem>Muskelschwäche um das betroffene Gelenk auf Grund von Inaktivität oder Schmerzen.</ListItem>
        </OrderedList>
        <Text>
            Die Diagnose von Arthrose basiert in der Regel auf einer Kombination von Symptomen, körperlicher Untersuchung, Bildgebungstechniken wie
            Röntgenaufnahmen oder Magnetresonanztomographie (MRT) und gegebenenfalls Labortests, um andere Gelenkerkrankungen auszuschließen.
        </Text>
        <Text>
            Die Behandlung der Arthrose zielt darauf ab, die Schmerzen zu lindern, die Gelenkfunktion zu verbessern und den Fortschritt der Erkrankung
            zu verlangsamen. Die Behandlungsmöglichkeiten können sein:
        </Text>
        <OrderedList>
            <ListItem>
                Lebensstiländerungen: Gewichtsreduktion bei Übergewicht, regelmäßige, gelenkschonende Bewegung, Physiotherapie zur Stärkung der
                Muskulatur und Verbesserung der Beweglichkeit sowie Therapie muskulärer Dysbalancen (z. B. per Biofeedback-Therapie).
            </ListItem>
            <ListItem>
                Schmerzmanagement: Verwendung von Schmerzmitteln, entzündungshemmenden Medikamenten, lokalen Injektionen von Kortikosteroiden,
                Hyaluronsäure oder ACP (Eigenbluttherapie), Akupunktur, Stoßwellentherapie, Magnetfeldtherapie.
            </ListItem>
            <ListItem>Hilfsmittel: Verwendung von Orthesen, Einlagen, Gehhilfen zur Entlastung des betroffenen Gelenks.</ListItem>
            <ListItem>
                Operation: In fortgeschrittenen Fällen, wenn konservative Maßnahmen keine ausreichende Linderung bieten, kann eine Operation
                erforderlich sein, wie z. B. ein künstlicher Gelenkersatz (Endoprothese).
            </ListItem>
        </OrderedList>
        <Text>
            Es ist wichtig, dass die Behandlung einer Arthrose individuell auf den Patienten zugeschnitten ist, unter Berücksichtigung des
            Schweregrads der Erkrankung, des Alters, des Aktivitätsniveaus und der individuellen Bedürfnisse. Eine frühzeitige Diagnose und Behandlung
            können dazu beitragen, Schmerzen zu lindern, die Gelenkfunktion zu erhalten und die Lebensqualität maßgeblich zu verbessern.
        </Text>
    </Stack>
);
