import { ListItem, UnorderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const ACGelenksprengungItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Die AC-Gelenksprengung, auch bekannt als Akromioklavikulargelenksprengung, meint eine Verletzung des Gelenks zwischen dem Akromion (ein
            Teil des Schulterblatts) und dem Schlüsselbein (Klavikula). Diese Art von Verletzung tritt häufig bei Sportlern auf, insbesondere bei
            Kontaktsportarten.
        </Text>
        <Text>
            Eine AC-Gelenksprengung tritt normalerweise auf Grund eines starken Aufpralls oder einer direkten Einwirkung auf die Schulter auf. Dies
            kann dazu führen, dass die stabilisierenden Bänder, die das Akromioklavikulargelenk zusammenhalten, überdehnt werden oder (teilweise)
            reißen. Je nach Schweregrad der Verletzung wird die Sprengung in verschiedene Grade eingeteilt:
        </Text>
        <UnorderedList>
            <ListItem>Grad I: Leichte Überdehnung der Bänder mit geringfügiger Instabilität des Gelenks.</ListItem>
            <ListItem>Grad II: Teilriss der Bänder mit mäßiger Instabilität des Gelenks.</ListItem>
            <ListItem>Grad III: Kompletter Riss der Bänder mit deutlicher Instabilität des Gelenks.</ListItem>
        </UnorderedList>
        <Text>
            Die Symptome einer AC-Gelenksprengung können Schmerzen, Schwellungen, eine sichtbare Verformung des Gelenks und eine eingeschränkte
            Beweglichkeit umfassen. In schweren Fällen kann das Schlüsselbein sogar nach oben ragen, was als "Klaviertastenphänomen" bezeichnet wird.
        </Text>
        <Text>
            Die Behandlung einer AC-Gelenksprengung hängt vom Schweregrad der Verletzung ab. Bei leichten Verletzungen (Grad I oder II) kann eine
            konservative Behandlung mit Ruhe, Schmerzmitteln, Physiotherapie und einer Schulterorthese ausreichend sein. Bei schweren Verletzungen
            (Grad III) kann eine Operation erforderlich sein, um das Gelenk zu stabilisieren.
        </Text>
        <Text>
            Die Genesungszeit variiert je nach Schweregrad der Verletzung und individuellen Faktoren. Bei einer konservativen Behandlung kann es
            einige Wochen dauern, bis die Schmerzen abklingen und die normale Beweglichkeit wiederhergestellt ist. Nach einer Operation kann die
            Genesungszeit mehrere Monate betragen, da eine längere Nachbehandlung erforderlich ist, um die Schulterstabilität wiederherzustellen und
            die Muskeln zu stärken.
        </Text>
        <Text>
            Es ist wichtig, dass eine AC-Gelenksprengung diagnostiziert und behandelt wird, um langfristige Schäden wie chronische Instabilität oder
            Arthrose zu vermeiden. Im Zweifelsfall sollte eine AC-Gelenksprengung durch einen Facharzt für Orthopädie und Unfallchirurgie
            ausgeschlossen bzw. diagnostiziert und fachgerecht behandelt werden.
        </Text>
    </Stack>
);
