import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const MortonNeuromItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Das Morton-Neurom, auch als Morton-Neurinom, Morton-Metatarsalgie oder Morton-Neuralgie bekannt, ist eine schmerzhafte Erkrankung im
            Bereich des vorderen Mittelfußes. Es tritt auf, wenn ein Nerv zwischen den Köpfchen der Mittelfußknochen (meistens zwischen dem dritten
            und vierten Mittelfußknochen) komprimiert oder gereizt wird.
        </Text>
        <Text>
            Die genaue Ursache des Morton-Neuroms ist nicht abschließend geklärt, aber es wird angenommen, dass mehrere Faktoren eine Rolle spielen
            können:
        </Text>
        <OrderedList>
            <ListItem>
                Mechanische Belastung: Wiederholte Überlastung oder Reizung des betroffenen Nervs durch enges Schuhwerk, hohen Druck auf den Mittelfuß
                oder wiederholte Fußbelastung.
            </ListItem>
            <ListItem>
                Fußdeformitäten: Bestimmte Fußdeformitäten wie ein hohes Fußgewölbe (Hohlfuß), ein Spreizfuß oder Hammerzehen können zu einer erhöhten
                Belastung des Nervs führen.
            </ListItem>
            <ListItem>
                Verletzungen: Verletzungen oder wiederholte Mikrotraumata im Bereich des Mittelfußes können das Morton-Neurom begünstigen.
            </ListItem>
            <ListItem>
                Hormonelle Veränderungen: Einige Studien haben einen Zusammenhang zwischen hormonellen Veränderungen (z. B. während einer
                Schwangerschaft) und dem Auftreten eines Morton-Neuroms aufgezeigt.
            </ListItem>
        </OrderedList>
        <Text>Die Symptome des Morton-Neuroms sind häufig bestimmt durch:</Text>
        <OrderedList>
            <ListItem>
                Schmerzen im Bereich des vorderen Mittelfußes, insbesondere zwischen den Zehen (typischerweise zwischen dem dritten und vierten Zeh).
            </ListItem>
            <ListItem>Taubheits- oder Kribbelgefühle in den betroffenen, angrenzenden Zehen.</ListItem>
            <ListItem>Brennen oder Stechen im betroffenen Mittel- und Vorfußbereich.</ListItem>
            <ListItem>Verschlimmerung der Symptome beim Gehen, Laufen oder Tragen von engen Schuhen.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung des Morton-Neuroms soll die Symptome lindern, die Entzündung reduzieren und den Druck auf den betroffenen Nerv verringern.
            Die Behandlungsmöglichkeiten umfassen:
        </Text>
        <OrderedList>
            <ListItem>
                Schuhwerk: Das Tragen von Schuhen mit ausreichend Platz im Vor- und Mittelfußbereich und ein niedriger Absatz kann den Druck auf den
                Nerv verringern.
            </ListItem>
            <ListItem>
                Orthopädische Einlagen: Spezielle orthopädische Einlagen können den Druck auf den betroffenen Bereich durch eine Polsterung reduzieren
                und die Symptome lindern.
            </ListItem>
            <ListItem>
                Medikamentöse Therapie: Entzündungshemmende Medikamente wie nichtsteroidale entzündungshemmende Medikamente können zur
                Schmerzlinderung eingesetzt werden.
            </ListItem>
            <ListItem>
                Physiotherapie: Bestimmte Übungen und physikalische Therapie können die Fußmuskulatur stärken, die Fußstellung verbessern und den
                Druck auf den Nerv verringern.
            </ListItem>
            <ListItem>
                Injektionen: Kortikosteroid-Injektionen in den Bereich um das Morton-Neurom können Entzündungen reduzieren und eine Schmerzlinderung
                bringen.
            </ListItem>
            <ListItem>
                Operation: Falls die konservativen Maßnahmen keine ausreichende Besserung bringen, kann eine Operation in Erwägung gezogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Falls Symptome eines Morton-Neuroms bestehen, ist eine Vorstellung bei dem Facharzt für Orthopädie Ihres Vertrauens empfehlenswert, damit
            notwendige Untersuchungen durchgeführt werden und die Diagnose gesichert sowie ein individueller Behandlungsplan erstellt werden kann.
        </Text>
    </Stack>
);
