import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const AchillessehnenreizungItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Die Achillodynie verursacht Schmerzen in der Achillessehne, die den Bereich zwischen der Ferse und den Wadenmuskeln verbindet. Diese
            Erkrankung wird auch als Achillessehnenentzündung bezeichnet.
        </Text>
        <Text>
            Die Achillodynie tritt häufig bei Sportlern auf, insbesondere bei Sportarten, die eine starke Belastung der Achillessehne erfordern, wie
            Laufen, Springen oder Sportarten mit schnellen Richtungswechseln. Die Schmerzen können durch verschiedene Faktoren begünstigt werden, wie
            z. B. durch Überbeanspruchung, eine unzureichende Aufwärmphase, schlechte biomechanische Verhältnisse, muskuläre Dysbalancen, eine zu
            schnelle Leistungssteigerung oder ungeeignetes Schuhwerk.
        </Text>
        <Text>
            Die Symptome einer Achillodynie umfassen normalerweise Schmerzen und Empfindlichkeit in der Achillessehne, insbesondere während oder nach
            sportlichen Aktivitäten. Die Schmerzen können anfangs mild sein, sich aber im Laufe der Zeit verschlimmern und chronisch werden. Die
            Achillessehne kann geschwollen, steif oder verdickt sein, und es kann zu Schmerzen beim Gehen oder Treppensteigen kommen.
        </Text>
        <Text>
            Die Behandlung einer Achillodynie zielt darauf ab, die Schmerzen zu lindern, die Entzündung zu reduzieren und die Heilung der Sehne zu
            fördern. Die empfohlenen Behandlungsmethoden können umfassen:
        </Text>
        <OrderedList>
            <ListItem>
                Ruhigstellung: Entlastung der Achillessehne durch Reduzierung oder Vermeidung von belastenden Aktivitäten. In einigen Fällen kann eine
                temporäre Schienenversorgung oder Verwendung von orthopädischen Schuhen erforderlich sein.
            </ListItem>
            <ListItem>
                Fokussierte Stoßwellentherapie (ESWT): Diese hochenergetischen Schallwellen durchdringen das Gewebe und haben eine regenerative
                Wirkung, indem sie den Heilungsprozess anregen und Schmerzen reduzieren.
            </ListItem>
            <ListItem>
                Physiotherapie: Dehnungs- und Kräftigungsübungen zur Verbesserung der Flexibilität und Stärkung der umgebenden Muskulatur, Massagen
                und Kälteanwendungen können ebenfalls hilfreich sein.
            </ListItem>
            <ListItem>
                Schmerzmanagement: Verwendung von entzündungshemmenden Medikamenten (wie nichtsteroidale Antirheumatika), Salben oder Gelen, um
                Schmerzen und Entzündungen zu reduzieren.
            </ListItem>
            <ListItem>
                Anpassung des Trainings: Überprüfung und Anpassung des Trainingsprogramms, um die Belastung auf die Achillessehne zu verringern und
                Risikofaktoren zu minimieren.
            </ListItem>
        </OrderedList>
        <Text>
            In seltenen Fällen, insbesondere bei schweren oder chronischen Beschwerden, kann eine Operation erforderlich sein. Dies kann die
            Entfernung von entzündetem Gewebe, die Reparatur von Sehnenrissen oder die Entfernung von Knochenwucherungen umfassen.
        </Text>
        <Text>
            Es ist wichtig bei Achillodynie frühzeitig ärztliche Hilfe in Anspruch zu nehmen, um eine angemessene Diagnose und Behandlung zu erhalten.
            Eine frühzeitige Behandlung kann dazu beitragen, langfristige Komplikationen zu vermeiden und eine vollständige Genesung zu erreichen.
        </Text>
    </Stack>
);
