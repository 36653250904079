import { Container, Image } from '@chakra-ui/react';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Img01 from 'images/praxis/Img1.JPG';
import Img02 from 'images/praxis/Img2.JPG';
import Img03 from 'images/praxis/Img3.JPG';
import Img04 from 'images/praxis/Img4.JPG';
import Img05 from 'images/praxis/Img5.JPG';
import Img06 from 'images/praxis/Img6.JPG';
import Img07 from 'images/praxis/Img7.JPG';
import Img08 from 'images/praxis/Img8.JPG';
import Img09 from 'images/praxis/Img9.JPG';
import Img10 from 'images/praxis/Img10.JPG';
import Img11 from 'images/praxis/Img11.JPG';

type SlideType = {
    image: string;
};

const slides: SlideType[] = [
    { image: Img01 },
    { image: Img02 },
    { image: Img03 },
    { image: Img04 },
    { image: Img05 },
    { image: Img06 },
    { image: Img07 },
    { image: Img08 },
    { image: Img09 },
    { image: Img10 },
    { image: Img11 },
];

export const Praxis: React.FC = () => {
    return (
        <Container maxW={'container.lg'}>
            <Carousel infiniteLoop autoPlay interval={5000}>
                {slides.map((slide) => {
                    return <Image src={slide.image} boxShadow={'md'} />;
                })}
            </Carousel>
        </Container>
    );
};
