import * as React from 'react';
import { Box, Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import InduktionImage from 'images/leistungen/Induktion.jpg';

export const MagnetfeldItem: React.FC = (): JSX.Element => {
    return (
        <Stack textAlign={'justify'}>
            <Text>
                Die hochenergetische Induktionstherapie / Magnetfeldtherapie ist eine medizinische Behandlungsmethode, bei der elektromagnetische
                Felder bzw. ein Induktionsfeld zur Linderung von Schmerzen und zur Förderung der Heilung eingesetzt werden. Es wird angenommen, dass
                die Anwendung von elektromagnetischen Feldern auf den Körper verschiedene biologische Prozesse beeinflusst und positive Auswirkungen
                auf das Gewebe hat.
            </Text>
            <Text>Die hochenergetische Induktionstherapie wird bei einer Vielzahl von Erkrankungen und Beschwerden eingesetzt, darunter:</Text>
            <OrderedList>
                <ListItem>
                    Schmerzbehandlung: Elektromagnetische Felder können zur Schmerzlinderung bei chronischen Schmerzen, wie beispielsweise bei
                    Arthrose, Rückenschmerzen oder Fibromyalgie, eingesetzt werden.
                </ListItem>
                <ListItem>
                    Wundheilung: Die hochenergetische Induktionstherapie kann die Wundheilung fördern, indem sie die Durchblutung im betroffenen
                    Bereich verbessert und den Stoffwechsel anregt.
                </ListItem>
                <ListItem>
                    Entzündungshemmung: Elektromagnetische Felder können entzündungshemmende Effekte haben und zur Reduzierung von Entzündungen
                    beitragen.
                </ListItem>
                <ListItem>
                    Knochenbrüche: Bei Knochenbrüchen kann die hochenergetische Induktionstherapie zur Unterstützung der Knochenheilung eingesetzt
                    werden.
                </ListItem>
            </OrderedList>
            <Box>
                <Image float={'left'} src={InduktionImage} w={{ base: '100%', md: 220 }} mr={4} mb={2} alt='Induktion' boxShadow={'md'} />
                <Text mb={2}>
                    Die hochenergetische Induktionstherapie ist in der Regel schmerzfrei und wird als sicher angesehen, wenn sie ordnungsgemäß
                    angewendet wird. Sie kann als eigenständige Behandlung oder in Kombination mit anderen Therapien eingesetzt werden. Die
                    Behandlungsdauer kann variieren, abhängig von der zu behandelnden Erkrankung.
                </Text>
                <Text mb={2}>
                    Es gibt jedoch auch einige Einschränkungen und Kontraindikationen für die hochenergetische Induktionstherapie. Zum Beispiel
                    sollten Menschen mit Herzschrittmachern, implantierten Defibrillatoren, metallischen Implantaten und schwangere Frauen die
                    Behandlung nicht durchführen lassen.
                </Text>
                <Text>
                    Die Entscheidung, eine hochenergetische Induktionstherapie in Betracht zu ziehen, sollte in Absprache mit einem qualifizierten
                    Facharzt für Orthopädie getroffen werden, der Ihre individuelle Situation bewerten kann.
                </Text>
            </Box>
        </Stack>
    );
};
