import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const SchnellenderFingerItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Schnellender Finger, auch als Schnappfinger, Triggerfinger, Springfinger, Tendovaginitis stenosans oder Digitus saltans bezeichnet, ist
            eine Erkrankung, bei der ein Finger oder der Daumen beim Beugen oder Strecken plötzlich hängen bleibt und dann mit einem schnappenden oder
            ruckartigen Gefühl wieder beweglich ist. Dieses Phänomen tritt auf Grund einer Störung der Beugesehnen auf, die normalerweise reibungslos
            durch die Sehnenscheiden und Ringbänder gleiten.
        </Text>
        <Text>
            Die genaue Ursache für einen schnellenden Finger ist nicht immer bekannt, aber es gibt bestimmte Faktoren, die das Risiko erhöhen können
            wie z. B.:
        </Text>
        <OrderedList>
            <ListItem>
                Wiederholte Belastung: Häufige und wiederholte Bewegungen der Finger oder Handgelenke, insbesondere bei bestimmten Berufen oder
                Aktivitäten, können zu einer Reizung der Sehnenscheiden führen.
            </ListItem>
            <ListItem>Alter: Ein schnellender Finger tritt häufiger bei Menschen über 40 Jahren auf.</ListItem>
            <ListItem>Geschlecht: Frauen sind häufiger von schnellenden Fingern betroffen als Männer.</ListItem>
            <ListItem>
                Vorhandensein von anderen Erkrankungen: Menschen mit Erkrankungen wie rheumatoider Arthritis, Diabetes oder Gicht haben ein höheres
                Risiko für schnellende Finger.
            </ListItem>
        </OrderedList>
        <Text>Die Symptome eines schnellenden Fingers können sein:</Text>
        <OrderedList>
            <ListItem>Plötzliches Feststecken oder Hängenbleiben des Fingers beim Beugen oder Strecken.</ListItem>
            <ListItem>Schmerzen oder Unbehagen im Bereich des betroffenen Fingers.</ListItem>
            <ListItem>Ein schnappendes oder ruckartiges Gefühl, wenn der Finger wieder freigegeben wird.</ListItem>
            <ListItem>Eine Schwellung im Bereich der betroffenen Sehnenscheide.</ListItem>
        </OrderedList>
        <Text>Die Behandlung eines schnellenden Fingers hängt von der Ausprägung der Symptome ab:</Text>
        <OrderedList>
            <ListItem>
                Ruhe und Schonung: Das Vermeiden von wiederholten belastenden Bewegungen des Fingers kann helfen die Symptome zu lindern.
            </ListItem>
            <ListItem>
                Handgelenkorthese oder -schiene: Das Tragen einer Orthese oder Schiene kann den Finger in einer positionierten Ruheposition halten und
                Entlastung bieten.
            </ListItem>
            <ListItem>Fingerübungen: Ein Physiotherapeut kann spezielle Übungen empfehlen, um die Fingerbeweglichkeit zu verbessern.</ListItem>
            <ListItem>Medikamente: Entzündungshemmende Medikamente können Schmerzen und Entzündungen lindern.</ListItem>
            <ListItem>
                Injektionen: Eine Kortikosteroid-Injektion in die betroffene Sehnenscheide kann Entzündungen reduzieren und die Symptome lindern.
            </ListItem>
            <ListItem>
                Operation: In Einzelfällen, in denen konservative Maßnahmen keine ausreichende Linderung bieten, kann eine Operation in Betracht
                gezogen werden.
            </ListItem>
        </OrderedList>
        <Text>
            Es ist ratsam bei anhaltenden oder störenden Symptomen eines schnellenden Fingers den Facharzt für Orthopädie Ihres Vertrauens
            aufzusuchen. Dieser kann die genaue Diagnose stellen und die für Sie optimale Behandlungsstrategie empfehlen.
        </Text>
    </Stack>
);
