import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const HalluxValgusItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Der Hallux valgus beschreibt eine Verformung des Vorfußes, bei der sich der Großzeh mit seiner Spitze in Richtung der anderen Zehen
            wendet. Es handelt sich um eine häufige Erkrankung, die oftmals durch eine Kombination aus genetischer Veranlagung und ungünstigem
            Schuhwerk verursacht wird.
        </Text>
        <Text>
            Die Hauptursache für die Entstehung eines Hallux valgus ist eine Instabilität im Großzehengrundgelenk, die dazu führt, dass der Großzeh
            mit seiner Spitze in Richtung der anderen Zehen abweicht. Dies kann durch verschiedene Faktoren begünstigt werden wie z. B.:
        </Text>
        <OrderedList>
            <ListItem>
                Vererbung: Es gibt eine genetische Veranlagung für den Hallux valgus. Das Risiko, die Erkrankung zu entwickeln, ist höher, wenn sie in
                der Familie bereits vorkommt.
            </ListItem>
            <ListItem>
                Schuhwerk: Das Tragen von sehr engen, spitzen und / oder schmalen Schuhen mit hohen Absätzen kann den Großzeh in eine ungünstige
                Position drücken und zur Entwicklung eines Hallux valgus beitragen.
            </ListItem>
            <ListItem>
                Fußfehlstellungen: Bestimmte Fußfehlstellungen wie übermäßig pronierte (nach innen geneigte) Füße können die Wahrscheinlichkeit einer
                Hallux-valgus-Entstehung erhöhen.
            </ListItem>
        </OrderedList>
        <Text>Die Symptome des Hallux valgus können variieren, aber die häufigsten Anzeichen sind:</Text>
        <OrderedList>
            <ListItem>Schmerzen im Bereich des Großzehengrundgelenks.</ListItem>
            <ListItem>Sichtbare Abweichung des Großzehs in Richtung der anderen Zehen.</ListItem>
            <ListItem>Bildung eines knöchernen Vorsprungs oder einer Druckstelle der Haut an der Innenseite des Fußes.</ListItem>
            <ListItem>Reibung oder Irritation an der Innenseite des Schuhs.</ListItem>
            <ListItem>Schwierigkeiten beim Tragen von engen Schuhen und Einschränkung der Beweglichkeit des Großzehs.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung des Hallux valgus kann konservativ oder operativ sein, abhängig von der Schwere der Symptome und der Beeinträchtigung der
            Lebensqualität. Konservative Maßnahmen umfassen:
        </Text>
        <OrderedList>
            <ListItem>Verwendung von breiteren Schuhen mit ausreichend Platz für den Großzeh.</ListItem>
            <ListItem>
                Verwendung von speziellen Schuheinlagen oder -polstern zur Entlastung des Großzehs und zur Verbesserung der Fußmechanik.
            </ListItem>
            <ListItem>Physiotherapie zur Stärkung der Fußmuskulatur und zur Verbesserung der Flexibilität.</ListItem>
            <ListItem>
                Verwendung von orthopädischen Hilfsmitteln wie Zehenspreizern oder Schienen, um die Ausrichtung des Großzehs zu verbessern.
            </ListItem>
            <ListItem>Schmerzlindernde und / oder entzündungshemmende Medikamente.</ListItem>
        </OrderedList>
        <Text>
            Es ist wichtig bei Anzeichen eines Hallux valgus den Facharzt für Orthopädie Ihres Vertrauens aufzusuchen, um eine genaue Diagnose zu
            erhalten und die besten Behandlungsmöglichkeiten zu erörtern. Eine frühzeitiger Behandlungsbeginn kann dazu beitragen, die Symptome zu
            lindern und das Fortschreiten der Deformität deutlich zu verlangsamen.
        </Text>
    </Stack>
);
