import { Datenschutz, Impressum, Layout } from 'components/main';
import { Diagnosen, Home, DrFestring, Jobs, Kontakt, Leistungen, Praxis } from 'pages';
import { Page404 } from 'pages/Page404';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const App: React.FC = () => {
    const isOnline = true;

    if (!isOnline) {
        return <Page404 />;
    }

    return (
        <Layout>
            <Routes>
                <Route index element={<Home />} />
                <Route path='leistungen' element={<Leistungen />} />
                <Route path='drfestring' element={<DrFestring />} />
                <Route path='praxis' element={<Praxis />} />
                <Route path='diagnosen' element={<Diagnosen />} />
                <Route path='jobs' element={<Jobs />} />
                <Route path='kontakt' element={<Kontakt />} />
                <Route path='datenschutz' element={<Datenschutz />} />
                <Route path='impressum' element={<Impressum />} />
                <Route path='*' element={<Navigate to='/' />} />
            </Routes>
        </Layout>
    );
};

export default App;
