import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const SchwindelItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Schwindel ist ein Symptom, das durch eine Vielzahl von Ursachen hervorgerufen werden kann. Es handelt sich um eine subjektive Empfindung
            einer unsicheren Balance oder einer falschen Bewegungswahrnehmung. Es gibt verschiedene Arten von Schwindel:
        </Text>
        <OrderedList>
            <ListItem>
                Benigner paroxysmaler Lagerungsschwindel: Dies ist eine häufige Form von Schwindel, die durch bestimmte Kopfbewegungen ausgelöst wird
                wie z. B. das Drehen im Bett oder das Aufrichten aus einer liegenden Position. Es entsteht durch eine vorübergehende Verschiebung
                kleiner Partikel in den Gleichgewichtsorganen des Innenohrs.
            </ListItem>
            <ListItem>
                Neuritis vestibularis: Diese Erkrankung wird durch eine Entzündung des Gleichgewichtsnervs im Innenohr verursacht und führt zu
                plötzlichem und intensivem Schwindel, begleitet von Übelkeit und Gleichgewichtsstörungen.
            </ListItem>
            <ListItem>
                Menière-Krankheit (Morbus Menière): Eine Erkrankung des Innenohrs, die Schwindel, Hörverlust und Ohrgeräusche (Tinnitus) verursacht.
                Die Anfälle von Schwindel können mehrere Stunden dauern und von Übelkeit begleitet sein.
            </ListItem>
            <ListItem>
                Orthostatischer Schwindel: Dieser tritt auf, wenn sich der Blutdruck beim Wechseln der Körperposition wie z. B. beim Aufstehen aus dem
                Liegen nicht schnell genug anpasst. Dies kann zu einem vorübergehenden Schwindelgefühl führen.
            </ListItem>
            <ListItem>
                Angst und Stress: Starkes emotionales oder psychisches Unwohlsein kann Schwindel verursachen oder verstärken. Dies wird oft als
                psychogener Schwindel bezeichnet.
            </ListItem>
        </OrderedList>
        <Text>
            Es gibt auch andere mögliche Ursachen für Schwindel, wie z. B. Medikament-Nebenwirkungen, Migräne, Blutdruckerkrankungen (z. B.
            Bluthochdruck), Entzündungen des Innenohrs, Blutzuckerschwankungen und neurologische Störungen.
        </Text>
        <Text>
            Die Behandlung des Schwindels hängt von der zu Grunde liegenden Ursache ab. In jedem Fall sollte ein Arzt aufgesucht werden, um die genaue
            Diagnose zu erhalten. Je nach Diagnose können z. B. folgende Maßnahmen ergriffen werden:
        </Text>
        <OrderedList>
            <ListItem>
                Medikamente: Der Arzt kann bestimmte Medikamente verschreiben, um den Schwindel und ggf. die Übelkeit zu kontrollieren, Entzündungen
                zu reduzieren oder den Blutdruck zu stabilisieren.
            </ListItem>
            <ListItem>
                Physiotherapie, um das Gleichgewichtssystem zu trainieren und zu stärken, um Schwindel zu reduzieren und die Balance
                wiederherzustellen.
            </ListItem>
            <ListItem>
                Änderungen des Lebensstils: Stressbewältigungstechniken, Vermeidung von Auslösern (z. B. bestimmte Kopfbewegungen beim benignen
                paroxysmalen Lagerungsschwindel) und ausreichende Flüssigkeitszufuhr können helfen Schwindel zu reduzieren.
            </ListItem>
        </OrderedList>
        <Text>
            Es ist wichtig bei anhaltendem oder starkem Schwindel, insbesondere falls er von Symptomen wie Sprach- oder Sehstörungen,
            unkontrollierbarem Erbrechen, plötzlicher Schwäche oder Koordinationsproblemen begleitet wird, umgehend einen Arzt aufsuchen.
        </Text>
    </Stack>
);
