import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const SchulterluxationItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Eine Schulterluxation, auch Schulterausrenkung oder ausgekugelte Schulter genannt, tritt auf, wenn der Kopf des Oberarmknochens (Humerus)
            aus dem Schultergelenk herausrutscht. Es handelt sich um eine relativ häufige Verletzung, die durch ein Trauma wie z. B. einen Sturz auf
            den ausgestreckten Arm oder eine starke Krafteinwirkung auf das Schultergelenk verursacht werden kann.
        </Text>
        <Text>Es gibt zwei Arten von Schulterluxationen:</Text>
        <OrderedList>
            <ListItem>
                Vordere Schulterluxation: Dies ist die häufigste Form der Schulterluxation, bei der der Oberarmknochen nach vorne aus dem
                Schultergelenk herausrutscht.
            </ListItem>
            <ListItem>
                Hintere Schulterluxation: Diese Art der Schulterluxation ist seltener und tritt auf, wenn der Oberarmknochen nach hinten aus dem
                Schultergelenk herausrutscht.
            </ListItem>
        </OrderedList>
        <Text>Die Symptome einer Schulterluxation können sein:</Text>
        <OrderedList>
            <ListItem>Plötzlicher und intensiver Schmerz in der Schulter, der durch Bewegung verstärkt wird.</ListItem>
            <ListItem>Deutliche Verformung oder Abflachung der Schultergelenks.</ListItem>
            <ListItem>Begrenzte Beweglichkeit des Schultergelenks oder Unfähigkeit den Arm zu bewegen.</ListItem>
            <ListItem>Taubheits- oder Kribbelgefühl im Arm oder in der Hand der betroffenen Seite.</ListItem>
        </OrderedList>
        <Text>
            Falls Sie eine Schulterluxation vermuten, sollten Sie sofort ärztliche Hilfe in Anspruch nehmen. Im Bestfall wird ein Facharzt für
            Orthopädie und Unfallchirurgie die verletzte Schulter untersuchen und eine Röntgenaufnahme oder andere bildgebende Untersuchungen
            anordnen, um die Diagnose zu bestätigen und den Schweregrad der Verletzung zu bestimmen.
        </Text>
        <Text>Die Behandlung einer Schulterluxation beinhaltet in der Regel:</Text>
        <OrderedList>
            <ListItem>
                Reposition: Ein Arzt oder medizinisches Fachpersonal wird die ausgekugelte Schulter mit Hilfe spezieller Techniken vorsichtig zurück
                in das Schultergelenk reponieren. Dieser Eingriff wird normalerweise unter Betäubung oder Schmerzmitteln durchgeführt.
            </ListItem>
            <ListItem>
                Immobilisierung: Nach der Reposition wird die Schulter mit einer Schlinge oder einer speziellen Schulterorthese stabilisiert, um das
                Bewegungsausmaß des Schultergelenks vorerst zu begrenzen und die Heilung der überdehnten Gelenkweichteile zu unterstützen.
            </ListItem>
            <ListItem>
                Physiotherapie: Sobald die Schmerzen und die Schwellung abgeklungen sind, wird eine frühfunktionelle physiotherapeutische Beübung
                empfohlen, um die Schultermuskulatur zu stärken, die Beweglichkeit wiederherzustellen und das Risiko einer erneuten Luxation zu
                verringern.
            </ListItem>
        </OrderedList>
        <Text>
            In Einzelfällen, insbesondere bei wiederholten Luxationen oder schweren Begleitverletzungen, kann eine Operation erforderlich sein. Die
            Art des Eingriffs hängt von der individuellen Situation ab und kann eine Rekonstruktion der verletzten Gewebe oder eine Stabilisierung des
            Schultergelenks umfassen.
        </Text>
        <Text>
            Es ist wichtig eine Schulterluxation umgehend angemessen behandeln zu lassen, um die bestmögliche Genesung zu ermöglichen und die
            Wahrscheinlichkeit für weitere Schulterluxationen zu minimieren. Wiederholte Luxationen können zu einer chronischen Instabilität des
            Schultergelenks führen, die aufwendige und umfassende Behandlungsmaßnahmen zur Folge haben kann. Gerne beraten wir sie individuell.
        </Text>
    </Stack>
);
