import * as React from 'react';
import { Box, Image, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import PedImage from 'images/leistungen/Pedographie.jpg';

export const PedographieItem: React.FC = (): JSX.Element => (
    <Stack textAlign={'justify'}>
        <Box>
            <Image src={PedImage} float={'right'} w={275} display={{ base: 'none', md: 'block' }} alt='Pedographie' />
            <Text mb={2}>
                Die Pedobarographie ist eine diagnostische Methode, bei der der Druck und die Verteilung der Belastung beim Gehen oder Stehen
                aufgezeichnet und analysiert werden. Dabei werden spezielle Geräte verwendet, die als Pedobarographen bezeichnet werden. Diese Geräte
                bestehen aus einer Plattform mit Drucksensoren.
            </Text>
            <Image src={PedImage} display={{ base: 'block', md: 'none' }} mb={2} alt='Pedographie' />
            <Text>
                Die Sensoren erfassen den Druck, der durch die Belastung der Füße auf die Plattform erzeugt wird. Die erfassten Daten werden dann in
                Echtzeit oder nachträglich analysiert.
            </Text>
        </Box>
        <Text>Die Pedobarographie bietet verschiedene Informationen über die Fußbelastung und -funktion, einschließlich:</Text>
        <OrderedList>
            <ListItem>
                Druckverteilung: Die Pedobarographie zeigt die Verteilung des Drucks auf die verschiedenen Bereiche der Fußsohle an. Dies ermöglicht
                die Identifizierung von Druckpunkten, übermäßiger Belastung oder ungleichmäßiger Verteilung des Drucks.
            </ListItem>
            <ListItem>
                Ganganalyse: Durch die Aufzeichnung des Drucks beim Gehen kann die Pedobarographie Informationen über das Gangmuster liefern. Dies
                kann helfen Gangstörungen, Abweichungen und Dysbalancen zu identifizieren.
            </ListItem>
            <ListItem>
                Fußfehlstellungen und Abweichungen: Die Pedobarographie kann auch Hinweise auf Fußfehlstellungen wie Plattfüße, Hohlfüße oder
                Pronation/Supination geben. Sie kann auch bei der Beurteilung von Fußdeformitäten oder Anomalien helfen.
            </ListItem>
            <ListItem>
                Bewertung von orthopädischen Schuhen oder Einlagen: Die Pedobarographie kann verwendet werden, um die Wirksamkeit von orthopädischen
                Schuhen oder Einlagen zu bewerten und sicherzustellen, dass sie die Fußbelastung korrekt verteilen.
            </ListItem>
        </OrderedList>
        <Text>
            Die Pedobarographie wird zum Beispiel in der Orthopädie und Sportmedizin eingesetzt. Die Ergebnisse der Pedobarographie können helfen,
            individuell angepasste Behandlungspläne zu erstellen, einschließlich orthopädischer Einlagen, physiotherapeutischer Übungen oder anderen
            therapeutischen Interventionen.
        </Text>
        <Text>
            Die Pedobarographie ist eine ergänzende diagnostische Methode, die in Verbindung mit einer gründlichen klinischen Untersuchung und
            Anamnese eingesetzt werden sollte. Ein qualifizierter Facharzt kann die Ergebnisse interpretieren und die geeigneten Behandlungsoptionen
            empfehlen.
        </Text>
    </Stack>
);
