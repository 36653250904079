import { ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const SchleimbeutelentzuendungItem: React.FC = () => (
    <Stack textAlign={'justify'}>
        <Text>
            Eine Bursitis ist eine akute oder chronische Entzündung einer oder mehrerer Schleimbeutel im Körper. Schleimbeutel sind kleine,
            flüssigkeitsgefüllte Säckchen, die sich zwischen Geweben wie Knochen, Sehnen und Muskeln befinden. Sie dienen als Gleitgewebe und Puffer,
            um Reibung zu reduzieren und die Bewegung der umliegenden Strukturen gegeneinander zu erleichtern.
        </Text>
        <Text>
            Bursitiden treten meistens an den Schulter-, Ellenbogen-, Hüft- und Kniegelenken auf. Die häufigsten Ursachen sind übermäßige Belastung,
            wiederholte Bewegungen, Verletzungen, Infektionen oder entzündliche Erkrankungen wie rheumatoide Arthritis.
        </Text>
        <Text>Die Symptome einer Bursitis können sein:</Text>
        <OrderedList>
            <ListItem>Schmerzen: Der betroffene Bereich kann schmerzhaft sein, insbesondere bei Bewegung oder Druck.</ListItem>
            <ListItem>Schwellung: Die Schleimbeutel können sich entzünden und anschwellen.</ListItem>
            <ListItem>Rötung: Die Haut über dem betroffenen Bereich kann gerötet sein.</ListItem>
            <ListItem>Bewegungseinschränkung: Die Schmerzen und Schwellungen können die Bewegungsfreiheit einschränken.</ListItem>
        </OrderedList>
        <Text>
            Die Behandlung einer Bursitis soll Schmerzen lindern, die Entzündung reduzieren und die normale Bewegung wiederherstellen. Hier sind
            einige gängige Ansätze zur Behandlung einer Bursitis:
        </Text>
        <OrderedList>
            <ListItem>
                Ruhe und Schonung: Die betroffene Extremität sollte geschont und ggf. kurzfristig ruhigstellt werden, um weitere Reizungen zu
                vermeiden.
            </ListItem>
            <ListItem>
                Kälteanwendung: Das Auftragen von Kältepackungen auf den betroffenen Bereich kann Schwellungen und Entzündungen reduzieren.
            </ListItem>
            <ListItem>
                Schmerzmittel: Die Einnahme von entzündungshemmenden Schmerzmitteln wie Ibuprofen kann Schmerzen und Entzündungen lindern.
            </ListItem>
            <ListItem>
                Physiotherapie: Ein Physiotherapeut kann Übungen und Techniken empfehlen, um die Beweglichkeit zu verbessern, die umliegende
                Muskulatur zu stärken und die Symptome zu lindern.
            </ListItem>
            <ListItem>
                Injektionen: In Einzelfällen kann ein Facharzt für Orthopädie eine Kortikosteroid-Injektion in den betroffenen Schleimbeutel
                verabreichen, um Entzündungen zu reduzieren und Schmerzen zu lindern.
            </ListItem>
            <ListItem>
                Biofeedback: Die Biofeedback-Therapie mittels EMG ist bei muskulären Dysbalancen eine gleichermaßen schonende und nachhaltige
                Therapiemethode.
            </ListItem>
            <ListItem>
                Magnetfeldtherapie: Zur Förderung der Stoffwechselsituation kann ebenfalls der Einsatz einer Magnetfeldtherapie von Vorteil für die
                Heilung und die Schmerzen sein.
            </ListItem>
            <ListItem>
                Antibiotika: Wenn die Bursitis durch eine bakterielle Infektion verursacht wird, kann ggf. auch der Einsatz von Antibiotika
                erforderlich sein.
            </ListItem>
        </OrderedList>
        <Text>
            In Einzelfällen kann eine operative Entfernung des entzündeten Schleimbeutels erforderlich sein, wenn konservative Maßnahmen keine
            ausreichende Linderung bieten, eine offene, infizierte Wunde vorliegt oder ein Übergreifen der Entzündung auf benachbarte Gewebe droht.
        </Text>
        <Text>
            Es ist ratsam den Facharzt für Orthopädie Ihres Vertrauens aufzusuchen, falls Sie Symptome einer Bursitis haben, um die Diagnose zu
            sichern und die optimale Behandlung einzuleiten.
        </Text>
    </Stack>
);
